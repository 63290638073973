import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ForwadIcon from "@mui/icons-material/Forward";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import "../Report/smartreport.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const DispatchCompletedList = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [selectedrow, setselectedrow] = useState([]);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  console.log(searchQuery3);
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const dispatchvalue = useDispatch();
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [alignment, setAlignment] = React.useState("Dispatched");

  // const [startDate, setStartDate] = useState(null);

  const handleChange = (date) => {
    setSearchQuery3(date);
  };

  console.log(userRecord);
  const handleChange1 = (event) => {
    setAlignment(event.target.value);
     // Log the selected value directly
  };
  
  console.log(alignment);

  useEffect(() => {
    // Start loading before the API call
    // setLoading(true);

    // Set a timeout for 5 seconds that will stop loading regardless of API call status
    // const timeout = setTimeout(() => {
      setLoading(true);
    // }, 500);
  
    axios
      .get(
        `${urllink}Billing/dispatchdataforque?Location=${userRecord?.location}&status=${alignment}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;

        const data1 = data.sort((a, b) => {
          const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ""), 10);
          const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ""), 10);
          return invoiceB - invoiceA;
        });

        const data2 = data1.map((row, index) => ({
          id: index + 1, // Assuming you want to use Patient_Id as id
          ...row,
          status: "Status",
        }));

        setsumma(data2);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // Stop loading after the API call completes (either success or error)
        // clearTimeout(timeout);
        setLoading(false);
      });
  }, [urllink, userRecord?.location,alignment]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const lowerCaseBarcodeQuery = searchQuery2.toLowerCase();

    const lowerCaseBilldateQuery = searchQuery3
      ? format(new Date(searchQuery3), "dd-MM-yyyy") // Format to dd-MM-yyyy
      : "";

    console.log(lowerCaseBilldateQuery);

    // Filter data based on all search queries
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      const lowerCaseBarcode = row.Barcode ? row.Barcode.toLowerCase() : "";

      const lowerCaseBilldate = row.Billing_Date ? row.Billing_Date : "";
      console.log(lowerCaseBilldate);
      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery) &&
        lowerCaseBarcode.includes(lowerCaseBarcodeQuery) &&
        lowerCaseBilldate.includes(lowerCaseBilldateQuery)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, searchQuery2, searchQuery3, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;

    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    } else if (type === "barcode") {
      setSearchQuery2(value);
    }
  };

  const handleSampleCapture = (params) => {
    console.log(params);

    console.log(params);
    const data = params;

    const patientRecord = filteredRows.find(
      (row) => row.Billing_Invoice === data.Billing_Invoice
    );
    const balanceAmount = patientRecord ? patientRecord.BalanceAmount : null;
    const formattedBalanceAmount =
      balanceAmount !== null
        ? `Rs. ${parseFloat(balanceAmount).toLocaleString("en-IN")}` // Using parseFloat to remove trailing zeros
        : "N/A";

    if (data.Status === "Due") {
      // Show confirmation dialog
      const confirmMessage = `This patient has a due amount. You can only receive the report after clearing the due bill amount ${formattedBalanceAmount}. Do you want to Close Due Amount ?`;
      const userConfirmed = window.confirm(confirmMessage); // Ask for user confirmation

      if (userConfirmed) {
        axios
          .get(
            `${urllink}Billing/get_General_billing_due_list_from_dispatch?location=${userRecord.location}&Patient_Id=${data.Patient_Id}&Billing_Invoice=${data.Billing_Invoice}`
          )
          .then((response) => {
            const Datas = response.data;

            console.log("Daaa", Datas);

            const summa = Datas.map((row, ind) => ({
              id: ind + 1,
              ...row,
            }));
            console.log(summa);
            dispatchvalue({
              type: "SelectedPateintDeuBilling",
              value: summa[0],
            });

            navigate("/Home/Deu-History");
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        return; // If the user clicks "Cancel", do nothing and return
      }
    } else {
      const confmsg = window.confirm("Do you Want Print Report ?");
      if (confmsg) {
        // Proceed to capture the sample and navigate
        dispatchvalue({ type: "Capturesample", value: data });
        navigate("/Home/CompleteReports");
      } else {
        return;
      }
    }
  };

  const handleView = (params) => {
    console.log(params);
    setselectedrow(params?.TestDetails);
    setOpenModal(true);
  };

  const dynamicColumns2 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice",
      width: 120,
      frozen: true,
      renderCell: (params) =>
        params.row.Status === "Due" ? (
          <span style={{ background: "transparent", color: "red" }}>
            {params.row.Billing_Invoice}
          </span>
        ) : (
          <span>{params.row.Billing_Invoice}</span>
        ),
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      frozen: true,
      width: 300,
    },

    {
      key: "Action",
      name: "Print",
      width: 100,
      renderCell: (params) => (
        <p
          onClick={() => handleSampleCapture(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <ForwadIcon />
        </p>
      ),
    },
    {
      key: "ActionView",
      name: "View Tests",
      width: 100,
      renderCell: (params) => (
        <p
          onClick={() => handleView(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <VisibilityIcon />
        </p>
      ),
    },
    {
      key: "Status",
      name: "Status",
      renderCell: (params) => {
        const serviceStatuses = params.row.Service_Statuses || []; // Logic moved outside JSX
        return (
          <Tooltip
            title={
              <>
                <h4
                  style={{
                    textAlign: "center",
                    padding: "3px",
                    fontSize: "12px",
                    borderBottom: "1px solid white",
                    color: "orange",
                  }}
                >
                  {params?.row?.Billing_Invoice}
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1px auto",
                    gap: "5px 10px",
                    fontSize: "12px",
                    alignItems: "center",
                    marginTop: "3px",
                  }}
                >
                  <label>Collection Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Capture || "-"}</span>

                  <label>Result Entry Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Analysis || "-"}</span>

                  <label>Result Verified Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Verify || "-"}</span>

                  <label>Result Approve Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Approve || "-"}</span>
                </div>
              </>
            }
            arrow
          >
            <span>{params.row.status || "N/A"}</span>
          </Tooltip>
        );
      },
    },
    {
      key: "Barcode",
      name: "Sample Id",
      frozen: true,
      width: 200,
    },
    {
      key: "Refering_Doctor",
      name: "Reference Doctor",
    },
    {
      key: "Visit_Id",
      name: "Visit Id",
      width: 100,
    },
    {
      key: "Billing_Date",
      name: "Date",
    },

    {
      key: "Age",
      name: "Age",
      width: 55,
    },
    {
      key: "Gender",
      name: "Gender",
      width: 100,
    },
    {
      key: "Phone",
      name: "Phone",
    },
  ];

  const dynamicColumns3 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Test_Name",
      name: "Test Name",
      width: 300,
      frozen: true,
    },
    {
      key: "Service_Status",
      name: "Service Status",
    },
    {
      key: "Analysis_Status",
      name: "Analysis Status",
    },
    {
      key: "Verify_Status",
      name: "Verify Status",
    },
    {
      key: "Approve_Status",
      name: "Approve Status",
    },
  ];

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Dispatch Que List</h4>
        </div>
        <br />
        <div className="chart_body_1_head">
          <FormControl component="fieldset">
            <RadioGroup
              value={alignment}
              onChange={handleChange1}
              aria-label="Status"
              name="status-radio-buttons-group"
              row // Adds horizontal layout for radio buttons
            >
              <FormControlLabel
                value="Pending"
                control={<Radio />}
                label="Pending"
                style={{
                  color:
                    alignment === "Pending"
                      ? "var(--yellowcolorDubai)"
                      : "inherit",
                       fontWeight: 'bold'
                }}
              />
              <FormControlLabel
                value="Dispatched"
                control={<Radio />}
                label="Dispatched"
                style={{
                  color:
                    alignment === "Dispatched"
                      ? "var(--yellowcolorDubai)"
                      : "inherit",
                      fontWeight: 'bold'
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No<span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone No"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Billing Date<span>:</span>
              </label>
              <DatePicker
                selected={searchQuery3}
                onChange={handleChange}
                dateFormat="dd-MM-yyyy" // Set the desired date format
                isClearable
                placeholderText="Select a date"
              />
            </div>
          </div>
        </div>
        <p
          style={{
            color: "red",
            fontSize: "13px",
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          * Hover over the{" "}
          <span
            style={{
              color: "green",
              fontSize: "13px",
              padding: "0px 5px",
            }}
          >
            Status
          </span>{" "}
          column to view the complete report status for each entry.
        </p>
        {/* </div> */}

        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns2} RowData={filteredRows} />
        </div>

        {openModal && (
          <div
            className={
              isSidebarOpen
                ? "sideopen_showcamera_profile"
                : "showcamera_profile"
            }
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <div
              className="newwProfiles newwPopupforreason"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="Main_container_app">
                <ReactGrid columns={dynamicColumns3} RowData={selectedrow} />
              </div>
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>

      {loading && (
        <div className="spinner-overlay">
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default DispatchCompletedList;

