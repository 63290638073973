import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

function DepartmentOrderMaster() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [departmentrole, setDepartmentRole] = useState([]);
  const [orderDepartment, setOrderDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [orderId, setOrderId] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  useEffect(() => {
    const fetchSubDepartmentData = () => {
      axios
        .get(`${urllink}mainddepartment/getsubdepartment`)
        .then((response) => {
          const data = response.data;
          setDepartmentRole(data);
        })
        .catch((error) => {
          console.error("Error fetching SubDepartment data:", error);
        });
    };

    fetchSubDepartmentData();
    fetchdepartmentorderdata();
  }, [urllink]);

  const fetchdepartmentorderdata = () => {
    axios
      .get(`${urllink}usercontrol/getdepartmentorder`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        setOrderDepartment(data);
      })
      .catch((error) => {
        console.error("Error fetching SubDepartment data:", error);
      });
  };

  useEffect(() => {
    // Filter out departments already in orderDepartment
    const usedDepartmentCodes = new Set(
      orderDepartment.map((order) => order.subdepartment_code)
    );
    const filtered = departmentrole.filter(
      (department) => !usedDepartmentCodes.has(department.subdepartment_code)
    );
    setFilteredDepartments(filtered);
  }, [departmentrole, orderDepartment]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "departmentname") {
      setSelectedDepartment(value);
    } else if (name === "DepartmentOrderID") {
      setOrderId(value);
    }
  };

  const handleadddepartment = () => {
    const existingOrder = orderDepartment.find(
      (order) => order.DepartmentOrderID === orderId
    );

    if (existingOrder) {
      const userConfirmed = window.confirm(
        `This Order ID is already assigned to department ${existingOrder.subdepartment_name}. Do you want to replace it?`
      );

      if (!userConfirmed) {
        return; // User canceled, do nothing
      }

      // Replace the department order
      const updatedOrderDepartment = orderDepartment.map((order) =>
        order.DepartmentOrderID === orderId
          ? {
              ...order,
              subdepartment_name:
                departmentrole.find(
                  (dept) => dept.subdepartment_code === selectedDepartment
                )?.subdepartment_name || order.subdepartment_name,
              subdepartment_code: selectedDepartment,
            }
          : order
      );

      setOrderDepartment(updatedOrderDepartment);
      setSelectedDepartment("");
      setOrderId("");
      return;
    }

    const selectedDepartmentData = departmentrole.find(
      (dept) => dept.subdepartment_code === selectedDepartment
    );

    if (selectedDepartmentData && orderId) {
      const newDepartmentOrder = {
        id: orderDepartment.length + 1,
        DepartmentOrderID: orderId,
        subdepartment_name: selectedDepartmentData.subdepartment_name,
        subdepartment_code: selectedDepartmentData.subdepartment_code,
      };

      setOrderDepartment([...orderDepartment, newDepartmentOrder]);
      setSelectedDepartment("");
      setOrderId("");
    }
  };

  const handlesavedepartmentorder = () => {
    axios
      .post(`${urllink}usercontrol/InsertDepartmentOrder`, {
        departmentOrders: orderDepartment,
      })
      .then((res) => {
        console.log(res.data);
        alert("Data saved successfully");
        fetchdepartmentorderdata();
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to save data");
      });
  };

  const handleEditClick = (row) => {
    const { DepartmentOrderID } = row;
    if (window.confirm("Do you want to update this")) {
      // Remove the department order
      const updatedOrderDepartment = orderDepartment.filter(
        (order) => order.DepartmentOrderID !== DepartmentOrderID
      );
      setOrderDepartment(updatedOrderDepartment);
      setSelectedDepartment(row.subdepartment_code);
      setOrderId(row.DepartmentOrderID);
    }
  };

  const columns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "DepartmentOrderID",
      name: "Order ID",
      width: 120,
    },
    {
      key: "subdepartment_name",
      name: "Department",
      width: 280,
    },
    {
      key: "subdepartment_code",
      name: "Department Code",
    },
    {
      key: "EditAction",
      name: "Action",
      width: 90,
      renderCell: (params) => (
        <p
          onClick={() => handleEditClick(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <EditIcon />
        </p>
      ),
    },
  ];

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Department Order Master</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="departmentname">
            Select Department<span>:</span>
          </label>
          <input
            name="departmentname"
            id="departmentname"
            list="departments"
            value={selectedDepartment}
            onChange={handleInputChange}
          />
          <datalist id="departments">
            {Array.isArray(filteredDepartments) &&
              filteredDepartments.map((department) => (
                <option
                  key={department.subdepartment_code}
                  value={department.subdepartment_code}
                >
                  {department.subdepartment_name}
                </option>
              ))}
          </datalist>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="DepartmentOrderID">
            Department OrderID <span>:</span>
          </label>
          <input
            type="text"
            name="DepartmentOrderID"
            id="DepartmentOrderID"
            value={orderId}
            onChange={handleInputChange}
            required
          />
        </div>
        <button className="RegisterForm_1_btns" onClick={handleadddepartment}>
          <AddCircleOutlineIcon />
        </button>
      </div>
      <br />
      {Array.isArray(orderDepartment) && orderDepartment.length > 0 && (
        <div className="Main_container_app">
          <ReactGrid columns={columns} RowData={orderDepartment} />
        </div>
      )}
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          onClick={handlesavedepartmentorder}
        >
          Save{" "}
        </button>
        {/* <ToastContainer /> */}
      </div>
    </div>
  );
}

export default DepartmentOrderMaster;
