import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";

const ReferDocs = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  console.log(userRecord);
  const [data, setdata] = useState([]);
  const [isEdit, setisEdit] = useState(true);
  const [summa, setsumma] = useState(true);
  const [id, setid] = useState("");
  const [QualificationData, setQualificationData] = useState([]);

  const [dataform, setdataform] = useState({
    doctorname: "",
    doctortype: "",
    phone: "",
    commission: "",
    address1: "",
    address2: "",
    PaymentType: "",
    Bankname: "",
    CardNumber: "",
    HolderName: "",
    location: userRecord?.location,
    Qualification: "",
    title: "",
  });

  console.log(dataform);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setdataform((prevDataForm) => ({
      ...prevDataForm,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!dataform.doctorname.trim()) {
      alert("please Enter All Details");
    } else {
      console.log(dataform);
      axios
        .post(`${urllink}usercontrol/insert_into_commission_master`, dataform)
        .then((response) => {
          console.log(response);
          setsumma((prevSumma) => !prevSumma);
          setdataform({
            doctorname: "",
            doctortype: "",
            phone: "",
            commission: "",
            address1: "",
            address2: "",
            PaymentType: "",
            Bankname: "",
            CardNumber: "",
            HolderName: "",
            Qualification: "",
            title: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/get_commission_data`)
      .then((response) => {
        const data = response.data.data;
        console.log(response);
        setdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [summa, urllink]);

  const handlestatuschange = (data) => {
    console.log("data------------------------------=>", data);
    if (data.Status === "Inactive") {
      const status = "Active";
      const Commission_Id = data.Commission_Id;
      Update_status_fun(Commission_Id, status);
    } else {
      const status = "Inactive";
      const Commission_Id = data.Commission_Id;
      Update_status_fun(Commission_Id, status);
    }
  };

  const Update_status_fun = (Commission_Id, status) => {
    axios
      .post(`${urllink}usercontrol/statusupdatefor_doc_commission`, {
        status,
        Commission_Id,
      })
      .then((res) => {
        console.log(res.data);
        //   fetchLocationData();
        //   successMsg('Status Update Successfully');
        setsumma((prevSumma) => !prevSumma);
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (row) => {
    console.log(row);
    // Extract the values from the selected row and update dataform state
    setdataform({
      doctorname: row.Doctor_Name,
      doctortype: row.Doctor_Type,
      phone: row.Phone,
      commission: row.Commission_Percentage,
      address1: row.Address_One,
      address2: row.Address_Two,
      PaymentType: row.Payment_Type,
      Bankname: row.Bank_Name,
      CardNumber: row.Account_Number,
      HolderName: row.Holder_Name,
      Qualification: row.Qualification,
      title: row.Title,
    });
    setid(row.Commission_Id);
    // Change the state to enable editing mode
    setisEdit(false);
  };

  const handleupdate = () => {
    if (
      !dataform.doctorname.trim() ||
      !dataform.doctortype.trim() ||
      !dataform.phone.trim() ||
      !dataform.commission.trim()
    ) {
      alert("please Enter All Details");
    } else {
      const postdata = {
        ...dataform,
        id: id,
      };
      axios
        .post(`${urllink}usercontrol/update_into_commission_master`, postdata)
        .then((response) => {
          console.log(response);
          setisEdit(true);

          setsumma((prevSumma) => !prevSumma);
          setdataform({
            doctorname: "",
            doctortype: "",
            phone: "",
            commission: "",
            address1: "",
            address2: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const referdoccolumn = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "FullName",
      name: "Doctor Name",
      width: 300,
      frozen: true,
    },

    {
      key: "Qualification",
      name: "Qualification",
      frozen: true,
    },

    {
      key: "Doctor_Type",
      name: "Doctor Type",
      frozen: true,
    },
    {
      key: "Phone",
      name: "Phone",
      frozen: true,
    },
    {
      key: "Commission_Percentage",
      name: "Commission",
      frozen: true,
    },
    {
      key: "EditAction",
      name: "Status",
      width: 90,
      renderCell: (params) => (
        <Button onClick={() => handlestatuschange(params.row)}>
          <span style={{ color: "black" }}>{params.row.Status}</span>
        </Button>
      ),
    },
    {
      key: "EditAction1",
      name: "Action",
      width: 90,
      renderCell: (params) => (
        <p
          onClick={() => handleEdit(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <EditIcon />
        </p>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getQualificationMasterData`)
      .then((response) => {
        console.log(response);
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setQualificationData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Commission Charges</h4>
        </div>
        <br />

        <div className="RegisFormcon">
          <div className="RegisForm_1 smalefhiu">
            <label htmlFor="DoctorName">
              Doctor Name<span>:</span>
            </label>
            <select
              id="title"
              name="title"
              value={dataform.title}
              onChange={handleInputChange}
              className="krfekj_09"
              autocomplete="off"
            >
              <option value="">Title</option>
              <option value="Dr">Dr.</option>
              <option value="Mr">Mr.</option>
              <option value="Ms">Ms.</option>
              <option value="Mrs">Mrs.</option>
              <option value="Others">Others.</option>
            </select>
            <input
              type="text"
              id="DoctorName"
              name="doctorname"
              required
              value={dataform.doctorname}
              onChange={handleInputChange}
            />
          </div>

          <div className="RegisForm_1">
            <label>
              Qualification <span>:</span>
            </label>
            <select
              id="Qualification"
              name="Qualification"
              onChange={handleInputChange}
              required
              value={dataform.Qualification}
            >
              <option value="">Select</option>
              {QualificationData.map((remarks, index) => (
                <option
                  key={remarks.QualificationID}
                  value={remarks.Qualification}
                >
                  {remarks.Qualification}
                </option>
              ))}
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="DoctorType">
              Doctor Type<span>:</span>
            </label>
            <select
              id="DoctorType"
              name="doctortype"
              required
              value={dataform.doctortype}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              <option value="Laborotry">Lab</option>
              <option value="Clinic">Clinic</option>
              <option value="Hospital">Hospital</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Phone">
              Phone<span>:</span>
            </label>
            <input
              type="text"
              id="Phone"
              name="phone"
              required
              value={dataform.phone}
              onChange={handleInputChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Commission">
              Commission in Percentage<span>:</span>
            </label>
            <input
              type="number"
              id="Commission"
              name="commission"
              required
              pattern="[0-9]+"
              title="Please enter a valid numbers"
              value={dataform.commission}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="PaymentType">
              Payment Type<span>:</span>
            </label>
            <select
              id="PaymentType"
              name="PaymentType"
              required
              value={dataform.PaymentType}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              <option value="Cash">Cash</option>
              <option value="DD">DD</option>
              <option value="Cheque">Cheque</option>
              <option value="Banking">Banking</option>
              {/* <option value="OnlinePayment"></option> */}
            </select>
          </div>
          {(dataform.PaymentType === "Banking" ||
            dataform.PaymentType === "DD") && (
            <>
              <div className="RegisForm_1">
                <label htmlFor="Bankname">
                  Bank Name<span>:</span>
                </label>
                <input
                  type="text"
                  id="Bankname"
                  name="Bankname"
                  required
                  value={dataform.Bankname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="HolderName">
                  {" "}
                  Holder Name<span>:</span>
                </label>
                <input
                  type="text"
                  id="HolderName"
                  name="HolderName"
                  required
                  value={dataform.HolderName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="CardNumber">
                  {" "}
                  Account Number<span>:</span>
                </label>
                <input
                  type="number"
                  id="CardNumber"
                  name="CardNumber"
                  required
                  value={dataform.CardNumber}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          <div className="RegisForm_1">
            <label htmlFor="Address">
              Address<span>:</span>
            </label>
            <input
              type="text"
              id="Address"
              name="address1"
              required
              value={dataform.address1}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Address2">
              Address Two<span>:</span>
            </label>
            <input
              type="text"
              id="Address2"
              name="address2"
              required
              value={dataform.address2}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            onClick={isEdit ? handleSubmit : handleupdate}
          >
            {isEdit ? "Submit" : "Update"}
          </button>
        </div>

        <div className="Main_container_app">
          <ReactGrid columns={referdoccolumn} RowData={data} />
        </div>
      </div>
    </>
  );
};

export default ReferDocs;
