import React, { useRef, useState, useEffect } from "react";
// import Barcode from "react-barcode";
import "./Report.css";
import axios from "axios";
import { useSelector } from "react-redux";
// import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { toPng } from "html-to-image";
import { useNavigate } from "react-router-dom";
// import { fontSize } from "@mui/system";
import Docsign from "../assets/approved.jpg";
// import Microsign from "../assets/Dr.Hemapriya sign.jpg";
import Button from "@mui/material/Button";
// import JsBarcode from "jsbarcode";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import PhoneIcon from "@mui/icons-material/Phone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import ReactToPrint from "react-to-print";
import { saveAs } from "file-saver";

// const chunkArray = (array, chunkSize) => {
//   const result = [];
//   for (let i = 0; i < array.length; i += chunkSize) {
//     result.push(array.slice(i, i + chunkSize));
//   }
//   return result;
// };

function CancelAppointmentDialog({
  open,
  onClose,
  onConfirm,
  cancelReason,
  setCancelReason,
}) {
  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
  };

  const handleClose = () => {
    setCancelReason("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reprint Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Reprint Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TableComponent = React.forwardRef(
  (
    {
      capturedatas,
      summa,
      ClinicDetials,
      emailsent,
      hasOTReport,
      navigate,
      totalPages,
      patinetbillingQr,
      showHeaderFooter,
      currentPage,
      currentDateTime,
      touppercasefun,
      departmentCount,
      departmentCountarray,
      userdata,
    },
    ref
  ) => {
    return (
      <div>
        {summa.length !== 0 && (
          <table ref={ref} className="print-table33">
            <thead
              className="print_header"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                // height: "150px",
              }}
            >
              {!showHeaderFooter && (
                <div
                  style={{
                    height: "140px",
                    width: "100%",
                    // backgroundColor: 'lightgray'
                  }}
                ></div>
              )}

              {showHeaderFooter && (
                <div className="wiioo900 dsdwd_p9o">
                  <tr className="ededfedf_9jui">
                    <td className="Register_btn_con_barcode hdyd_p05421">
                      <div id="get_imagecontent_1">
                        <img src={ClinicDetials.ClinicLogo} alt="logo" />
                      </div>

                      <div className="dchfejjj0p">
                        <div>
                          <span>
                            {touppercasefun(ClinicDetials.ClinicAddress)},{" "}
                          </span>{" "}
                        </div>
                        <div>
                          <span>
                            PO BOX : {ClinicDetials.ClinicCode} ,{" "}
                            {touppercasefun(ClinicDetials?.ClinicCity)} ,{" "}
                            {touppercasefun(ClinicDetials.ClinicCountry)}
                          </span>{" "}
                        </div>

                        <div>
                          <span>
                            TEL : (+971) {ClinicDetials.ClinicMobileNo + " ; "}
                            (+971) {ClinicDetials.ClinicLandLineNo}
                          </span>{" "}
                        </div>

                        <div>
                          <span> EMAIL : {ClinicDetials.ClinicMailID}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              )}

              <div
                style={{
                  width: "90%",
                  height: "1px",
                  marginLeft: "45px",
                  marginBottom: "10px",
                  backgroundColor: "#F8CC3F",
                }}
              />

              {hasOTReport ? (
                <>
                  <div className="wiioo900">
                    <td>
                      <h4>
                        {capturedatas.Patient_Name} {" , "}{" "}
                        {capturedatas.Refering_Hospital}
                      </h4>
                    </td>
                  </div>
                  <div className="wiioo900 nbjui_94d">
                    <tr className="new_billing_address_2">
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Collected <p>:</p>
                        </label>
                        <span>
                          {/* {item.testdetails?.[0]?.Collected || "-"} */}
                        </span>
                      </td>
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Received <p>:</p>
                        </label>
                        <span>
                          {/* {item.testdetails?.[0]?.Received || "-"} */}
                        </span>
                      </td>
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Barcode <p>:</p>
                        </label>
                        {/* <span>{captureddatas.Barcode}</span> */}
                      </td>
                    </tr>
                  </div>
                </>
              ) : (
                <>
                  <div className="wiioo900">
                    <tr className="">
                      <td className="new_billing_div_report">
                        <label>
                          LED NO <p>:</p>
                        </label>
                        <span>{capturedatas?.Patient_Id}</span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          PATIENT'S NAME <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Title}.{" "}
                          {touppercasefun(capturedatas.ReportPatient_Name)}
                        </span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          EID NO <p>:</p>
                        </label>
                        <span>{capturedatas?.Emirates_Id}</span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          NATIONALITY <p>:</p>
                        </label>
                        <span>{touppercasefun(capturedatas?.Nationality)}</span>
                      </td>
                      <td className="new_billing_div_report rgrdgfdre">
                        <label>
                          REF. PHYSISCIAN <p>:</p>
                        </label>
                        <span>
                          {touppercasefun(capturedatas.Refering_Doctor) || "-"}
                        </span>
                      </td>

                      <td className="new_billing_div_report">
                        <label>
                          FACILITY <p>:</p>
                        </label>
                        <span>
                          {touppercasefun(capturedatas?.Refering_Hospital)}
                        </span>
                      </td>
                    </tr>

                    <tr className="">
                      <td className="new_billing_div_report ffddffn">
                        <label>
                          VISIT NO <p>:</p>
                        </label>
                        <span>{capturedatas?.visitTokenid}</span>
                      </td>

                      <td className="new_billing_div_report ffddffn">
                        <label>
                          AGE/GENDER <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Age}{" "}
                          {touppercasefun(capturedatas?.Time_Period)} /{" "}
                          {touppercasefun(capturedatas.Gender)}
                        </span>
                      </td>

                      <td className="new_billing_div_report ffddffn">
                        <label>
                          COLLECTION DATE<p>:</p>
                        </label>
                        <span>{capturedatas?.CollectedDate}</span>
                      </td>

                      <td className="new_billing_div_report ffddffn">
                        <label>
                          RECEIVING DATE<p>:</p>
                        </label>
                        <span>{capturedatas?.ReceivedDate}</span>
                      </td>

                      <td className="new_billing_div_report ffddffn">
                        <label>
                          REPORT DATE<p>:</p>
                        </label>

                        <span>
                          {capturedatas?.Repordate1
                            ? capturedatas?.Repordate1
                            : currentDateTime}
                        </span>
                      </td>

                      <td className="new_billing_div_report ffddffn">
                        <label>
                          REF MRN. <p>:</p>
                        </label>
                        <span>{capturedatas?.MRNNumber} </span>
                      </td>
                    </tr>
                  </div>
                </>
              )}
            </thead>
            <br />

            <tbody
              className="print_body Selected-table-container"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              {summa
                .filter((item, findx) => {
                  if (item.groupname === "DIFFERNTIAL COUNT") {
                    const isFirstItemCBC =
                      summa[0]?.PackageName === "CBC ( COMPLETE BLOOD COUNT )";
                    return !isFirstItemCBC;
                  } else if (item.groupname === "MICROSCOPY") {
                    const isFirstItemCBC =
                      summa[2]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" ||
                      summa[0]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )";
                    //   ||
                    // summa[2].PackageName ===
                    //   "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )";
                    return !isFirstItemCBC;
                  } else if (item.groupname === "URINE CHEMISTRY") {
                    const isFirstItemCBC =
                      summa[2]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" ||
                      summa[0]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )";
                    //   ||
                    // summa[2].PackageName ===
                    //   "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )";
                    return !isFirstItemCBC;
                  } else if (
                    item.groupname === "MICROSCOPY ( SPECIMEN: STOOL )"
                  ) {
                    const isFirstItemCBC =
                      summa[1]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[0]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[3]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[2]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[5]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )";
                    //   ||
                    // summa[2].PackageName ===
                    //   "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )";
                    return !isFirstItemCBC;
                  }

                  return true;
                })
                ?.map((dpt, undex) => {
                  const currentPage = undex + 1;

                  const CBC_DIFFERNTIAL_COUNT = summa.filter((f, findx) => {
                    if (
                      summa[0].PackageName === "CBC ( COMPLETE BLOOD COUNT )"
                    ) {
                      return (
                        findx ===
                        summa.findIndex(
                          (item) => item.groupname === "DIFFERNTIAL COUNT"
                        )
                      );
                    }
                    return false;
                  });

                  const MICROSCOPY = summa.filter((f, findx) => {
                    // Check if index is 0 and PackageName matches "CBC ( COMPLETE BLOOD COUNT )"
                    if (
                      summa[2]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" ||
                      summa[0]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )"
                    ) {
                      return (
                        findx ===
                        summa.findIndex(
                          (item) => item.groupname === "MICROSCOPY"
                        )
                      );
                    }
                    return false;
                  });

                  const URINE_CHEMISTRY = summa.filter((f, findx) => {
                    // Check if index is 0 and PackageName matches "CBC ( COMPLETE BLOOD COUNT )"
                    if (
                      summa[2]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" ||
                      summa[0]?.PackageName ===
                        "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )"
                    ) {
                      return (
                        findx ===
                        summa.findIndex(
                          (item) => item.groupname === "URINE CHEMISTRY"
                        )
                      );
                    }
                    return false;
                  });

                  const Stool_Microscopy = summa.filter((f, findx) => {
                    // Check if index is 0 and PackageName matches "CBC ( COMPLETE BLOOD COUNT )"
                    if (
                      summa[1]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[0]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[3]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[2]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )" ||
                      summa[5]?.PackageName ===
                        "STOOL ROUTINE ( Specimen: Stool )"
                    ) {
                      return (
                        findx ===
                        summa.findIndex(
                          (item) =>
                            item.groupname === "MICROSCOPY ( SPECIMEN: STOOL )"
                        )
                      );
                    }
                    return false;
                  });

                  let departmentTotals = summa.reduce((acc, dpt) => {
                    // Check if department already exists in the accumulator
                    if (!acc[dpt.department]) {
                      acc[dpt.department] = 0;
                    }

                    // Add the length of testdetails to the corresponding department's total
                    acc[dpt.department] += dpt.testdetails
                      ? dpt.testdetails.length
                      : 0;

                    return acc;
                  }, {});

                  // console.log(departmentTotals)
                  if (dpt.department === "MICROBIOLOGY") {
                    return (
                      <div style={{ pageBreakAfter: "always" }}>
                        <div className="page-numbers-top-ad">
                          <td
                            colSpan="3"
                            className="page-numbers"
                            style={{ textAlign: "right" }}
                          ></td>
                        </div>

                        <table
                          key={undex}
                          style={{
                            width: "85%",
                            position: "relative",
                            left: "55px",
                            pageBreakInside: "avoid",
                          }}
                          className="flex_tbdy_tfoot"
                        >
                          <tbody>
                            <div className="print-page3jjj">
                              {dpt?.testdetails.map((group, groupIndex) => (
                                <div
                                  key={groupIndex}
                                  className="print-page3jjj"
                                >
                                  <div className="table-container554">
                                    <div className="culture_report_data">
                                      <div className="culture_test_header">
                                        <p> Service - {dpt.department}</p>
                                      </div>
                                      <br />
                                      {group?.Report_Type === "Ot" && (
                                        <table
                                          className="report_table report_table_for_micro"
                                          key={groupIndex}
                                        >
                                          <tbody className="print_body Selected-table-container">
                                            <tr>
                                              <td>
                                                <h3>{group.groupName}</h3>
                                                <div className="completed_report_1111">
                                                  <div className="completed_report completed_report04948 gghbuy_o9">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        width: "100%",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          group?.EditContent,
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}

                                      {(group?.Report_Type === "NoGrowth" ||
                                        group?.Report_Type ===
                                          "Sensitivity") && (
                                        <div className="culturetest_div_head">
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Test Requested<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group.Test}
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Nature of specimen<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group?.Sample_Specimen}
                                            </p>
                                          </div>

                                          {group?.Report_Type ===
                                            "NoGrowth" && (
                                            <>
                                              <div className="culturetest_div">
                                                <label className="headerculture">
                                                  Report Status<span>:</span>
                                                </label>
                                                <p className="dataforprint">
                                                  Final Report{" "}
                                                </p>
                                              </div>
                                              <br />
                                            </>
                                          )}

                                          {group?.Microscopy_Data !== "" && (
                                            <div className="culturetest_div">
                                              <label className="headerculture">
                                                Microscopy<span>:</span>
                                              </label>
                                              <p className="dataforprint">
                                                {group?.Microscopy_Data}
                                              </p>
                                            </div>
                                          )}
                                          {group?.Report_Type === "NoGrowth" &&
                                            group?.Culture_Report !== "" && (
                                              <>
                                                {/* <br /> */}
                                                <div className="culturetest_div">
                                                  <label className="headerculture">
                                                    Culture Report<span>:</span>
                                                  </label>
                                                  <p className="dataforprint">
                                                    {group?.Culture_Report}
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                        </div>
                                      )}
                                      {group?.Report_Type === "Sensitivity" && (
                                        <>
                                          {/* <br /> */}
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Organism Isolated<span>:</span>
                                            </label>
                                            <p className="dataforprint summaprintsummauuu">
                                              <ol
                                                style={{
                                                  listStyleType: "upper-alpha",
                                                  textAlign: "start",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {group?.UniqueOrganism?.map(
                                                  (org, index) => (
                                                    <li key={index}>
                                                      <i
                                                        style={{
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        {
                                                          org?.Captured_Oraganism
                                                        }
                                                      </i>{" "}
                                                      -{" "}
                                                      <span>Colony Count</span>{" "}
                                                      - {group?.Colony_Count}{" "}
                                                      {group?.UOM}
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Sensitive<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Sensitive
                                              }
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Resistant<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Resistant
                                              }
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Intermediate<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Intermediate
                                              }
                                            </p>
                                          </div>
                                          <br />
                                          <br />
                                          <div className="culturetest_div tffyfcgyt_o">
                                            {" "}
                                            <label className="headerculture">
                                              Notes<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group?.Notes}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                      <br />

                                      <div className="Add_items_Purchase_Master ggdcgc_">
                                        <span> - End of Report - </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </tbody>

                          {/* <tfoot className="hxhxhxh"></tfoot> */}
                        </table>
                      </div>
                    );
                  } else if (
                    dpt.department !== "MICROBIOLOGY" &&
                    dpt.department !== "HISTOPATHOLOGY"
                  ) {
                    const testsPerPage = 25; // Define number of tests per page
                    const totalPages = Math.ceil(
                      dpt.testdetails.length / testsPerPage
                    ); // Calculate total pages
                    // console.log(summa)
                    // console.log(departmentCountarray)
                    const pageNumber =
                      departmentCountarray.indexOf(dpt.department) + 1;
                    // console.log(departmentCountarray)

                    // const lastdepartment = departmentCountarray[departmentCountarray.length - 1];
                    // // console.log("Last department:", lastdepartment);

                    // // 2. Filter the summa array to find matching departments
                    // const matchingDepartments = summa.filter(item => item?.department === lastdepartment);

                    // // 3. Sum the count of testdetails for all matching departments
                    // const totalTestDetailsCount = matchingDepartments.reduce((total, item) => total + (item.testdetails ? item.testdetails.length  : 0), 0);

                    return Array.from({ length: totalPages }).map(
                      (_, pageIndex) => (
                        <div
                          key={pageIndex}
                          style={{ pageBreakAfter: "always" }}
                        >
                          <table
                            style={{
                              width: "90%",
                              position: "relative",
                              left: "45px",
                            }}
                          >
                            <div className="print-page3">
                              <div className="ewdwedcsdwe">
                                {/* <div
                                  className="page-numbersee"
                                  style={{
                                    textAlign: "center",
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: "15px",
                                    color: "gray",
                                    fontStyle: "italic",
                                    // top: pageNumber === departmentCount ? "415px" : "910px", // Apply top based on page number
                                  }}
                                >
                                  Page {pageNumber} of {departmentCount}
                                </div> */}
                                <div className="culture_test_header">
                                  <p>{dpt.department} - Test Report</p>
                                </div>
                                {/* <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    textAlign: "right",
                                  }}
                                >
                                  Page {pageNumber} of {departmentCount}
                                </div> */}
                                <br />
                                <div className="table-container554">
                                  <table className="report_table report_table_for_micro">
                                    <thead style={{ backgroundColor: "white" }}>
                                      <tr className="report_table_0op9">
                                        <th>Test Name</th>
                                        <th>Result</th>
                                        <th>Unit</th>
                                        <th>Biological Reference</th>
                                        <th>Method</th>
                                      </tr>
                                    </thead>
                                    <tbody className="dcuhsuc">
                                      {dpt.Test_Method === "offer" && (
                                        <>
                                          <tr
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                            }}
                                          >
                                            <td
                                              className="fjuvgudfv"
                                              colSpan={5}
                                              style={{ textAlign: "center" }}
                                            >
                                              {dpt.PackageName}
                                            </td>
                                          </tr>

                                          {dpt.groupname && (
                                            <tr
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <td
                                                colSpan={5}
                                                style={{
                                                  textAlign: "center",
                                                  padding:
                                                    "10px 0px 9px !important",
                                                }}
                                              >
                                                {dpt.groupname}
                                              </td>
                                            </tr>
                                          )}

                                          {dpt.testdetails.length > 0 &&
                                            dpt.testdetails
                                              .sort(
                                                (a, b) => a.Item_Id - b.Item_Id
                                              )
                                              .map((group, index) => (
                                                <React.Fragment key={index}>
                                                  <tr>
                                                    <td>
                                                      <div className="uyhfgr_gf">
                                                        <p>{group.Test_Name}</p>
                                                      </div>
                                                    </td>

                                                    <td>
                                                      {group?.Capture_Value <
                                                      group?.paniclow ? (
                                                        <span
                                                          style={{
                                                            color: "blue",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {group?.Capture_Value}
                                                        </span>
                                                      ) : group?.Capture_Value >
                                                        group?.panichigh ? (
                                                        <span
                                                          style={{
                                                            color: "red",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {group?.Capture_Value}
                                                        </span>
                                                      ) : (
                                                        group?.Capture_Value
                                                      )}
                                                    </td>

                                                    <td>{group?.UOM}</td>
                                                    <td>
                                                      {group?.ReferenceRange}
                                                    </td>
                                                    <td>
                                                      {group?.Method_Name}
                                                    </td>
                                                  </tr>

                                                  {group.PackageName ===
                                                    "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" && (
                                                    <>
                                                      {index === 1 &&
                                                        MICROSCOPY &&
                                                        MICROSCOPY.map(
                                                          (
                                                            cbc_data,
                                                            cbc_indx
                                                          ) => (
                                                            <React.Fragment
                                                              key={cbc_indx}
                                                            >
                                                              {cbc_data.groupname && (
                                                                <tr>
                                                                  <td
                                                                    className="fjuvgudfv"
                                                                    colSpan={5}
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "10px 0px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      cbc_data.groupname
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              )}

                                                              {cbc_data
                                                                .testdetails
                                                                .length > 0 &&
                                                                cbc_data.testdetails
                                                                  .sort(
                                                                    (a, b) =>
                                                                      a.Item_Id -
                                                                      b.Item_Id
                                                                  )
                                                                  .map(
                                                                    (
                                                                      group,
                                                                      index
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <tr>
                                                                          <td>
                                                                            <div className="uyhfgr_gf">
                                                                              <p>
                                                                                {
                                                                                  group.Test_Name
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </td>

                                                                          <td>
                                                                            {group?.Capture_Value <
                                                                            group?.paniclow ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : group?.Capture_Value >
                                                                              group?.panichigh ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "red",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : (
                                                                              group?.Capture_Value
                                                                            )}
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.UOM
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.ReferenceRange
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.Method_Name
                                                                            }
                                                                          </td>
                                                                        </tr>

                                                                        {group.Remarks && (
                                                                          <tr>
                                                                            <td
                                                                              colSpan={
                                                                                5
                                                                              }
                                                                            >
                                                                              <i>
                                                                                {group.Remarks
                                                                                  ? ` **  ${group.Remarks} ** `
                                                                                  : ""}
                                                                              </i>
                                                                            </td>
                                                                          </tr>
                                                                        )}
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                            </React.Fragment>
                                                          )
                                                        )}
                                                    </>
                                                  )}

                                                  {group.PackageName ===
                                                    "ROUTINE URINALYSIS ( SAMPLY TYPE : URINE )" && (
                                                    <>
                                                      {index === 1 &&
                                                        URINE_CHEMISTRY &&
                                                        URINE_CHEMISTRY.map(
                                                          (
                                                            cbc_data,
                                                            cbc_indx
                                                          ) => (
                                                            <React.Fragment
                                                              key={cbc_indx}
                                                            >
                                                              {cbc_data.groupname && (
                                                                <tr>
                                                                  <td
                                                                    className="fjuvgudfv"
                                                                    colSpan={5}
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "10px 0px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      cbc_data.groupname
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              )}

                                                              {cbc_data
                                                                .testdetails
                                                                .length > 0 &&
                                                                cbc_data.testdetails
                                                                  .sort(
                                                                    (a, b) =>
                                                                      a.Item_Id -
                                                                      b.Item_Id
                                                                  )
                                                                  .map(
                                                                    (
                                                                      group,
                                                                      index
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <tr>
                                                                          <td>
                                                                            <div className="uyhfgr_gf">
                                                                              <p>
                                                                                {
                                                                                  group.Test_Name
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </td>

                                                                          <td>
                                                                            {group?.Capture_Value <
                                                                            group?.paniclow ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : group?.Capture_Value >
                                                                              group?.panichigh ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "red",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : (
                                                                              group?.Capture_Value
                                                                            )}
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.UOM
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.ReferenceRange
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.Method_Name
                                                                            }
                                                                          </td>
                                                                        </tr>

                                                                        {group.Remarks && (
                                                                          <tr>
                                                                            <td
                                                                              colSpan={
                                                                                5
                                                                              }
                                                                            >
                                                                              <i>
                                                                                {group.Remarks
                                                                                  ? `   **  ${group.Remarks} ** `
                                                                                  : ""}
                                                                              </i>
                                                                            </td>
                                                                          </tr>
                                                                        )}
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                            </React.Fragment>
                                                          )
                                                        )}
                                                    </>
                                                  )}

                                                  {group.PackageName ===
                                                    "STOOL ROUTINE ( Specimen: Stool )" && (
                                                    <>
                                                      {index === 3 &&
                                                        Stool_Microscopy &&
                                                        Stool_Microscopy.map(
                                                          (
                                                            cbc_data,
                                                            cbc_indx
                                                          ) => (
                                                            <React.Fragment
                                                              key={cbc_indx}
                                                            >
                                                              {cbc_data.groupname && (
                                                                <tr>
                                                                  <td
                                                                    className="fjuvgudfv"
                                                                    colSpan={5}
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "10px 0px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      cbc_data.groupname
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              )}

                                                              {cbc_data
                                                                .testdetails
                                                                .length > 0 &&
                                                                cbc_data.testdetails
                                                                  .sort(
                                                                    (a, b) =>
                                                                      a.Item_Id -
                                                                      b.Item_Id
                                                                  )
                                                                  .map(
                                                                    (
                                                                      group,
                                                                      index
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <tr>
                                                                          <td>
                                                                            <div className="uyhfgr_gf">
                                                                              <p>
                                                                                {
                                                                                  group.Test_Name
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </td>

                                                                          <td>
                                                                            {group?.Capture_Value <
                                                                            group?.paniclow ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : group?.Capture_Value >
                                                                              group?.panichigh ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "red",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : (
                                                                              group?.Capture_Value
                                                                            )}
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.UOM
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.ReferenceRange
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.Method_Name
                                                                            }
                                                                          </td>
                                                                        </tr>

                                                                        {group.Remarks && (
                                                                          <tr>
                                                                            <td
                                                                              colSpan={
                                                                                5
                                                                              }
                                                                            >
                                                                              <i>
                                                                                {group.Remarks
                                                                                  ? `   **  ${group.Remarks} ** `
                                                                                  : ""}
                                                                              </i>
                                                                            </td>
                                                                          </tr>
                                                                        )}
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                            </React.Fragment>
                                                          )
                                                        )}
                                                    </>
                                                  )}

                                                  {group.PackageName ===
                                                    "CBC ( COMPLETE BLOOD COUNT )" && (
                                                    <>
                                                      {/* {console.log(dpt.testdetails)} */}
                                                      {index ===
                                                        dpt.testdetails.length -
                                                          1 &&
                                                        CBC_DIFFERNTIAL_COUNT &&
                                                        CBC_DIFFERNTIAL_COUNT.map(
                                                          (
                                                            cbc_data,
                                                            cbc_indx
                                                          ) => (
                                                            <React.Fragment
                                                              key={cbc_indx}
                                                            >
                                                              {cbc_data.groupname && (
                                                                <tr>
                                                                  <td
                                                                    className="fjuvgudfv"
                                                                    colSpan={5}
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "10px 0px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      cbc_data.groupname
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              )}

                                                              {cbc_data
                                                                .testdetails
                                                                .length > 0 &&
                                                                cbc_data.testdetails
                                                                  .sort(
                                                                    (a, b) =>
                                                                      a.Item_Id -
                                                                      b.Item_Id
                                                                  )
                                                                  .map(
                                                                    (
                                                                      group,
                                                                      index
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <tr>
                                                                          <td>
                                                                            <div className="uyhfgr_gf">
                                                                              <p>
                                                                                {
                                                                                  group.Test_Name
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </td>

                                                                          <td>
                                                                            {group?.Capture_Value <
                                                                            group?.paniclow ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : group?.Capture_Value >
                                                                              group?.panichigh ? (
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "red",
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  group?.Capture_Value
                                                                                }
                                                                              </span>
                                                                            ) : (
                                                                              group?.Capture_Value
                                                                            )}
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.UOM
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.ReferenceRange
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              group?.Method_Name
                                                                            }
                                                                          </td>
                                                                        </tr>

                                                                        {group.Remarks && (
                                                                          <tr>
                                                                            <td
                                                                              colSpan={
                                                                                5
                                                                              }
                                                                            >
                                                                              <i>
                                                                                {group.Remarks
                                                                                  ? ` **  ${group.Remarks} ** `
                                                                                  : ""}
                                                                              </i>
                                                                            </td>
                                                                          </tr>
                                                                        )}
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                            </React.Fragment>
                                                          )
                                                        )}
                                                    </>
                                                  )}
                                                  {/* <br /> */}
                                                  {group.Remarks && (
                                                    <tr>
                                                      <td colSpan={5}>
                                                        <i>
                                                          {group.Remarks
                                                            ? ` **  ${group.Remarks} **`
                                                            : ""}
                                                        </i>
                                                      </td>
                                                    </tr>
                                                  )}
                                                </React.Fragment>
                                              ))}
                                          <br />
                                        </>
                                      )}

                                      <br />
                                      {dpt.Test_Method !== "offer" && (
                                        <>
                                          {dpt.testdetails
                                            .slice(
                                              pageIndex * testsPerPage,
                                              (pageIndex + 1) * testsPerPage
                                            ) // Only display tests for the current page
                                            .map((group, index) => (
                                              <React.Fragment key={index}>
                                                {group?.Test_Method ===
                                                  "Group" &&
                                                  index === 0 && (
                                                    <tr
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <td
                                                        colSpan={5}
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {group.Group_Name}
                                                      </td>
                                                    </tr>
                                                  )}
                                                {group?.Capture_Value !==
                                                  "" && (
                                                  <tr
                                                    style={{
                                                      fontWeight:
                                                        group.IsSubTest ===
                                                        "Yes"
                                                          ? "bold"
                                                          : "normal",
                                                    }}
                                                  >
                                                    <td>
                                                      <p
                                                        style={{
                                                          fontWeight:
                                                            group.IsSubTest ===
                                                            "Yes"
                                                              ? "bold"
                                                              : "normal",
                                                        }}
                                                      >
                                                        {group.Test_Name}
                                                      </p>
                                                    </td>
                                                    <td>
                                                      {group?.Capture_Value <
                                                      group?.paniclow ? (
                                                        <span
                                                          style={{
                                                            color: "blue",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {group?.Capture_Value}
                                                        </span>
                                                      ) : group?.Capture_Value >
                                                        group?.panichigh ? (
                                                        <span
                                                          style={{
                                                            color: "red",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {group?.Capture_Value}
                                                        </span>
                                                      ) : (
                                                        group?.Capture_Value
                                                      )}
                                                    </td>
                                                    <td>{group?.UOM}</td>
                                                    <td>
                                                      {group?.ReferenceRange}
                                                    </td>
                                                    <td>
                                                      {group?.Method_Name}
                                                    </td>
                                                  </tr>
                                                )}
                                                {group.Remarks !== "," && (
                                                  <tr>
                                                    <td colSpan={5}>
                                                      <i>
                                                        {group.Remarks
                                                          ? `**  ${group.Remarks} **`
                                                          : ""}
                                                      </i>
                                                    </td>
                                                  </tr>
                                                )}
                                              </React.Fragment>
                                            ))}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <tfoot className="print_footerr">
                              <div className="shshxhxs_secfooter">
                                <tr className="ehdhe_9ikw">
                                  <td className="esexwshh_">
                                    <div className="esexwshh_kkk">
                                      <div className="hhh_p2qcc">
                                        <h5>
                                          Verified by <p>:</p>
                                        </h5>
                                        <h6>
                                          {userdata?.VerifierName || "admin"}
                                        </h6>
                                      </div>
                                      <div className="hhh_p2qcc">
                                        <h5>
                                          Verified at <p>:</p>
                                        </h5>
                                        <h6>{userdata?.verifieddate}</h6>
                                      </div>
                                    </div>
                                    <div className="esexwshh_kkk">
                                      <div className="hhh_p2qcc">
                                        <h5>
                                          Authenticated by <p>:</p>
                                        </h5>
                                        <h6>
                                          {userdata?.ApproverName || "admin"}
                                        </h6>
                                      </div>
                                      <div className="hhh_p2qcc">
                                        <h5>
                                          Authenticated at <p>:</p>
                                        </h5>
                                        <h6>{userdata?.approveddate}</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "12px",
                                        color: "gray",
                                        fontStyle: "italic",
                                        textAlign: "center",
                                        marginBottom: "5px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      This is a computer-generated report and
                                      does not need a signature.
                                    </p>
                                    <div
                                      style={{
                                        height: "1px",
                                        width: "100%",
                                        backgroundColor: "#F8CC3F",
                                      }}
                                    ></div>

                                    {/* </div> */}
                                  </td>
                                </tr>
                              </div>
                            </tfoot>
                          </table>
                        </div>
                      )
                    );
                  } else if (dpt.department === "HISTOPATHOLOGY") {
                    return (
                      <div style={{ pageBreakAfter: "always" }}>
                        <div className="print-page3">
                          <div className="ewdwedcsdwe">
                            <div className="culture_test_header">
                              <p>{dpt.department}</p>
                            </div>

                            <div className="table-container554"></div>

                            <table className="report_table report_table_for_micro">
                              <tbody className="print_body Selected-table-container">
                                <div key={undex}>
                                  {/* <h3>{dpt?.department}</h3> */}

                                  <div className="completed_report_1111">
                                    <div className="completed_report gghbuy_o9">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            dpt.testdetails[0]?.EditContent,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="Add_items_Purchase_Master ggdcgc_">
                                  <span> --- End of Report --- </span>
                                </div>
                              </tbody>

                              {/* <tfoot className="hxhxhxh">
                                <div className="esexwshh_head">
                                  <div className="esexwshh_">
                                    <div className="esexwshh_kkk">
                                      <div className="hhh_p2qcc">
                                        <label>
                                          Verified by <p>:</p>
                                        </label>
                                        <h6>
                                          {dpt.department === "BIOCHEMISTRY"
                                            ? "Santhosh"
                                            : "Tamil"}
                                        </h6>
                                      </div>

                                      <div className="hhh_p2qcc">
                                        <label>
                                          Verified at <p>:</p>
                                        </label>
                                        <h6>01/01/2024</h6>
                                      </div>
                                    </div>

                                    <div className="esexwshh_kkk">
                                      <div className="hhh_p2qcc">
                                        <label>
                                          Authenticated by <p>:</p>
                                        </label>
                                        <h6>
                                          Dr. Hesham Mahmoud Hafez Shahin
                                          GD25515
                                        </h6>
                                      </div>

                                      <div className="hhh_p2qcc">
                                        <label>
                                          Authenticated at <p>:</p>
                                        </label>
                                        <h6>01/01/2024</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </tfoot> */}
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}

              {/* <div className="Add_items_Purchase_Master yydd_end_reprt">
                <span>
                  {" "}
                  --------------------------------- End of Report
                  ---------------------------------{" "}
                </span>
              </div> */}
            </tbody>
          </table>
        )}

        {summa.length === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <p style={{ color: "red" }}> Your Report is not Ready Now</p>

            <button
              className="RegisterForm_1_btns  Rhugt_report"
              onClick={() => navigate("/Home/DispatchEntryList")}
            >
              Back
            </button>
          </div>
        )}
      </div>
    );
  }
);

function Reports() {
  const componentRef = useRef();

  const touppercasefun = (value) => {
    return value?.toUpperCase();
  };

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const SignPerson = useSelector((state) => state.userRecord?.SignPerson);
  const printpapaer = useSelector((state) => state.userRecord?.printpapaer);
  const [microandhistonumber, setmicroandhistonumber] = useState({});
  let isNoRendered = false;
  const [summa, setsumma] = useState([]);
  const [cancelReason, setCancelReason] = useState("");
  const [trues, settrues] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState();
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  const navigate = useNavigate();
  const hasOTReport = summa.some((item) => item.Report_Type === "OT");
  const [emailsent, setemailsent] = useState(false);
  const [patinetbillingQr, setpatinetbillingQr] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [userdata, setuserdata] = useState({});

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_userdata_for_Report?Billing_Invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res);
        setuserdata(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [capturedatas, urllink]);

  useEffect(() => {
    // Function to format the date and time
    const formatDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0"); // 24-hour format
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    // Update the date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(formatDateTime());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCountry: "",

    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  const handleCheckboxChange = (e) => {
    setShowHeaderFooter(e.target.value === "yes");
  };
  // const [report, setreport] = useState({
  //   Collected: "",
  //   Received: "",
  //   Reported: "",
  //   Head: "",
  //   Detail: "",
  // });

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_data_forReport_New?Visitid=${capturedatas?.Visit_Id}&patientid=${capturedatas.Patient_Id}&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res);
        setsumma(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    if (capturedatas) {
      const barval = `${urllink}Billing/get_report?inv=${capturedatas?.Barcode}`;
      axios
        .get(`${urllink}Phelobotomist/get_qrcode_image?qrValue=${barval}`)
        .then((res) => {
          const datass = res.data.qrImage;
          setpatinetbillingQr(datass);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [capturedatas, urllink]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getMicro_and_HistoNumber?Invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        setmicroandhistonumber(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, capturedatas]);

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate("/Home/DispatchEntryList");
    }
  }, [capturedatas, navigate]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=ALL`)
      .then((response) => {
        console.log(response);
        const data = response.data[0];
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.street + "," + data.area,
            ClinicGST: data.Gst_no,
            ClinicCity: data.location,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicCountry: data.country,

            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`
      )
      .then((response) => {
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink]);

  useEffect(() => {
    const updatePageCount = () => {
      if (componentRef.current) {
        const contentHeight = componentRef.current.scrollHeight;
        const pageHeight = 1120;
        const pages = Math.ceil(contentHeight / pageHeight);
        setTotalPages(pages);
        setCurrentPage(1);
      }
    };

    updatePageCount();
    // Recalculate on summa change
  }, [summa]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    pageStyle: `
    @page {
      size: auto;

  
      @bottom-center {
        content: 'Page ' counter(page) ' of ' counter(pages);
        text-align: center;
        font-size: 10.5px;
        font-family: Arial, sans-serif;
        font-style: italic;
        margin-bottom: 30mm !important;
    
      }
    }
  `,
    onBeforePrint: () => {
      // You can set any pre-print logic here, if necessary
    },
    onAfterPrint: async () => {
      const printdata = componentRef.current;

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const pdfWidth = contentWidth + 2 * 5;

          // Initialize the pdf before use
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            format: [pdfWidth, 1120],
          });

          // Calculate total pages based on the number of departments (summa)
          const totalPages = summa.length;

          // // Generating the barcodes and adding them to the PDF
          // const barcodeImagecon1 = await toPng(
          //   printdata.querySelector("#get_imagecontent_1")
          // );
          // const barcodeImagecon2 = await toPng(
          //   printdata.querySelector("#get_imagecontent_2")
          // );

          // const barcodeImage1 = await new Promise((resolve, reject) => {
          //   const barcodeImg1 = new Image();
          //   barcodeImg1.onload = () => resolve(barcodeImg1);
          //   barcodeImg1.onerror = reject;
          //   barcodeImg1.src = barcodeImagecon1;
          // });

          // const barcodeImage2 = await new Promise((resolve, reject) => {
          //   const barcodeImg2 = new Image();
          //   barcodeImg2.onload = () => resolve(barcodeImg2);
          //   barcodeImg2.onerror = reject;
          //   barcodeImg2.src = barcodeImagecon2;
          // });

          // // Append barcode images to their respective containers
          // const barcodeContainer1 = printdata.querySelector(
          //   "#get_imagecontent_1"
          // );
          // barcodeContainer1.innerHTML = ""; // Clear previous content
          // barcodeContainer1.appendChild(barcodeImage1);

          // const barcodeContainer2 = printdata.querySelector(
          //   "#get_imagecontent_2"
          // );
          // barcodeContainer2.innerHTML = ""; // Clear previous content
          // barcodeContainer2.appendChild(barcodeImage2);

          // Loop through each page and add to PDF
          for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            await pdf.html(printdata, {
              x: 5, // Set x-coordinate for content
              y: 5, // Set y-coordinate for content
              callback: () => {
                const generatedPdfBlob = pdf.output("blob");

                const formdata = new FormData();
                const postdata = {
                  patientname: capturedatas.Patient_Name,
                  patient_Id: capturedatas.Patient_Id,
                  Visit_Id: capturedatas.Visit_Id,
                  refering_doc: capturedatas.Refering_Doctor,
                  invoice: capturedatas.Billing_Invoice,
                  patinetbillingbarcode: patinetbillingbarcode,
                  samplecode: capturedatas.Barcode,
                  location: userRecord?.location,
                  createdby: userRecord?.username,
                  currentPage, // Add current page for tracking
                  totalPages, // Add total pages for tracking
                };

                for (const key in postdata) {
                  if (postdata.hasOwnProperty(key)) {
                    formdata.append(key, postdata[key]);
                  }
                }

                formdata.append("report", generatedPdfBlob);

                axios
                  .post(
                    `${urllink}Phelobotomist/insert_reort_table`,
                    formdata,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((response) => {
                    settrues(!trues);
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // if (capturedatas.Refering_Hospital === "RMC") {
                //   axios
                //     .post(
                //       `${urllink}Phelobotomist/update_report_for_external_request`,
                //       formdata,
                //       {
                //         headers: {
                //           "Content-Type": "multipart/form-data",
                //         },
                //       }
                //     )
                //     .then((response) => {
                //       settrues(!trues);
                //     })
                //     .catch((error) => {
                //       console.log(error);
                //     });
                // }
              },
            });
          }
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const [loader, setloader] = useState(false);

  const handleDownloadPDF = async () => {
    setemailsent(true);

    const printData = componentRef.current;

    if (!printData) {
      console.error("No data to print.");
      return;
    }

    try {
      const contentWidth = printData.offsetWidth;
      const contentHeight = printData.offsetHeight;

      // A4 size in pixels
      const a4WidthPx = 794; // A4 width in pixels (portrait)
      const a4HeightPx = 1123; // A4 height in pixels

      // Initialize jsPDF with A4 format
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [a4WidthPx, a4HeightPx], // A4 size in pixels
      });

      const marginX = (a4WidthPx - contentWidth) / 2;
      const marginY = (a4HeightPx - contentHeight) / 2;

      // Render HTML to PDF
      await pdf.html(printData, {
        x: marginX > 0 ? marginX : 5, // Center content horizontally
        y: marginY > 0 ? marginY : 5, // Center content vertically
        html2canvas: {
          scale: 2, // Higher scale for better resolution in PDF
          width: contentWidth,
          height: contentHeight,
        },
        callback: async () => {
          // Output PDF as blob
          const generatedPdfBlob = pdf.output("blob");

          // Prepare FormData for submission
          const formData = new FormData();
          const postData = {
            patientname: capturedatas.Patient_Name,
            Patient_Id: capturedatas.Patient_Id,
          };

          // Append postData to formData
          for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
              formData.append(key, postData[key]);
            }
          }

          // Append the generated PDF blob to formData
          formData.append("report", generatedPdfBlob);

          // Set loader while sending email
          setloader(true);

          // Send PDF via email
          try {
            const response = await axios.post(
              `${urllink}Phelobotomist/sendEmail`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            console.log("Email sent successfully:", response);
            setemailsent(false); // Reset email sent flag
            setloader(false); // Hide loader
          } catch (error) {
            console.error("Error sending email:", error);
            setloader(false); // Hide loader on error
          }
        },
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const [departmentCount, setDepartmentCount] = useState(0);
  const [departmentCountarray, setDepartmentCountarray] = useState([]);

  useEffect(() => {
    if (summa.length !== 0) {
      let departmentSet = new Set();

      summa.forEach((item) => {
        departmentSet.add(item.department);
      });

      console.log("departmentSet", departmentSet.size);
      setDepartmentCount(departmentSet?.size);
      let departmentArray = Array.from(departmentSet);
      setDepartmentCountarray(departmentArray);
    }
  }, [summa]);

  return (
    <>
      {loader ? (
        <>
          <div class="loader"></div>
        </>
      ) : (
        <div>
          <TableComponent
            ref={componentRef}
            patinetbillingbarcode={patinetbillingbarcode}
            capturedatas={capturedatas}
            summa={summa}
            userRecord={userRecord}
            navigate={navigate}
            ClinicDetials={ClinicDetials}
            urllink={urllink}
            emailsent={emailsent}
            SignPerson={SignPerson}
            printpapaer={printpapaer}
            isNoRendered={isNoRendered}
            microandhistonumber={microandhistonumber}
            hasOTReport={hasOTReport}
            totalPages={summa.length}
            currentPage={currentPage}
            patinetbillingQr={patinetbillingQr}
            showHeaderFooter={showHeaderFooter}
            currentDateTime={currentDateTime}
            touppercasefun={touppercasefun}
            departmentCount={departmentCount}
            departmentCountarray={departmentCountarray}
            userdata={userdata}
          />

          <br />

          <div className="sdcyysc_uxcds67">
            <label>
              <h3>Do you want Header and Footer?</h3>
            </label>
            <div className="dhchche_oici8">
              <h3>
                {" "}
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="yes"
                    checked={showHeaderFooter === true}
                    onChange={handleCheckboxChange}
                  />
                  Yes
                </label>
              </h3>
              <h3>
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="no"
                    checked={showHeaderFooter === false}
                    onChange={handleCheckboxChange}
                  />
                  No
                </label>
              </h3>
            </div>
          </div>
          <br />

          {summa?.length > 0 && (
            <ReactToPrint
              trigger={() => (
                <div className="Register_btn_con">
                  <button
                    onClick={handlePrint}
                    className="RegisterForm_1_btns  Rhugt_report"
                  >
                    Print
                  </button>
                </div>
              )}
            />
          )}

          {summa?.length > 0 && (
            <div className="Register_btn_con">
              <button
                onClick={handleDownloadPDF}
                className="RegisterForm_1_btns  Rhugt_report"
              >
                Send Email
              </button>
            </div>
          )}

          <CancelAppointmentDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            setCancelReason={setCancelReason}
            cancelReason={cancelReason}
          />
        </div>
      )}
    </>
  );
}
export default Reports;
