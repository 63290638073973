import React, { useState, useEffect } from "react";
// import Barcode from 'react-barcode';
import { useSelector } from "react-redux";
import axios from "axios";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TbDelta } from "react-icons/tb";
import { GiDrippingTube } from "react-icons/gi";
import "./CaptureGroup.css";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  XAxis,
  Legend,
} from "recharts";
import { useDispatch } from "react-redux";
import Select from "react-select";

function Navigationple() {
  const [testDetails, setTestDetails] = useState([]);
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log(capturedatas);
  // const [visitid, setvisitid] = useState([])
  const [dataline, setdataline] = useState([]);
  const [location, setlocation] = useState([]);
  // const [transferlocation,settransferlocation] = useState('')
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  // const [value, setvalue] = useState([]);
  const [save, setsave] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  console.log("capturedatas", capturedatas);

  const [LabRemarksdata, setLabRemarksdata] = useState([]);

  const remarksOptions =
    Array.isArray(LabRemarksdata) &&
    LabRemarksdata.map((remark) => ({
      value: remark.LabRemarks,
      label: remark.LabRemarks,
    }));

  const handleInputChangeRemarks = (detail, selectedOptions) => {
    // Find the test detail using the testcode
    const updatedTestDetails = testDetails.map((testDetail) => {
      if (testDetail.testcode === detail.testcode) {
        // If selectedOptions is not null, map through the selected remarks and join them with spaces
        const selectedRemarks = selectedOptions
          ? selectedOptions.map((option) => option.value).join(".")
          : "";

        // Update the Remarks field with the selected remarks
        return {
          ...testDetail,
          Remarks: selectedRemarks,
        };
      }
      return testDetail;
    });

    // Update the state or do further processing as needed
    setTestDetails(updatedTestDetails);
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getsetLabRemarksdata`)
      .then((response) => {
        const data = response.data;
        setLabRemarksdata(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getcapturetestdata?Billinginvoice=${capturedatas?.Billing_Invoice}&gender=${capturedatas?.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}`
      )
      .then((response) => {
        console.log("response===============", response);
        const data = response.data.map((item, index) => ({
          id: index + 1,
          testcode: item.Test_Code,
          testname: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          Test_Method: item.Test_Method,
          uom: item.UOM,
          department: item.Department,
          status: "Completed",
          medicalRemark: item.MedicalRemarks,
          technicalRemark: item.TechnicalRemarks,
          transferlocation: "",
          Remarks: "",
        }));
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink]);

  const handleStatusChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handlelocationChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].transferlocation = newValue;
    setTestDetails(updatedTestDetails);
  };

  // const handleRemarksChange = (index, newValue, type) => {
  //   const updatedTestDetails = [...testDetails];
  //   if (type === "technical") {
  //     updatedTestDetails[index].technicalRemark = newValue;
  //   } else if (type === "medical") {
  //     updatedTestDetails[index].medicalRemark = newValue;
  //   }
  //   setTestDetails(updatedTestDetails);
  // };

  const handleNewRemarkToggle = (index, isChecked, type) => {
    const updatedTestDetails = [...testDetails];
    if (type === "technical") {
      updatedTestDetails[index].isTechnicalNewRemark = isChecked;
      updatedTestDetails[index].newTechnicalRemark = "";
    } else {
      updatedTestDetails[index].isMedicalNewRemark = isChecked;
      updatedTestDetails[index].newMedicalRemark = "";
    }
    setTestDetails(updatedTestDetails);
  };

  const handleNewRemarkChange = (index, value, type) => {
    const updatedTestDetails = [...testDetails];
    if (type === "technical") {
      updatedTestDetails[index].newTechnicalRemark = value;
    } else {
      updatedTestDetails[index].newMedicalRemark = value;
    }
    setTestDetails(updatedTestDetails);
  };

  const handleSelectedRemarkChange = (index, value, type) => {
    const updatedTestDetails = [...testDetails];
    if (type === "technical") {
      updatedTestDetails[index].newTechnicalRemark = value;
    } else {
      updatedTestDetails[index].newMedicalRemark = value;
    }
    setTestDetails(updatedTestDetails);
  };
  console.log(testDetails);
  const handlesamplesave = () => {
    // Check if every testDetail item has a status and medicalRemark (if needed)
    const isEveryTestValidated = testDetails.every(
      (detail) => detail.status !== ""
    );

    if (!isEveryTestValidated) {
      alert(
        "Please ensure every test has a status selected and required remarks filled."
      );
      return; // Stop the function from proceeding further
    }
    console.log(testDetails);
    const postdata = testDetails.map((detail) => ({
      ...detail,
      Billing_invoice: capturedatas?.Billing_Invoice,
      patientid: capturedatas?.Patient_Id,
      patientname: capturedatas?.Patient_Name,
      visit_id: capturedatas?.Visit_Id,
      location: userRecord?.location,
      createdby: userRecord?.username,
      sampleid: capturedatas?.Barcode,
      patientbarcodeid: capturedatas?.patientbarcode,
      Age: capturedatas?.Age,
      Gender: capturedatas?.Gender,
      dob: capturedatas?.dob,
      // transferlocation: transferlocation
    }));

    console.log(postdata);

    axios
      .post(`${urllink}Phelobotomist/insersamplecapture`, postdata)
      .then((response) => {
        console.log(response.data);
        navigate("/Home/CaptureSamplelist");
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${urllink}Phelobotomist/updatesamplestatus`, {
        Billinginvoice: capturedatas.Billing_Invoice,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handletrendview = (testname) => {
    axios
      .get(
        `${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`
      )
      .then((response) => {
        console.log(response.data);
        let data = response.data;
        setdataline(data);
        setOpenModel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(testDetails);
  const hasTransfer = testDetails.some(
    (detail) => detail.status === "Transfer"
  );

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate("/Home/CaptureSamplelist");
    }
  });

  const handlebarcodeprint = () => {
    dispatchvalue({ type: "PrintBarcode", value: capturedatas });
    setsave(!save);
    navigate("/Home/BarcodePrint");
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getLocation_for_sampletransfer`)
      .then((response) => {
        console.log(response);
        setlocation(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [urllink]);

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="h_head h_head_h_2">
          <h4>Sample Collection Form</h4>

          <button className="barcodeprintbtn" onClick={handlebarcodeprint}>
            Print Barcode
          </button>
        </div>
        <br />

        <div className="patientdetails_lab">
          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <CgProfile />
              </span>
              Patient Name<span>:</span>
            </label>
            <span>{capturedatas?.Patient_Name}</span>
          </div>

          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <SlCalender />
              </span>
              Date <span>:</span>
            </label>
            <span>{capturedatas?.UpdatedAt}</span>
          </div>

          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <MdPhonelinkRing />
              </span>
              Phone <span>:</span>
            </label>
            <span>{capturedatas?.Phone}</span>
          </div>

          <div className="patientdata">
            {/* <Barcode value={capturedatas?.Barcode || ''} lineColor="black" height={50} width={1.5} /> */}
            <img src={capturedatas?.Sample_Barcode_Image} alt="barcode" />
          </div>
        </div>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Test Name</th>
              <th>Test Code</th>
              <th>Test Method</th>
              <th>Containers</th>
              <th>Specimens</th>
              <th>Capture / Unit</th>
              <th>Status</th>
              <th>Remarks</th>
              <th>Graph</th>
              {console.log(hasTransfer)}
              {hasTransfer && <th>Location</th>}
            </tr>
          </thead>
          <tbody>
            {console.log(testDetails)}
            {testDetails.map((detail, index) => (
              <tr key={index}>
                <td>{detail.testname}</td>
                <td>{detail.testcode}</td>
                <td>{detail.Test_Method}</td>
                <td>
                  {detail.Container_Name}
                  <span className="containernamesvg">
                    <GiDrippingTube />
                  </span>
                </td>
                <td>{detail.Specimen_Name}</td>
                <td>
                  {detail.Captured_Unit}
                  {detail.uom}
                </td>
                <td>
                  <select
                    name="status"
                    id="status"
                    className="Capture_Status_select"
                    value={detail.status}
                    onChange={(e) => handleStatusChange(index, e.target.value)}
                  >
                    <option value="Completed">Collected</option>
                    <option value="Pending">Pending</option>
                    <option value="Transfer">Transfer</option>
                  </select>
                </td>
                <td>
                  <Select
                    id="Remarks"
                    name="Remarks"
                    value={remarksOptions.filter((option) =>
                      detail.Remarks.includes(option.value)
                    )} // Pre-select options based on the current Remarks value
                    onChange={(selectedOptions) =>
                      handleInputChangeRemarks(detail, selectedOptions)
                    }
                    options={remarksOptions}
                    isMulti // Enables multiple selections
                    classNamePrefix="react-select"
                    placeholder="Select Remarks"
                  />
                </td>

                <td>
                  <span
                    className="trends"
                    onClick={() => handletrendview(detail.testcode)}
                  >
                    <TbDelta />
                  </span>
                </td>

                {detail.status === "Transfer" && (
                  <td>
                    <input
                      type="type"
                      list="organismlist"
                      value={detail.transferlocation}
                      className="Capture_Status_select1"
                      onChange={(e) =>
                        handlelocationChange(index, e.target.value)
                      }
                    />
                    <datalist id="organismlist">
                      {location
                        ?.filter(
                          (p) => p.location_name !== userRecord?.location
                        )
                        .map((p, i) => (
                          <option key={i} value={p.location_name}>
                            {p.location_name}
                          </option>
                        ))}
                    </datalist>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handlesamplesave}>
          Save
        </button>
      </div>

      {openModel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <LineChart
                width={380}
                height={200}
                data={dataline}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                className="chart_linechart"
                style={{ width: "100%", height: "100%" }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="visit" />
                <YAxis className="yaxis_linechart" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="data"
                  name="Delta Values"
                  stroke="var(--ProjectColor)"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </div>
            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navigationple;
