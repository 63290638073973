import React, { useState, useEffect } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import axios from "axios";
import { format } from "date-fns";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../AppointmentManagement/AppointmentCalendar.css";

const LabCalendar = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const employeeIdget = useSelector((state) => state.userRecord?.employeeIdget);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("userRecord :", userRecord);
  const [holidays, setHolidays] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showModal3, setShowModal3] = useState(false);
  const [selectedTestDetails, setSelectedTestDetails] = useState({});
  const [TestandPatientDetails, setTestandPatientDetails] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  console.log(currentMonth);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const [completedCount1, setCompletedCount] = useState(0);
  const [TotalRequest, setTotalRequest] = useState(0);
  const dispatchvalue = useDispatch();
  const navigate = useNavigate();
  const [RequestCancelledCount, setRequestCancelledCount] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_AppoinmentRequest_data_forcalendar?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response);
        setTestandPatientDetails(response.data);
        const currentYear = currentMonth.getFullYear();
        const currentMonthNumber = currentMonth.getMonth() + 1; // Months are 0-based
        const data = response.data;

        // Filter the requests based on the current month and year
        const filteredRequests = data.filter((request) => {
          const requestDate = new Date(request.RequestDate);
          return (
            requestDate.getFullYear() === currentYear &&
            requestDate.getMonth() + 1 === currentMonthNumber
          );
        });

        const TotalRequest = filteredRequests.filter(
          (request) => request.Status === "Pending"
        ).length;

        const completedRequests = filteredRequests.filter(
          (request) => request.Status === "Completed"
        ).length;

        const Cancelled = filteredRequests.filter(
          (request) => request.Status === "Cancelled"
        ).length;
        console.log(TotalRequest);
        console.log(completedRequests);
        console.log(RequestCancelledCount);
        setRequestCancelledCount(Cancelled);
        setTotalRequestCount(TotalRequest);
        setCompletedCount(completedRequests);
        setTotalRequest(
          TotalRequest + completedRequests + RequestCancelledCount
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentMonth, userRecord, urllink]);

  useEffect(() => {
    axios
      .get(`${urllink}HRmanagement/getholiday`)
      .then((response) => {
        setHolidays(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink]);

  const handlePreviousMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
    );
  };

  const getStartingDayOfMonth = () => {
    const firstDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    return firstDay.getDay();
  };

  const handlenavigate = (detail) => {
    const patientDetails = TestandPatientDetails.find(
      (patient) =>
        patient.Appointment_Request_Id === detail.Appointment_Request_Id
    );
    console.log(patientDetails);
    dispatchvalue({ type: "RequestDataforBilling", value: patientDetails });
    // You can now navigate to another page or perform any action with the patientDetails
    navigate("/Home/ReceptionistBilling");
  };
  const daysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    const startingDay = getStartingDayOfMonth();
    const daysArray = [];

    for (let i = 0; i < startingDay; i++) {
      daysArray.push(null);
    }

    for (let day = 1; day <= lastDay; day++) {
      const date = new Date(year, month, day);
      const formattedDate = format(date, "yyyy-MM-dd");
      const dayDetails =
        Array.isArray(TestandPatientDetails) &&
        TestandPatientDetails.filter(
          (item) => item.RequestDate === formattedDate
        );
      const requestCount = dayDetails.filter(
        (item) => item.Status === "Pending"
      ).length;

      const completedCount = dayDetails.filter(
        (item) => item.Status === "Completed"
      ).length;

      const Cancelledcount = dayDetails.filter(
        (item) => item.Status === "Cancelled"
      ).length;

      daysArray.push({
        date,
        requestCount,
        completedCount,
        Cancelledcount,
        dayDetails,
      });
    }

    return daysArray;
  };

  const renderCalendar = () => {
    const days = daysInMonth();
    let rows = [];
    let cells = [];

    days.forEach((day, index) => {
      if (index % 7 === 0 && cells.length > 0) {
        rows.push(cells);
        cells = [];
      }

      if (day) {
        let isHolidayClass = "";
        let holidayName = "";

        if (day.date) {
          const formattedDate = format(day.date, "yyyy-MM-dd");
          const isHoliday = holidays[formattedDate];
          isHolidayClass = isHoliday ? "holiday" : "";
          holidayName = isHoliday ? holidays[formattedDate] : "";
        }

        cells.push(
          <td
            key={index}
            className={`cal_flex ${isHolidayClass} ${
              day.requestCount === 0 &&
              day.completedCount === 0 &&
              day.Cancelledcount === 0
                ? "disabled"
                : ""
            }`}
            onClick={() => {
              if (
                day.requestCount !== 0 ||
                day.completedCount !== 0 ||
                day.Cancelledcount !== 0
              ) {
                setSelectedTestDetails(day.dayDetails);
                setShowModal3(true);
              }
            }}
          >
            <div className="day">
              <span className="date">{day.date.getDate()}</span>
              {holidayName && (
                <div
                  className="holiday-name"
                  style={{ color: "Var(--ProjectColor)" }}
                >
                  {holidayName}
                </div>
              )}
              <div className="calendar_app">
                <div className="appointment_body_1">
                  <div className="appointment_data_1" title="Request Count">
                    <div>
                      <span>Pending</span>
                      <span>:</span>
                    </div>
                    <span
                      style={{
                        color:
                          day.requestCount === 0 ? "var(--labelcolor)" : "blue",
                        fontWeight: "600",
                      }}
                    >
                      {day.requestCount}
                    </span>
                  </div>
                  <div className="appointment_data_1" title="Completed Count">
                    <div>
                      <span>Completed</span>
                      <span>:</span>
                    </div>
                    <span
                      style={{
                        color:
                          day.completedCount === 0
                            ? "var(--labelcolor)"
                            : "green",
                        fontWeight: "600",
                      }}
                    >
                      {day.completedCount}
                    </span>
                  </div>
                  <div className="appointment_data_1" title="Completed Count">
                    <div>
                      <span>Cancelled</span>
                      <span>:</span>
                    </div>
                    <span
                      style={{
                        color:
                          day.Cancelledcount === 0
                            ? "var(--labelcolor)"
                            : "red",
                        fontWeight: "600",
                      }}
                    >
                      {day.Cancelledcount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        );
      } else {
        cells.push(<td key={index} className="cal_flex empty-cell"></td>);
      }
    });

    if (cells.length > 0) {
      rows.push(cells);
    }

    return (
      <>
        <div className="total_calendar total-emp-cald-con">
          <div className="h_head">
            <h4>Employee Calendar</h4>
          </div>
          <div className="calendar emple-calndr-with">
            <div className="cal_mon_1">
              <button onClick={handlePreviousMonth}>
                <KeyboardDoubleArrowLeftIcon />
              </button>
              <h3>
                {currentMonth.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </h3>
              <button onClick={handleNextMonth}>
                <KeyboardDoubleArrowRightIcon />
              </button>
            </div>
            <div className="select_items_appointment jhsdfhk">
              <div className="appoint_booked_count" style={{ color: "blue" }}>
                <label>
                  Pending <span>:</span>
                </label>
                <p>{totalRequestCount}</p>
              </div>
              <div className="appoint_booked_count" style={{ color: "green" }}>
                <label>
                  Completed <span>:</span>
                </label>
                <p>{completedCount1}</p>
              </div>
              <div className="appoint_booked_count" style={{ color: "red" }}>
                <label>
                  Cancelled <span>:</span>
                </label>
                <p>{RequestCancelledCount}</p>
              </div>
              <div className="appoint_booked_count">
                <label>
                  Total <span>:</span>
                </label>
                <p>{TotalRequest}</p>
              </div>
            </div>
          </div>
          <div className="calender_table_overall">
            <div className="calender_table">
              <table>
                <thead>
                  <tr>
                    <th>Sun</th>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr key={index}>{row}</tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleCloseModal = () => {
    setShowModal3(false);
  };

  return (
    <div className="calendar-container">
      {renderCalendar()}

      {showModal3 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={handleCloseModal}
        >
          {" "}
          <div
            className="newwProfiles newwPopupforreason uwagduaguleaveauto"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Test Details</h3>
            <div className="sdjkfhdf_7g">
              {selectedTestDetails.length > 0 ? (
                selectedTestDetails.map((detail, index) => (
                  <div className="jshudu_42">
                    <div key={index} className="jdfy_53d">
                      <div className="rdgdr_8jyu">
                        <label>
                          Patient Name<span>:</span>
                        </label>
                        <p> {detail.Patient_Name}</p>
                      </div>
                      <div className="rdgdr_8jyu">
                        <label>
                          Phone Number<span>:</span>
                        </label>{" "}
                        <p>{detail.Phone_Number}</p>
                      </div>

                      <div className="rdgdr_8jyu">
                        <label>
                          Test Name<span>:</span>
                        </label>{" "}
                        <p>
                          {detail.testdetails.map((test, idx) => (
                            <div key={idx}>
                              <p>{test.Test_Name || test.Group_Name} ,</p>
                            </div>
                          ))}
                        </p>
                      </div>
                      <br />
                    </div>
                    <div>
                      {detail.Status === "Pending" && (
                        <>
                          <div className="rdgdr_8jyu">
                            <label>
                              Status<span>:</span>
                            </label>
                            <p
                              className="drnhkisfdhl"
                              style={{
                                color: "blue",
                              }}
                            >
                              {detail.Status}

                              <button
                                style={{
                                  border: "none",
                                }}
                                onClick={() => handlenavigate(detail)}
                              >
                                <ArrowForwardIcon />
                              </button>
                            </p>
                          </div>
                        </>
                      )}

                      {detail.Status === "Completed" && (
                        <div className="rdgdr_8jyu">
                          <label>
                            Status<span>:</span>
                          </label>
                          <p
                            style={{
                              color: "green",
                            }}
                          >
                            {detail.Status}
                          </p>
                        </div>
                      )}

                      {detail.Status === "Cancelled" && (
                        <div className="rdgdr_8jyu">
                          <label>
                            Status<span>:</span>
                          </label>
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            {detail.Status}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No details available for this date.</p>
              )}
            </div>
            <button onClick={handleCloseModal} className="booked_app_btn">
              <HighlightOffIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LabCalendar;
