import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Webcam from "react-webcam";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

function LabpatientsEdit() {
  const patientEditData = useSelector(
    (state) => state.userRecord?.forpatienteditdatalab
  );

  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const webcamRef1 = useRef(null);
  const Navigate = useNavigate();
  const [expanded, setExpanded] = useState("panel1");
  const [showFile, setShowFile] = useState({
    file1: false,
  });
  const [isImageCaptured1, setIsImageCaptured1] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);
  const videoConstraints = {
    facingMode: facingMode,
  };
  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const handleRecaptureImage1 = () => {
    setFormData3((prev) => ({
      ...prev,
      CapturedImage1: null,
    }));
    setIsImageCaptured1(false);
  };

  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    const blob = imageSrc
      ? dataURItoBlob(imageSrc, "captured_image.jpg")
      : null;

    setPatientRegisterformData({
      ...PatientRegisterformData,
      NewPatientPhoto:
        blob instanceof Blob ? new File([blob], "captured_image.jpg") : null,
    });
    setIsImageCaptured1(true);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const [formData3, setFormData3] = useState({
    CapturedImage1: null,
  });

  const [PatientRegisterformData, setPatientRegisterformData] = useState({
    Patient_Id: "",
    Patient_Name: "",
    PatientPhoto: null,
    DOB: "",
    Age: "",
    AdhaarNumber: "",
    Alternate_No: "",
    Email: "",
    Father_Name: "",
    Gender: "",
    Marital_Status: "",
    Phone: "",
    Relation: "",
    SurName: "",
    Time_Period: "",
    Title: "",
    Emirates_Id: "",
    URN_Number: "",
    Passport_Number: "",
    Nationality: "",
    communicationAddress: "",
    Location: userRecord?.location,
    CreatedBy: userRecord?.username,
    MRNNumber: "",
    NewPatientPhoto: null,
  });

  console.log(PatientRegisterformData);

  const handleChange12 = (e) => {
    const { name, value, files } = e.target;

    // Limit Phone and Alternate_No to 10 characters
    let updatedValue = value;
    if ((name === "Phone" || name === "Alternate_No") && value.length > 10) {
      // updatedValue = value.slice(0, 10);
      updatedValue = {
        ...PatientRegisterformData,
        [name]: value.slice(0, 10),
      };
    } else if (name === "photo") {
      // If user selects an employee photo, update the state directly
      updatedValue = {
        ...PatientRegisterformData,
        NewPatientPhoto: e.target.files[0],
      };
    } else {
      updatedValue = {
        ...PatientRegisterformData,
        [name]: value,
      };
    }

    setPatientRegisterformData(updatedValue);
  };

  console.log(PatientRegisterformData);

  useEffect(() => {
    const fetchImageAsBlob = async () => {
      if (patientEditData?.PatientPhoto) {
        try {
          const response = await fetch(patientEditData.PatientPhoto);
          const blob = await response.blob();
          const file = new File([blob], "patientPhoto.jpg", {
            type: blob.type,
          });

          // Update state with blob file as PatientPhoto
          setPatientRegisterformData((prevData) => ({
            ...prevData,
            NewPatientPhoto: file,
          }));
        } catch (error) {
          console.error("Error fetching and converting image to blob:", error);
        }
      }
    };

    if (patientEditData && Object.keys(patientEditData).length > 0) {
      // Set all other data
      setPatientRegisterformData((prevData) => ({
        ...prevData,
        Patient_Id: patientEditData.Patient_Id,
        Patient_Name: patientEditData.Patient_Name,
        DOB: patientEditData.DOB,
        Age: patientEditData.Age,
        AdhaarNumber: patientEditData.AdhaarNumber,
        Alternate_No: patientEditData.Alternate_No,
        Email: patientEditData.Email,
        Father_Name: patientEditData.Father_Name,
        Gender: patientEditData.Gender,
        Marital_Status: patientEditData.Marital_Status,
        Phone: patientEditData.Phone,
        Relation: patientEditData.Forsur_Name,
        SurName: patientEditData?.SurName,
        Time_Period: patientEditData?.Time_Period,
        Title: patientEditData?.Title,
        communicationAddress: patientEditData?.communicationAddress,
        Emirates_Id: patientEditData?.Emirates_Id,
        URN_Number: patientEditData?.URN_Number,
        Passport_Number: patientEditData?.Passport_Number,
        Nationality: patientEditData?.Nationality,
        MRNNumber: patientEditData?.MRNNumber,
        PatientPhoto: patientEditData?.PatientPhoto,
      }));

      // Fetch the PatientPhoto as blob
      fetchImageAsBlob();
    }
  }, [patientEditData, urllink]);

  const handleSubmit = () => {
    const datatosend = new FormData();

    Object.entries(PatientRegisterformData).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        datatosend.append(key, value);
      }
    });

    axios
      .post(`${urllink}Billing/Update_Patient_Details`, datatosend)
      .then((res) => {
        console.log(res);
        successMsg(res.data.Message);
        dispatchvalue({ type: "forpatienteditdatalab", value: {} });
        navigate("/Home/Patientlistforlab");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Patient Edit</h4>
      </div>
      <br />
      <div className="Add_items_Purchase_Master">
        <span>Personal Details</span>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="Patient_Id">
            Patient ID <span>:</span>
          </label>
          <input
            type="text"
            id="Patient_Id"
            name="Patient_Id"
            value={PatientRegisterformData?.Patient_Id}
            onChange={handleChange12}
            required
            disabled
          />
        </div>

        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="Patient_Name">
            Patient Name<span>:</span>
          </label>
          <select
            id="Title"
            name="Title"
            value={PatientRegisterformData.Title}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="">Title</option>
            <option value="Dr">Dr.</option>
            <option value="Mr">Mr.</option>
            <option value="Ms">Ms.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Others">Others.</option>
          </select>
          <input
            type="text"
            id="Patient_Name"
            name="Patient_Name"
            required
            value={PatientRegisterformData.Patient_Name}
            onChange={handleChange12}
          />
        </div>

        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="SurName">
            Sur Name<span>:</span>
          </label>
          <select
            id="Relation"
            name="Relation"
            value={PatientRegisterformData.Relation}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="S/O">Son of</option>
            <option value="D/O">Daughter of</option>
            <option value="W/O">Wife of</option>
            <option value="H/O">Husband of</option>
            <option value="F/O">Father of</option>
            <option value="M/O">Mother of</option>
          </select>
          <input
            type="text"
            id="SurName"
            name="SurName"
            required
            value={PatientRegisterformData.SurName}
            onChange={handleChange12}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Gender">
            Gender <span>:</span>
          </label>
          <input
            type="text"
            id="Gender"
            name="Gender"
            value={PatientRegisterformData?.Gender}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="Age">
            Age<span>:</span>
          </label>
          <select
            id="Time_Period"
            name="Time_Period"
            value={PatientRegisterformData.Time_Period}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="year">Years</option>
            <option value="day">Days</option>
            <option value="month">Months</option>
          </select>
          <input
            type="text"
            id="Age"
            name="Age"
            required
            value={PatientRegisterformData.Age}
            onChange={handleChange12}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="DOB">
            DOB <span>:</span>
          </label>
          <input
            type="date"
            id="DOB"
            name="DOB"
            value={PatientRegisterformData?.DOB}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Phone">
            Phone Number <span>:</span>
          </label>
          <input
            type="number"
            id="Phone"
            name="Phone"
            value={PatientRegisterformData?.Phone}
            onChange={handleChange12}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Alternate_No">
            Alternate Phone Number <span>:</span>
          </label>
          <input
            type="number"
            id="Alternate_No"
            name="Alternate_No"
            value={PatientRegisterformData?.Alternate_No}
            onChange={handleChange12}
            required
          />
        </div>

        {/* <div className="RegisForm_1">
          <label htmlFor="AdhaarNumber">
            Adhaar Number <span>:</span>
          </label>
          <input
            type="number"
            id="AdhaarNumber"
            name="AdhaarNumber"
            value={PatientRegisterformData?.AdhaarNumber}
            onChange={handleChange12}
            required
          />
        </div> */}
        <div className="RegisForm_1">
          <label htmlFor="Email">
            Email <span>:</span>
          </label>
          <input
            type="text"
            id="Email"
            name="Email"
            value={PatientRegisterformData?.Email}
            onChange={handleChange12}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Marital_Status">
            Marital Status <span>:</span>
          </label>
          <input
            type="text"
            id="Marital_Status"
            name="Marital_Status"
            value={PatientRegisterformData?.Marital_Status}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="communicationAddress">
            Communication Address <span>:</span>
          </label>
          <input
            type="text"
            id="communicationAddress"
            name="communicationAddress"
            value={PatientRegisterformData?.communicationAddress}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Emirates_Id">
            Emirates ID <span>:</span>
          </label>
          <input
            type="text"
            id="Emirates_Id"
            name="Emirates_Id"
            value={PatientRegisterformData?.Emirates_Id}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="URN_Number">
            URN Number <span>:</span>
          </label>
          <input
            type="text"
            id="URN_Number"
            name="URN_Number"
            value={PatientRegisterformData?.URN_Number}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Passport_Number">
            Passport Number <span>:</span>
          </label>
          <input
            type="text"
            id="Passport_Number"
            name="Passport_Number"
            value={PatientRegisterformData?.Passport_Number}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="MRNNumber">
            MRN Number <span>:</span>
          </label>
          <input
            type="text"
            id="MRNNumber"
            name="MRNNumber"
            value={PatientRegisterformData?.MRNNumber}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Nationality">
            Nationality <span>:</span>
          </label>
          <input
            type="text"
            id="Nationality"
            name="Nationality"
            value={PatientRegisterformData?.Nationality}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Patient Photo <span>:</span>
          </label>
          <img
            src={PatientRegisterformData?.PatientPhoto}
            height="100px"
            width="100px"
            alt={PatientRegisterformData.Patient_Name}
          />
        </div>

        <div class="RegisForm_1">
          <label htmlFor="photo">
            New Photo <span>:</span>
          </label>
          <div className="RegisterForm_2">
            <input
              type="file"
              id="photo"
              name="photo"
              className="hiden-nochse-file"
              style={{ display: "none" }}
              accept="image/*,.pdf"
              onChange={handleChange12}
              required
            />
            <label
              htmlFor="photo"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
          </div>
          <span>or </span>
          <div className="RegisterForm_2">
            <button
              onClick={() => setShowFile({ file1: true })}
              className="RegisterForm_1_btns choose_file_update"
            >
              Capture pic
            </button>

            {showFile.file1 && (
              <div
                className={
                  isSidebarOpen
                    ? "sideopen_showcamera_takepic"
                    : "showcamera_takepic"
                }
                onClick={() =>
                  setShowFile({
                    file1: false,
                  })
                }
              >
                <div
                  className={
                    isSidebarOpen
                      ? "sideopen_showcamera_1_takepic1"
                      : "showcamera_1_takepic1"
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {formData3.CapturedImage1 ? ( // Display the captured image
                    <img
                      src={URL.createObjectURL(formData3.CapturedImage1)}
                      alt="captured"
                      className="captured-image11"
                    />
                  ) : (
                    <div className="camera-container">
                      <div className="web_head">
                        <h3>Image</h3>
                      </div>
                      <br></br>
                      <div className="RotateButton_canva">
                        <Webcam
                          audio={false}
                          ref={webcamRef1}
                          screenshotFormat="image/jpeg"
                          mirrored={facingMode === "user"}
                          className="web_cam"
                          videoConstraints={videoConstraints}
                        />
                        {!devices.includes(IsmobileorNot) && (
                          <button onClick={switchCamera}>
                            <CameraswitchIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="web_btn">
                    {isImageCaptured1 ? (
                      <button
                        onClick={handleRecaptureImage1}
                        className="btncon_add"
                      >
                        Recapture
                      </button>
                    ) : (
                      <button
                        onClick={handleCaptureImage1}
                        className="btncon_add"
                      >
                        Capture
                      </button>
                    )}

                    <button
                      onClick={() =>
                        setShowFile({
                          file1: false,
                        })
                      }
                      className="btncon_add"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LabpatientsEdit;
