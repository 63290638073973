import React, { useCallback, useEffect} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../src/App.css";
import LoginPage from "./LoginPage/LoginPage";
import HomePage from "./HomePage/HomePage";
import RoleManagement from "./UserControls/RoleManagement";
import UserRegister from "./UserControls/UserRegister";
import UserList from "./UserControls/UserList";
import Profile from "./Profile/Profile";
import PasswordChange from "./PasswordChange/PasswordChange";
import AccountSettings from "./UserControls/Account Settings";
import ClinicDetails from "./UserControls/ClinicDetails";
// import ClinicMetrics from "./ClinicMetrics/ClinicMetrics";
import ExpensesMaster from "./Pettycash/ExpensesMaster";
import CashExpenses from "./Pettycash/CashExpenses";
import CashExpenseReport from "./Pettycash/CashExpenseReport";
import DayBookReport from "./Pettycash/DayBookReport";
import DayClosing from "./Pettycash/DayClosing";
import OverallDayreport from "./Pettycash/OverallDayreport";
import EmployeeRegister from "./HR Management/EmployeeRegister";
import EmployeeList from "./HR Management/EmployeeList";
import EmployeeProfile from "./HR Management/EmployeeProfile";
import EmployeeAttendance from "./HR Management/Attendance";
import EmployeeCalendar from "./HR Management/EmployeeCalendar";
import EmployeeQueList from "./UserControls/EmployeeQueList";
import LeaveMangement from "./HR Management/LeaveMangement";
import PayRoll from "./HR Management/PayRoll";
import PaySlip from "./HR Management/PaySlip";
import PerformanceAppraisal from "./HR Management/Performance";
import PerformanceManagement from "./HR Management/PerformanceManagement";
import AddvaceApproval from "./HR Management/AdvanceApproval";
import AddvaceManagement from "./HR Management/AdvanceManagement";
import LeaveNavigation from "./EmployeeRequest/LeaveNavigation";
import AdvanceNavigation from "./EmployeeRequest/AdvanceNavigation";
import LeaveApproval from "./HR Management/LeaveApproval";
import PaymentVoucher from "./Accounts/PaymentVoucher";
import NotFound from "./404Page";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import TermsOfUse from "./Footer/TermsOfUse";
import { useDispatch} from "react-redux";
import DigitalExpenses from "./Pettycash/DigitalExpense";
import PurchaseRegister from "./Finance/PurchaseRegister";
import AccountGroupMaster from "./Finance/AccountGroupMaster";
import SalesRegister from "./Finance/SalesRegister";
import Journalentry from "./Finance/Journalentry";
import LedgerList from "./Finance/LedgerList";
import BalanceSheet from "./Finance/BalanceSheet";
import AccountGroupList from "./Finance/AccountGroupList";
import Accounts from "./Finance/Accounts";
import DutyManagement from "./HR Management/DutyManagement";
import GroupMaster from "./Masters/GroupMaster";
import PatientGroupName from "./Masters/PatientGroupName";
import GroupMasterList from "./Masters/GroupMasterList";
import ExternalLab from "./Masters/ExternalLab";
import ExternalLabList from "./Masters/ExternalLabList";
import AssetType from "./Masters/AssetType";
import AntibioticGroup from "./Masters/AntibioticGroup";
import TestMasterNavigation from "./Masters/TestMasterNavigation";
import Billing from "./Receptionist/Billing";
import PatientListforLab from "./Receptionist/PatientList";
import PatientRequestforLab from "./Receptionist/PatientRequest";
import PaymentHistory from "./Receptionist/PaymentHistory";
import Navigationple from "./Phelobotomist/Navigationple";
import SampleList from "./Phelobotomist/SampleList";
import TransactionHistory from "./Phelobotomist/TransactionHistory";
import CaptureSample from "./Phelobotomist/CaptureSample";
import Analyzereport from "./LabTechnician/AnalyzeReportEntry";
import NavigationLab from "./LabTechnician/NavigationLab";
import ValidatedQueue from "./LabTechnician/ValidatedQueue";
import Verifyreport from "./Sr.LabTechnician/VerifyLabEntry";
import Navigationseior from "./Sr.LabTechnician/Navigationsenior";
import Approvereport from "./Pathologist/Authorize-Approval";
import Navigationpath from "./Pathologist/Navigationpath";
import PathValidatedQueue from "./Pathologist/PathValidatedQueue";
import SrValidatedQueue from "./Sr.LabTechnician/SrValidatedQueue";
import DispatchList from "./Dispatch/DispatchTransactionHistory";
import Reports from "./Report/Reports";
import ReferDocs from "./Masters/ReferDocs";
import DoctorsPayout from "./Pettycash/DoctorsPayout";
import Doctorspayoutlist from "./Pettycash/Doctorspayoutlist";
import Cultruretest from "./Masters/Cultruretest";
import Billcancelation from "./Receptionist/BillCancelationLab";
import RadiologyMaster from "./Radiology/RadiologyMasters/RadiologyMaster";
import FranchiseMaster from "./Masters/FranchiseMaster";
import RatecardLims from "./Masters/RatecardLims";
import ConsultantcyMaster from "./HR Management/ConsultantcyMaster";
import JobRequirement from "./HR Management/JobRequiremet";
import RequestToHR from "./HR Management/RequestToHR";
import MailFromConsultancy from "./HR Management/MailFromConsultancy";
import ShortlistedResumelist from "./HR Management/ShortlistedResumelist";
import Reportspreview from "./Sr.LabTechnician/Reportspreview";
import PathologyEdit from "./Pathologist/PathologyEdit";
import { BarcodePrint } from "./Receptionist/BarcodePrint";
import Outsourceratecard from "./Masters/Outsourceratecard";
import DueBillinglab from "./Pettycash/DueBillinglab";
import TAT from "./Masters/TAT";
import LabSupplierMaster from "./LabStockMasters/LabSupplierMaster";
import LabSupplierList from "./LabStockMasters/LabSupplierList";
import LabProductMaster from "./LabStockMasters/LabProductMaster";
import LabProductMasterList from "./LabStockMasters/LabProductMasterList";
import ProductItemMaster from "./LabStockMasters/ProductItemMaster";
import LabPurchaseRaiseMaster from "./LabStockInventory/Purchase/LabPurchaseRaiseMaster";
import LabPurchaseRaiseList from "./LabStockInventory/Purchase/LabPurchaseRaiseList";
import LabPurchaseRecieveMaster from "./LabStockInventory/Purchase/LabPurchaseRecieveMaster";
import LabPurchaseRecieveList from "./LabStockInventory/Purchase/LabPurchaseRecieveList";
import LabQuickStockMasterCentral from "./LabStockInventory/Purchase/LabQuickStockMasterCentral";
import LabPurchaseReturnMaster from "./LabStockInventory/Purchase/LabPurchaseReturnMaster";
import LabPurchaseReturnMasterlist from "./LabStockInventory/Purchase/LabPurchaseReturnMasterlist";
import LabSupplierDetails from "./LabStockInventory/Supplier/LabSupplierDetails";
import LabSupplierPayCentralStore from "./LabStockInventory/Supplier/LabSupplierPayCentralStore";
import LabIndentRaiseList from "./LabStockInventory/Indent/LabIndentRaiseList";
import LabIndentRaiseMaster from "./LabStockInventory/Indent/LabIndentRaiseMaster";
import LabIndentIssueList from "./LabStockInventory/Indent/LabIndentIssueList";
import LabIndentIssueMaster from "./LabStockInventory/Indent/LabIndentIssueMaster";
import LabIndentRecieveList from "./LabStockInventory/Indent/LabIndentRecieveList";
import LabIndentRecieveMaster from "./LabStockInventory/Indent/LabIndentRecieveMaster";
import LabIndentReturnList from "./LabStockInventory/Indent/LabIndentReturnList";
import LabIndentReturnMaster from "./LabStockInventory/Indent/LabIndentReturnMaster";
import LabCentralStockList from "./LabStockMasters/LabCentralStockList";
import LabLocationStock from "./LabStockMasters/LabLocationStock";
import ReferDocreport from "./Report/ReferDocreport";
import Userwisebillreport from "./Report/UserWiseBillReport";
import AuditTrial from "./Report/AuditTrial";
import ReferDocreviewreport from "./Report/ReferingDocReview";
import RevenueByDepartment from "./Report/RevenueByDepartment";
import OfferPackage from "./Masters/OfferPackage";
import OfferPackageList from "./Masters/OfferPackageList";
import DoctorsList from "./HR Management/DoctorsList";
import LeaveManagementNavigation from "./HR Management/LeaveManagementNavigation";
import AdvanceManagementNavigation from "./HR Management/AdvanceManagementNavigation";
import AppraisalNavigation from "./HR Management/AppraisalNavigation";
import PayslipView from "./EmployeeRequest/PayslipView";
import ShiftDetails from "./EmployeeRequest/ShiftDetails";
import AccountsMaster from "./FinanceModule/AccountsMaster";
import AccountsMasterList from "./FinanceModule/AccountsMasterList";
import ContraVoucher from "./Finance/ContraVoucher";
import ContraVoucherFinance from "./FinanceModule/ContraVoucherFinance";
import PaymentVoucherFinance from "./FinanceModule/PaymentVoucherFinance";
import PaymentVoucherFinanceList from "./FinanceModule/PaymentVoucherFinanceList";
import ReceiptVoucherFinanceList from "./FinanceModule/ReceiptVoucherFinanceList";
import ReceiptVoucherFinance from "./FinanceModule/ReceiptVoucherFinance";
import JournalentryFinance from "./FinanceModule/JournalentryFinance";
import JournalentryFinanceList from "./FinanceModule/JournalentryFinanceList";
import CashbookFinance from "./FinanceModule/CashbookFinance";
import BankbookFinance from "./FinanceModule/BankbookFinance";
import DayBookFinance from "./FinanceModule/DayBookFinance";
import TrialBalanceFinance from "./FinanceModule/TrialBalanceFinance";
import BalanceSheetFinance from "./FinanceModule/BalanceSheetFinance";
import ProfitandLossFinance from "./FinanceModule/ProfitandLossFinance";
import TransactionTypeMaster from "./FinanceModule/TransactionTypeMaster";
import ReferingHospitalReport from "./Report/ReferingHospitalReport";
import DutyRoster from "./HR Management/DutyRoster";
import EmployeeReport from "./HR Management/EmployeeReport";
import RegisReport from "./Report/RegisReport";
import TestWiseReport from "./Report/TestWiseReport";
import BillEdit from "./Receptionist/BillEdit";
import RequestList from "./Receptionist/RequestList";
import AppoinmentRequestLab from "./Receptionist/AppoinmentRequestLab";
import LabCalendar from "./Receptionist/LabCalendar";
import PatientProfile from "./Receptionist/PatientProfile";
import NewMasterList from "./Masters/NewMasterList";
import DepartmentOrderMaster from "./Masters/DepartmentOrderMaster";
import MastersNavigation from "./Masters/MastersNavigation";
import MainDashboard from "./ClinicMetrics/MainDashboard";
import TestMaster from "./Masters/TestMaster";
import DeuHistory from "./Billing/Duehistory/DeuHistory";
import DueHistoryList from "./Billing/Duehistory/DueHistoryList";
import FinanceReport from "./Report/FinanceReport";
import LabpatientsEdit from "./Receptionist/LabpatientsEdit";
import ResultEntryMaster from "./Masters/ResultEntryMaster";
import ResultEntryMasterList from "./Masters/ResultEntryMasterList";
import FormulaList from "./Masters/FormulaList";
import FormulaMaster from "./Masters/FormulaMaster";
import DispatchCompletedList from "./Dispatch/DispatchCompletedList";

const App = () => {




  const navigate = useNavigate();
  const location = useLocation();

  const dispatchvalue = useDispatch();



  const getuserrecord = useCallback(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(storedToken);
      // setUserRecord(decodedTokenData);
      dispatchvalue({ type: "UserData", value: decodedTokenData });
    } else {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }
  }, [navigate, location.pathname,dispatchvalue]);

  useEffect(() => {
    const loc = location.pathname;
    if (loc === "/") {
      localStorage.clear();
    }
    getuserrecord();
  }, [location, location.pathname, navigate, getuserrecord]);

  // console.log(userRecord)

  useEffect(() => {
    const sessionTimeout = 30 * 60 * 1000; // 3 minutes in milliseconds
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        navigate("/");
      }, sessionTimeout);
    };

    // Add event listeners to reset the timeout on user activity
    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    resetTimeout(); // Initial setup

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, [navigate]);

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.setAttribute("autocomplete", "off");
      input.addEventListener("copy", (event) => {
        event.preventDefault();
      });
    });
  }, []);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "F1") {
        navigate("/Home/ReceptionistBilling");
      } else if (event.key === "F2") {
        navigate("/Home/AnalayseReportEntry");
      } else if (event.key === "F3") {
        navigate("/Home/Deu-History-List");
      } else if (event.key === "F4") {
        navigate("/Home/Bill-Edit");
      } else if (event.key === "F5") {
        navigate("/Home/PatientRequestlistforlab");
      } else if (event.key === "F6") {
        navigate("/Home/Bill-Cancellation");
      } else if (event.key === "F7") {
        navigate("/Home/Patientlistforlab");
      } else if (event.key === "F9") {
        navigate("/Home/AuthorizedReportEntry");
      } else if (event.key === "F9") {
        navigate("/Home/AccountsMasterList");
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);



  return (
    <>
      <Routes>
        {/* <Route path="/Home/ReceptionistBilling" element={<Billing />} /> */}
        <Route path="/Home" element={<HomePage />}>
          {/* Header */}
          <Route path="Profile" element={<Profile />} />
          <Route path="PasswordChange" element={<PasswordChange />} />
          {/* Patient Management */}
          <Route path="PatientProfile" element={<PatientProfile />} />
          <Route path="PatientsEdit" element={<LabpatientsEdit />} />
          {/* Dashboard */}
          {/* <Route path="ClinicMetrics" element={<ClinicMetrics />} /> */}
          <Route path="MainDashboard" element={<MainDashboard />} />
          {/* Front Office */}
          <Route path="Appoinment-RequestList" element={<RequestList />} />
          <Route path="Appoinment-Request-Lab" element={<AppoinmentRequestLab />} />
          <Route path="NewTestMasterList" element={<NewMasterList />} />
          <Route path="DepartmentOrderMaster" element={<DepartmentOrderMaster />} />
          {/* Stock */}
          {/* -------------------Lab Stock-------------------------------- */}
          <Route path="LabSupplierMaster" element={<LabSupplierMaster />} />
          <Route path="LabSupplierLists" element={<LabSupplierList />} />
          <Route path="LabProductMaster" element={<LabProductMaster />} />
          <Route
            path="LabProductMasterList"
            element={<LabProductMasterList />}
          />
          <Route path="Productitemmaster" element={<ProductItemMaster />} />
          <Route
            path="LabPurchaseRaiseMaster"
            element={<LabPurchaseRaiseMaster />}
          />
          <Route
            path="LabPurchaseRaiseList"
            element={<LabPurchaseRaiseList />}
          />
          <Route
            path="LabPurchaseRecieveMaster"
            element={<LabPurchaseRecieveMaster />}
          />
          <Route
            path="LabPurchaseRecieveList"
            element={<LabPurchaseRecieveList />}
          />
          <Route
            path="LabQuickStockMasterCentral"
            element={<LabQuickStockMasterCentral />}
          />
          <Route
            path="LabPurchaseReturnMaster"
            element={<LabPurchaseReturnMaster />}
          />
          <Route
            path="LabPurchaseReturnMasterlist"
            element={<LabPurchaseReturnMasterlist />}
          />
          <Route path="LabSupplierDetails" element={<LabSupplierDetails />} />
          <Route
            path="LabSupplierPayCentralStore"
            element={<LabSupplierPayCentralStore />}
          />
          <Route path="LabIndentRaiseList" element={<LabIndentRaiseList />} />
          <Route
            path="LabIndentRaiseMaster"
            element={<LabIndentRaiseMaster />}
          />
          <Route path="LabIndentIssueList" element={<LabIndentIssueList />} />
          <Route
            path="LabIndentIssueMaster"
            element={<LabIndentIssueMaster />}
          />
          <Route
            path="LabIndentRecieveList"
            element={<LabIndentRecieveList />}
          />
          <Route
            path="LabIndentRecieveMaster"
            element={<LabIndentRecieveMaster />}
          />
          <Route path="LabIndentReturnList" element={<LabIndentReturnList />} />
          <Route
            path="LabIndentReturnMaster"
            element={<LabIndentReturnMaster />}
          />
          <Route path="LabCentralStockList" element={<LabCentralStockList />} />
          <Route path="LabLocationStock" element={<LabLocationStock />} />
          {/*------------------------------ Finance -------------------------*/}
          <Route path="PurchaseRegister" element={<PurchaseRegister />} />
          <Route path="SalesRegister" element={<SalesRegister />} />
          <Route path="Journalentry" element={<Journalentry />} />
          <Route path="LedgerList" element={<LedgerList />} />
          <Route path="Add_Accounts" element={<Accounts />} />
          <Route path="BalanceSheet" element={<BalanceSheet />} />
          <Route path="AccountGroupList" element={<AccountGroupList />} />
          {/* Accounts */}
          <Route path="Payment-Voucher" element={<PaymentVoucher />} />
          {/* <Route path="/Accounts_payable" element={<AccountPayable/>} /> */}
          <Route path="AccountGroupMaster" element={<AccountGroupMaster />} />
          {/* Doctor work bench */}
        
          <Route path="Bill-Report" element={<OverallDayreport />} />
          {/* Petty Cash */}
          <Route path="Expenses-Master" element={<ExpensesMaster />} />
          <Route path="Cash-Expenses" element={<CashExpenses />} />
          <Route path="Digital-Expenses" element={<DigitalExpenses />} />
          <Route path="Cash-Expenses-Report" element={<CashExpenseReport />} />
          {/* <Route path="Hand-Over-Summary" element={<HandOverSummary />} /> */}
          <Route path="Day-Book-Report" element={<DayBookReport />} />
          <Route path="Day-Closing" element={<DayClosing />} />
          <Route path="DoctorsPayout" element={<DoctorsPayout />} />
          <Route path="DoctorsPayoutReport" element={<Doctorspayoutlist />} />
          <Route path="DueBillPayout" element={<DueBillinglab />} />
          {/* HR management */}
          <Route path="Employee-Register" element={<EmployeeRegister />} />
          <Route path="Employee-List" element={<EmployeeList />} />
          <Route path="Doctor-List" element={<DoctorsList />} />
          <Route path="Employee-Profile" element={<EmployeeProfile />} />
          <Route path="Employee-Attendance" element={<EmployeeAttendance />} />
          <Route
            path="Employee-Performance"
            element={<PerformanceAppraisal />}
          />
          <Route
            path="Employee-PerformanceManagement"
            element={<PerformanceManagement />}
          />
          <Route
            path="Employee-AdvanceApproval"
            element={<AddvaceApproval />}
          />
          <Route path="Employee-LeaveApproval" element={<LeaveApproval />} />
          <Route path="Employee-LeaveManage" element={<LeaveMangement />} />
          <Route
            path="Employee-AdvanceManagement"
            element={<AddvaceManagement />}
          />
          <Route
            path="Employee-AttendanceCalendar"
            element={<EmployeeCalendar />}
          />
          <Route path="Employee-PayRoll" element={<PayRoll />} />
          <Route path="Employee-PaySlip" element={<PaySlip />} />
          <Route path="Duty-Management" element={<DutyManagement />} />
          <Route
            path="Consultancy-Typeup-List"
            element={<ConsultantcyMaster />}
          />
          <Route path="Job-Requirements" element={<JobRequirement />} />
          <Route path="RequestTo-HrManagementList" element={<RequestToHR />} />
          <Route
            path="MailFrom-Consultancy"
            element={<MailFromConsultancy />}
          />
          <Route
            path="Shortlisted-Resume-list"
            element={<ShortlistedResumelist />}
          />
          <Route
            path="Leave-Management-Navigation"
            element={<LeaveManagementNavigation />}
          />
          <Route
            path="Advance-Management-Navigation"
            element={<AdvanceManagementNavigation />}
          />
          <Route
            path="Performance-Appraisal-Navigation"
            element={<AppraisalNavigation />}
          />
          <Route path="Duty-Roster-Master" element={<DutyRoster />} />
          <Route path="Employee-Reports" element={<EmployeeReport />} />
          {/* Employee Request */}
          <Route path="Navigation-leave" element={<LeaveNavigation />} />
          <Route path="Navigation-Advance" element={<AdvanceNavigation />} />
          <Route path="Employee-PaySlip-View" element={<PayslipView />} />
          <Route path="Shift-Details" element={<ShiftDetails />} />
          {/* User Control */}
          <Route path="Role-Management" element={<RoleManagement />} />
          <Route path="Register-User" element={<UserRegister />} />
          <Route path="User-List" element={<UserList />} />
          <Route path="Account Settings" element={<AccountSettings />} />
          <Route path="Clinic Details" element={<ClinicDetails />} />
          <Route path="EmployeeQue-List" element={<EmployeeQueList />} />
         
          {/* Masters */}
          <Route path="GroupMaster" element={<GroupMaster />} />
          <Route path="ResulEntryValueList" element={<ResultEntryMasterList />} />

          <Route path="FormulaList" element={<FormulaList />} />
          <Route path="FormulaMaster" element={<FormulaMaster />} />


          <Route path="GroupMasterList" element={<GroupMasterList />} />
          <Route path="Patient-group" element={<PatientGroupName />} />

          <Route path="ResultEntryMaster" element={<ResultEntryMaster />} />

          <Route path="MastersNavigation" element={<MastersNavigation />} />

          <Route path="ExternalLabmaster" element={<ExternalLab />} />
          <Route path="ExternalLabmasterList" element={<ExternalLabList />} />
          
          <Route path="AssetTypemaster" element={<AssetType />} />
          <Route path="AntibioticGroupmaster" element={<AntibioticGroup />} />
          <Route
            path="TestMastersNavigation"
            element={<TestMasterNavigation />}
          />
     

<Route
            path="TestMaster"
            element={<TestMaster />}
          />

          
          <Route path="Referdoctormaster" element={<ReferDocs />} />
          <Route path="Culturemaster" element={<Cultruretest />} />
          <Route path="Ratecardlims" element={<RatecardLims />} />
          <Route path="Outsourceratecard" element={<Outsourceratecard />} />
          <Route path="OfferPackage" element={<OfferPackage />} />
          <Route path="OfferPackageList" element={<OfferPackageList />} />
          {/* Receptionist */}
          <Route path="ReceptionistBilling" element={<Billing />} />
          <Route path="Lab-Calendar" element={<LabCalendar />} />
          {/* <Route path="PatientData" element={<PatientData />} /> */}
          <Route path="Patientlistforlab" element={<PatientListforLab />} />
          <Route
            path="PatientRequestlistforlab"
            element={<PatientRequestforLab /> }
          />









          <Route path="PaymentHistroy" element={<PaymentHistory />} />
          <Route path="Bill-Cancellation" element={<Billcancelation />} />
          <Route path="Bill-Edit" element={<BillEdit />} />
          <Route path="BarcodePrint" element={<BarcodePrint />} />
           <Route path="Deu-History" element={<DeuHistory />} />
          <Route path="Deu-History-List" element={<DueHistoryList />} /> 
          {/* Phelobotomist */}
          <Route path="CaptureSamplelist" element={<CaptureSample />} />
          <Route path="PhelobotomistCapture" element={<Navigationple />} />
          <Route path="SamplelistData" element={<SampleList />} />
          <Route path="TransactionHistory" element={<TransactionHistory />} />
          {/* Lab Technicians */}
          <Route path="AnalayseReportEntry" element={<Analyzereport />} />
          <Route path="NavigationLabtechnician" element={<NavigationLab />} />
          <Route path="ValidatedQue" element={<ValidatedQueue />} />
          {/* Sr. LabTechnician */}
          <Route path="VerifyReportEntry" element={<Verifyreport />} />
          <Route path="NavigationSenior" element={<Navigationseior />} />
          <Route path="SrLabValidatedQue" element={<SrValidatedQueue />} />
          <Route path="SrLabreportpreview" element={<Reportspreview />} />
          {/* Pathologist */}
          <Route path="AuthorizedReportEntry" element={<Approvereport />} />
          <Route path="NavigationPathalogist" element={<Navigationpath />} />
          <Route path="PathValidatedQue" element={<PathValidatedQueue />} />
          <Route path="Pathreportpreview" element={<PathologyEdit />} />
          {/* Dispatch */}
          <Route path="DispatchEntryList" element={<DispatchList />} />
          <Route path="CompleteReports" element={<Reports />} />
          <Route path="DispatchCompletedList" element={<DispatchCompletedList />} />
          {/* Radiology */}
          <Route path="RadiologyMaster" element={<RadiologyMaster />} />
          <Route path="FranchiseMaster" element={<FranchiseMaster />} />
          <Route path="tatreport" element={<TAT />} />
          <Route path="ReferingDoctorReport" element={<ReferDocreport />} />
          <Route path="Userwisebillreport" element={<Userwisebillreport />} />
          <Route path="Audittrailreport" element={<AuditTrial />} />
          <Route
            path="ReferDocreviewreport"
            element={<ReferDocreviewreport />}
          />
          <Route path="RevenueByDepartment" element={<RevenueByDepartment />} />
          <Route
            path="Refering-Hospital-Report"
            element={<ReferingHospitalReport />}
          />
          <Route path="Regis-Report" element={<RegisReport />} />

          
          <Route path="FinanceReport" element={<FinanceReport />} />
          

          <Route path="TestWise-Report" element={<TestWiseReport />} />
          {/* Finance */}
          <Route path="AccountsMasterCreate" element={<AccountsMaster />} />
          <Route path="AccountsMasterList" element={<AccountsMasterList />} />
          <Route path="ContraVoucherFinance" element={<ContraVoucher />} />
          <Route
            path="ContraVoucherListFinance"
            element={<ContraVoucherFinance />}
          />
          <Route
            path="TransactionTypeMaster"
            element={<TransactionTypeMaster />}
          />
          <Route
            path="PaymentVoucherFinance"
            element={<PaymentVoucherFinance />}
          />
          <Route
            path="PaymentVoucherFinanceList"
            element={<PaymentVoucherFinanceList />}
          />
          <Route
            path="ReceiptVoucherFinance"
            element={<ReceiptVoucherFinance />}
          />
          <Route
            path="ReceiptVoucherFinanceList"
            element={<ReceiptVoucherFinanceList />}
          />
          <Route path="JournalentryFinance" element={<JournalentryFinance />} />
          <Route
            path="JournalentryFinanceList"
            element={<JournalentryFinanceList />}
          />
          <Route path="CashbookFinance" element={<CashbookFinance />} />
          <Route path="BankbookFinance" element={<BankbookFinance />} />
          <Route path="DayBookFinance" element={<DayBookFinance />} />
          <Route path="TrialBalanceFinance" element={<TrialBalanceFinance />} />
          <Route path="BalanceSheetFinance" element={<BalanceSheetFinance />} />
          <Route
            path="ProfitandLossFinance"
            element={<ProfitandLossFinance />}
          />
          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />
          {/* NotFound */}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/" element={<LoginPage />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
