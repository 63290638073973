import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";

function OrganismMaster() {
  const [organismData, setOrganismData] = useState([]);
  const [organismCode, setOrganismCode] = useState("");
  console.log(organismCode);
  const [organismName, setOrganismName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleSubmitOrganismMaster = async () => {
    if (!organismCode.trim() || !organismName.trim()) {
      userwarn(" Both OrganismCode And  OrganismName  are required.");
      return; // Exit the function early if validation fails
    }
    try {
      const response = await axios.post(
        `${urllink}usercontrol/insertorganismmaster`,
        {
          organismCode,
          organismName,
        }
      );

      console.log(response.data);
      successMsg("Succesfully Inserted");
      fetchOrganismMastercodeData();
      // setOrganismCode('');
      setOrganismName("");
      fetchOrganismMasterData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchOrganismMasterData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getorganismmaster`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setOrganismData(data);
      })
      .catch((error) => {
        console.error("Error fetching organismmaster data:", error);
        setOrganismData([]); // Reset data in case of an error
      });
  }, [urllink]);

  const fetchOrganismMastercodeData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getorganismmastercode`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setOrganismCode(data.organism_code);
      })
      .catch((error) => {
        console.error("Error fetching organismmaster data:", error);
        setOrganismData([]); // Reset data in case of an error
      });
  }, [urllink]);

  const handleEdit = (row) => {
    setOrganismCode(row.organism_code);
    setOrganismName(row.organism_name);
    setIsEditMode(true);
    setSelectedMethodId(row.organism_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(
        `${urllink}usercontrol/updateorganismmaster`,
        {
          method_id: selectedMethodId,
          method_name: organismCode,
          method_code: organismName,
        }
      );

      console.log(response.data);
      successMsg(response.data.message);
      fetchOrganismMastercodeData();
      // setOrganismCode('');
      setOrganismName("");
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchOrganismMasterData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetchOrganismMasterData();
    fetchOrganismMastercodeData();
  }, [fetchOrganismMasterData, fetchOrganismMastercodeData]);

  const organismcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "organism_code",
      name: "Organism Code",
    },
    {
      key: "organism_name",
      name: "Organism Name",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <p
          onClick={() => handleEdit(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}

        >
          <EditIcon />
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <br />

        <div className="ShiftClosing_Container">
          <div className="con_1">
            <div className="inp_1">
              <label htmlFor="input">
                Organism Code <span>:</span>
              </label>
              <input
                type="text"
                id="organismCode"
                name="organismCode"
                value={organismCode}
                disabled
                onChange={(e) => setOrganismCode(e.target.value)}
                placeholder="Enter Organism Code"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Organism Name <span>:</span>
              </label>
              <input
                type="text"
                id="organismName"
                name="organismName"
                value={organismName}
                onChange={(e) => setOrganismName(e.target.value)}
                placeholder="Enter Organism Name"
              />
            </div>
            <button
              className="RegisterForm_1_btns"
              onClick={
                isEditMode ? handleUpdateMethod : handleSubmitOrganismMaster
              }
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
          </div>

          <div className="Main_container_app">
            <ReactGrid columns={organismcolumns} RowData={organismData} />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default OrganismMaster;
