import React, { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";

const EmployeeList = () => {
  const dispatchvalue = useDispatch();
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [filteredRows, setFilteredRows] = useState([]);
  console.log(filteredRows);

  console.log(userRecord);
  const [rows, setRows] = useState([]);

  const fetchemployeelist = useCallback(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails_forlist?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setRows(
          data.map((row, index) => ({
            id: index + 1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, userRecord]);

  useEffect(() => {
    fetchemployeelist();
  }, [fetchemployeelist]);

  const navigate = useNavigate();

  const handleList = (params) => {
    console.log(params);
    axios
      .get(
        `${urllink}HRmanagement/get_for_employeeprofile?employeeid=${params.row.EmployeeID}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        dispatchvalue({ type: "foremployeedata", value: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/Home/Employee-Profile");
  };

  const handleEditList = (params) => {
    console.log(params);
    axios
      .get(
        `${urllink}HRmanagement/getfor_employeeedit?employeeid=${params.row.EmployeeID}`
      )
      .then((response) => {
        console.log(response.data);
        dispatchvalue({ type: "foremployeeedit", value: response.data });
        navigate("/Home/Employee-Register");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.EmployeeName.toLowerCase();
      const lowerCasePhoneNo = row?.PhoneNumber?.toString();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, rows]);

  const handlestatus = (params) => {
    console.log(params);

    let newstatus;

    if (params.row.EmployeeStatus === "active") {
      newstatus = "Inactive";
    } else if (params.row.EmployeeStatus === "Inactive") {
      newstatus = "active";
    }

    axios
      .post(
        `${urllink}HRmanagement/update_employeestatus?newstatus=${newstatus}&Employeeid=${params.row.EmployeeID}`
      )
      .then((res) => {
        console.log(res);
        fetchemployeelist();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Agesetupcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "EmployeeID",
      name: "ID",
      width: 100,
      frozen: true,
    },
    {
      key: "EmployeePhoto",
      name: "Photo",
      width: 90,
      frozen: true,
      renderCell: (params) => (
        <img
          src={params.row.EmployeePhoto}
          style={{
            height: "39px",
            width: "41px",
            borderRadius: "22px",
          }}
          alt={params.row.EmployeeName}
        />
      ),
    },
    {
      key: "EmployeeName",
      name: "Name",
      frozen: true,
      width: 300,
    },

    {
      key: "PhoneNumber",
      name: "Phone No",
      frozen: true,
    },

    {
      key: "Designation",
      name: "Designation",
      frozen: true,
    },
    {
      key: "EmployeeStatus",
      name: "Status",
      width: 70,
      renderCell: (params) => (
        <button onClick={() => handlestatus(params)} className="Addnamebtn_pt2">
          {params.row.EmployeeStatus}
        </button>
      ),
    },
    {
      key: "Reference",
      name: "Profile",
      width: 90,
      renderCell: (params) => (
        <Button
          onClick={() => handleList(params)}
          // startIcon={<VisibilityIcon />}
        >
          <span style={{ color: "black" }}>View</span>
        </Button>
      ),
    },

    {
      key: "EditAction",
      name: "Action",
      width: 90,
      renderCell: (params) => (
        <Button
          onClick={() => handleEditList(params)}
          // startIcon={<EditIcon />}
        >
          <span style={{ color: "black" }}>Edit</span>
        </Button>
      ),
    },
  ];

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Employee List</h4>
      </div>
      <br />

      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">
            Employee Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Enter Employee Name"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Phone No <span>:</span>
          </label>
          <input
            type="number"
            id="PhoneNo"
            value={searchQuery1}
            onChange={handleSearchChange}
            placeholder="Enter Phone No"
          />
        </div>
      </div>

      <div className="Main_container_app">
        <ReactGrid columns={Agesetupcolumns} RowData={filteredRows} />
      </div>
    </div>
  );
};

export default EmployeeList;
