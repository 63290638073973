import React, { useState, useEffect } from "react";
import "./UserRegister.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

function UserRegister() {
  const foruserregisteremployeedata = useSelector(
    (state) => state.userRecord?.foredituserregisteremployeedata
  );
  console.log(foruserregisteremployeedata);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [DepartmentData, setDepartmentData] = useState([]);

  const [formData, setFormData] = useState({
    username: "",
    employeeid: "",
    firstName: "",
    lastName: "",
    Title: "",
    PhoneNo: "",
    email: "",
    password: "",
    roleName: "",
    gender: "",
    qualification: "",
  });

  const [checkedItems, setCheckedItems] = useState([
    {
      key: "1",
      value: "A",
      label: "ClinicMetrics",
      check: false,
      children: [
        { key: "1-1", value: "A1-1", label: "ClinicMetrics", check: false },
      ],
    },
    {
      key: "2",
      value: "B",
      label: "Receptionist",
      check: false,
      children: [
        { key: "2-1", value: "B2-1", label: "Billing", check: false },
        { key: "2-2", value: "B2-2", label: "Request List", check: false },
        { key: "2-3", value: "B2-3", label: "Bill Cancellation", check: false },
        { key: "2-4", value: "B2-4", label: "Patient List", check: false },
        { key: "2-5", value: "B2-5", label: "Payment History", check: false },
        { key: "2-6", value: "B2-6", label: "Deu History", check: false },

        {
          key: "2-7",
          value: "B2-7",
          label: "Appointment Request",
          check: false,
        },
        {
          key: "2-8",
          value: "B2-8",
          label: "Appointment Calendar",
          check: false,
        },
        { key: "2-9", value: "B2-9", label: "Bill Edit", check: false },
      ],
    },
    {
      key: "3",
      value: "C",
      label: "Phelobotomist",
      check: false,
      children: [
        {
          key: "3-1",
          value: "C3-1",
          label: "Sample Waiting List",
          check: false,
        },
        { key: "3-2", value: "C3-2", label: "Request List", check: false },
        { key: "3-3", value: "C3-3", label: "Completed List", check: false },
      ],
    },
    {
      key: "4",
      value: "D",
      label: "Lab Technicians",
      check: false,
      children: [
        { key: "4-1", value: "D4-1", label: "Report Entry Que", check: false },
        { key: "4-2", value: "D4-2", label: "Validated Que", check: false },
      ],
    },
    {
      key: "5",
      value: "E",
      label: "Senior Technicians",
      check: false,
      children: [
        { key: "5-1", value: "E5-1", label: "Report Verify Que", check: false },
        { key: "5-2", value: "E5-2", label: "Validated Que", check: false },
      ],
    },
    {
      key: "6",
      value: "F",
      label: "Pathologist",
      check: false,
      children: [
        {
          key: "6-1",
          value: "F6-1",
          label: "Report Approval Que",
          check: false,
        },
        { key: "6-2", value: "F6-2", label: "Validated Que", check: false },
      ],
    },
    {
      key: "7",
      value: "G",
      label: "Dispatch",
      check: false,
      children: [
        {
          key: "7-1",
          value: "G7-1",
          label: "Waiting For Dispatch",
          check: false,
        },
      ],
    },
    {
      key: "8",
      value: "H",
      label: "PettyCash",
      check: false,
      children: [
        { key: "8-1", value: "H8-1", label: "Expense Master", check: false },
        { key: "8-2", value: "H8-2", label: "Cash Expenses", check: false },
        { key: "8-3", value: "H8-3", label: "Expenses Report", check: false },
        { key: "8-4", value: "H8-4", label: "Day Closing", check: false },
        { key: "8-5", value: "H8-5", label: "Day Report", check: false },
        { key: "8-6", value: "H8-6", label: "Doctors Payout", check: false },
        {
          key: "8-7",
          value: "H8-7",
          label: "Doctors Payout Report",
          check: false,
        },
        { key: "8-8", value: "H8-8", label: "Due Payout", check: false },
      ],
    },
    // {
    //   key: "9",
    //   value: "I",
    //   label: "Lab Inventory Master",
    //   check: false,
    //   children: [
    //     { key: "9-1", value: "I9-1", label: "Supplier List", check: false },
    //     {
    //       key: "9-2",
    //       value: "I9-2",
    //       label: "Product Type Master",
    //       check: false,
    //     },
    //     { key: "9-3", value: "I9-3", label: "Pharmacy Master", check: false },
    //   ],
    // },

    // {
    //   key: "10",
    //   value: "J",
    //   label: "CentralStore",
    //   check: false,
    //   children: [
    //     { key: "11-1", value: "J11-1", label: "Purchase Raise", check: false },
    //     { key: "11-2", value: "J11-2", label: "GRN List", check: false },
    //     { key: "11-3", value: "J11-3", label: "Lab QuickStock", check: false },
    //     { key: "11-4", value: "J11-4", label: "Purchase Return", check: false },
    //     { key: "11-5", value: "J11-5", label: "Supplier Pay", check: false },
    //     { key: "11-6", value: "J11-6", label: "Indent Issue", check: false },
    //     { key: "11-7", value: "J11-7", label: "StockList", check: false },
    //   ],
    // },
    // {
    //   key: "11",
    //   value: "K",
    //   label: "Indent Approve",
    //   check: false,
    //   children: [
    //     { key: "11-1", value: "K11-1", label: "Indent Raise", check: false },
    //     { key: "11-2", value: "K11-2", label: "Indent Recieve", check: false },
    //     { key: "11-3", value: "K11-3", label: "Indent Return", check: false },
    //     {
    //       key: "11-4",
    //       value: "K11-4",
    //       label: "Location StockList",
    //       check: false,
    //     },
    //   ],
    // },

    {
      key: "12",
      value: "L",
      label: "Reports",
      check: false,
      children: [
        {
          key: "12-1",
          value: "L12-1",
          label: "Supplier Pay List",
          check: false,
        },
        {
          key: "12-2",
          value: "L12-2",
          label: "Purchase Register",
          check: false,
        },
        { key: "12-3", value: "L12-3", label: "Sales Register", check: false },
        {
          key: "12-4",
          value: "L12-4",
          label: "Revenue By Department",
          check: false,
        },
        { key: "12-5", value: "L12-5", label: "TAT Report", check: false },
        {
          key: "12-6",
          value: "L12-6",
          label: "Refering Doctor List",
          check: false,
        },
        {
          key: "12-7",
          value: "L12-7",
          label: "User Wise Collection",
          check: false,
        },
        { key: "12-8", value: "L12-8", label: "Audit Trail", check: false },
        { key: "12-9", value: "L12-9", label: "Review Report", check: false },
        {
          key: "12-10",
          value: "L12-10",
          label: "Refering Hospital Report",
          check: false,
        },

        {
          key: "12-11",
          value: "L12-11",
          label: "Register Report",
          check: false,
        },
        {
          key: "12-12",
          value: "L12-12",
          label: "TestWise Report",
          check: false,
        },
        {
          key: "12-13",
          value: "L12-13",
          label: "Finance Report",
          check: false,
        },
      ],
    },
    {
      key: "13",
      value: "M",
      label: "HRManagement",
      check: false,
      children: [
        {
          key: "13-1",
          value: "M13-1",
          label: "EmployeeRegister",
          check: false,
        },
        { key: "13-2", value: "M13-2", label: "EmployeeList", check: false },
        // { key: "13-3", value: "M13-3", label: "Attendance", check: false },
        // { key: "13-4", value: "M13-4", label: "PayRoll", check: false },
        // { key: "13-5", value: "M13-5", label: "DutyManagement", check: false },
        // {
        //   key: "13-6",
        //   value: "M13-6",
        //   label: "DutyRosterMaster",
        //   check: false,
        // },
        // { key: "13-7", value: "M13-7", label: "LeaveManagement", check: false },
        // {
        //   key: "13-8",
        //   value: "M13-8",
        //   label: "AdvanceManagement",
        //   check: false,
        // },
        // {
        //   key: "13-9",
        //   value: "M13-9",
        //   label: "PerformanceManagement",
        //   check: false,
        // },
        // {
        //   key: "13-10",
        //   value: "M13-10",
        //   label: "EmployeeReport",
        //   check: false,
        // },
      ],
    },
    // {
    //   key: "14",
    //   value: "N",
    //   label: "EmployeeRequest",
    //   check: false,
    //   children: [
    //     { key: "14-1", value: "N14-1", label: "LeaveManagement", check: false },
    //     {
    //       key: "14-2",
    //       value: "N14-2",
    //       label: "AdvanceManagement",
    //       check: false,
    //     },
    //     {
    //       key: "14-3",
    //       value: "N14-3",
    //       label: "Shift Management",
    //       check: false,
    //     },
    //     {
    //       key: "14-4",
    //       value: "N14-4",
    //       label: "Pay Slip Download",
    //       check: false,
    //     },
    //   ],
    // },
    {
      key: "15",
      value: "O",
      label: "Masters",
      check: false,
      children: [
        {
          key: "15-1",
          value: "O15-1",
          label: "Master",
          check: false,
        },
        // {
        //   key: "15-2",
        //   value: "O15-2",
        //   label: "Units Management",
        //   check: false,
        // },
        // {
        //   key: "15-3",
        //   value: "O15-3",
        //   label: "Container Management",
        //   check: false,
        // },
        // {
        //   key: "15-4",
        //   value: "O15-4",
        //   label: "Specimen Management",
        //   check: false,
        // },
        // {
        //   key: "15-5",
        //   value: "O15-5",
        //   label: "Methods Management",
        //   check: false,
        // },
        // {
        //   key: "15-6",
        //   value: "O15-6",
        //   label: "Organism Masters",
        //   check: false,
        // },
        // {
        //   key: "15-7",
        //   value: "O15-7",
        //   label: "Antibiotic Masters",
        //   check: false,
        // },
        // {
        //   key: "15-8",
        //   value: "O15-8",
        //   label: "Equipments Master",
        //   check: false,
        // },

        {
          key: "15-2",
          value: "O15-2",
          label: "Formula Master",
          check: false,
        },
        {
          key: "15-3",
          value: "O15-3",
          label: "TestMasters",
          check: false,
        },
        {
          key: "15-4",
          value: "O15-4",
          label: "ResultEntry Master",
          check: false,
        },
        { key: "15-5", value: "O15-5", label: "TestMaster Navigation", check: false },
        {
          key: "15-6",
          value: "O15-6",
          label: "External Lab Master",
          check: false,
        },
        {
          key: "15-7",
          value: "O15-7",
          label: "Department Order Master",
          check: false,
        },
        {
          key: "15-8",
          value: "O15-8",
          label: "Group Master",
          check: false,
        },
        {
          key: "15-9",
          value: "O15-9",
          label: "Profile Master",
          check: false,
        },
        {
          key: "15-10",
          value: "O15-10",
          label: "Refer Doctor Master",
          check: false,
        },
        {
          key: "15-11",
          value: "O15-11",
          label: "RateCard Master",
          check: false,
        },
      ],
    },
    {
      key: "16",
      value: "P",
      label: "UserControl",
      check: false,
      children: [
        { key: "16-1", value: "P16-1", label: "RoleManagement", check: false },
        {
          key: "16-2",
          value: "P16-2",
          label: "EmployeeQueueList",
          check: false,
        },
        { key: "16-3", value: "P16-3", label: "UserRegister", check: false },
        { key: "16-4", value: "P16-4", label: "AccountSettings", check: false },
        { key: "16-5", value: "P16-5", label: "ClinicDetails", check: false },
        { key: "16-6", value: "P16-6", label: "UserList", check: false },
       
      ],
    },
    {
      key: "17",
      value: "R",
      label: "Accounts",
      check: false,
      children: [
        { key: "17-1", value: "R17-1", label: "AccountsMaster", check: false },
        {
          key: "17-2",
          value: "R17-2",
          label: "TransactionTypeMaster",
          check: false,
        },
        { key: "17-3", value: "R17-3", label: "ContraVoucher", check: false },
        { key: "17-4", value: "R17-4", label: "PaymentVoucher", check: false },
        { key: "17-5", value: "R17-5", label: "ReceiptVoucher", check: false },
        { key: "17-6", value: "R17-6", label: "JournalVoucher", check: false },
        { key: "17-7", value: "R17-7", label: "Cashbook", check: false },
        {
          key: "17-8",
          value: "R17-8",
          label: "Bankbook",
          check: false,
        },
        { key: "17-9", value: "R17-9", label: "Daybook", check: false },
        { key: "17-10", value: "R17-10", label: "TrialBalance", check: false },
        { key: "17-11", value: "R17-11", label: "BalanceSheet", check: false },
        { key: "17-12", value: "R17-12", label: "ProfitandLoss", check: false },
      ],
    },
  ]);

  const [Locations, setLocations] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [LocationData, setLocationData] = useState([]);
  const [ParentData, setParentData] = useState([]);
  const [ChildData, setChildData] = useState([]);
  const [selectdepartmentdata, setselectdepartmentdata] = useState([]);
  console.log(selectdepartmentdata);

  const handleParentChange = (index) => {
    setCheckedItems((prev) => {
      const updated = [...prev];
      updated[index].check = !updated[index].check;
      if (updated[index].children.length > 0) {
        updated[index].children.forEach((child) => {
          if (updated[index].check) {
            child.check = true;
            setChildData((prev) => [...prev, child.value]);
          } else {
            child.check = false;
            setChildData((prev) =>
              prev.filter((value) => value !== child.value)
            );
          }
        });
      }
      if (updated[index].check) {
        setParentData((prev) => [...prev, updated[index].value]);
      } else {
        setParentData((prev) =>
          prev.filter((value) => value !== updated[index].value)
        );
      }
      return updated;
    });
  };

  const handleChildChange = (parentIndex, childIndex) => {
    setCheckedItems((prev) => {
      const updated = [...prev];
      updated[parentIndex].children[childIndex].check =
        !updated[parentIndex].children[childIndex].check;
      if (updated[parentIndex].children[childIndex].check) {
        setChildData((prev) => [
          ...prev,
          updated[parentIndex].children[childIndex].value,
        ]);
      } else {
        setChildData((prev) =>
          prev.filter(
            (value) => value !== updated[parentIndex].children[childIndex].value
          )
        );
      }
      if (updated[parentIndex].children.some((child) => child.check)) {
        updated[parentIndex].check = true;
        setParentData((prev) => {
          if (!prev.includes(updated[parentIndex].value)) {
            return [...prev, updated[parentIndex].value];
          }
          return prev;
        });
      } else {
        updated[parentIndex].check = false;
        setParentData((prev) =>
          prev.filter((value) => value !== updated[parentIndex].value)
        );
      }
      return updated;
    });
  };

  useEffect(() => {
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0
      axios
        .get(
          `${urllink}usercontrol/get_for_useregister_access?employeeid=${employeeData.EmployeeID}`
        )
        .then((response) => {
          const data = response.data[0];
          console.log(data);
          setFormData((prevFormData) => ({
            ...prevFormData,
            employeeid: employeeData.EmployeeID,
            username: data?.username,
            firstName: employeeData.EmployeeName,
            lastName: employeeData.FatherName,
            Title: employeeData.Title,
            PhoneNo: employeeData.PhoneNumber,
            email: employeeData.Email,
            roleName: employeeData.Designation,
            gender: employeeData.Gender,
            qualification: employeeData.Qualification,
          }));

          // Preselect Locations
          const preselectedLocations = data.Location.split(",");
          setLocationData(preselectedLocations);

          // Preselect Access
          const accessOne = data.Access_one.split(",");
          if (accessOne.length > 0) {
            setIsUpdate(true);
          } else {
            setIsUpdate(false);
          }
          const accessTwo = data.Access_two.split(",");
          const preselectedAccess = [...accessOne, ...accessTwo];

          setCheckedItems((prevItems) =>
            prevItems.map((item) => {
              const isParentChecked = preselectedAccess.includes(item.value);
              const updatedChildren = item.children.map((child) => ({
                ...child,
                check: preselectedAccess.includes(child.value),
              }));
              return {
                ...item,
                check: isParentChecked,
                children: updatedChildren,
              };
            })
          );

          setParentData(accessOne);
          setChildData(accessTwo);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [foruserregisteremployeedata, urllink]);

  const handlelocationChange = (lname) => {
    setLocationData((prev) => {
      if (lname === "ALL") {
        if (!prev.includes("ALL")) {
          // Select all locations
          return Locations.map((location) => location.location_name);
        } else {
          // Deselect all locations
          return [];
        }
      } else {
        // Normal selection logic
        if (!prev.includes(lname)) {
          const newSelection = [...prev, lname];
          // Check if all other locations are selected
          const allOtherSelected = Locations.every(
            (location) =>
              location.location_name === "ALL" ||
              newSelection.includes(location.location_name)
          );
          return allOtherSelected
            ? Locations.map((location) => location.location_name)
            : newSelection;
        } else {
          return prev.filter((value) => value !== lname && value !== "ALL");
        }
      }
    });
  };

  const handledepartmentchange = (lname) => {
    setselectdepartmentdata((prev) => {
      if (lname === "ALL") {
        if (!prev.includes("ALL")) {
          // Select all departments
          return DepartmentData.map((department) => department.Department_name);
        } else {
          // Deselect all departments
          return [];
        }
      } else {
        // Normal selection logic
        if (!prev.includes(lname)) {
          const newSelection = [...prev, lname];
          // Check if all other departments are selected
          const allOtherSelected = DepartmentData.every(
            (department) =>
              department.Department_name === "ALL" ||
              newSelection.includes(department.Department_name)
          );
          return allOtherSelected
            ? DepartmentData.map((department) => department.Department_name)
            : newSelection;
        } else {
          // If already included, remove the department
          return prev.filter((value) => value !== lname && value !== "ALL");
        }
      }
    });
  };

  useEffect(() => {
    // Fetch role options from the backend and update state
    fetchRoleOptions();
    fetchlocationOptions();
    // Log all user data when the page reloads
    fetchUserList();
  }, []);

  const fetchlocationOptions = () => {
    // Fetch role options from the backend
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data) {
          setLocations(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    fetchUserList()
      .then((usernames) => {
        if (usernames === null) {
          // Handle the case when usernames are null
        } else if (!isUpdate && usernames.includes(formData.username)) {
          // Check if it's not an update and username already exists
          userwarn();
          setFormData({ ...formData, username: "" });
        } else {
          // Filter out 'ALL' from LocationData before submitting
          const filteredLocationData = LocationData.filter(
            (location) => location !== "ALL"
          );

          const datatosend = {
            ...formData,
            created_by: userRecord?.username || "",
            ChildData: ChildData.join(","),
            ParentData: ParentData.join(","),
            LocationData: filteredLocationData.join(","),
            departmentdata: selectdepartmentdata.join(","),
          };

          console.log(datatosend);

          const url = isUpdate
            ? `${urllink}usercontrol/update_userregister`
            : `${urllink}usercontrol/insert_userregister`;

          axios
            .post(url, datatosend)
            .then((response) => {
              if (response.data.message) {
                successMsg(response.data.message);
                fetchRoleOptions();
                if (isUpdate) {
                  setIsUpdate(false);
                }
              } else {
                console.log(response.data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  console.log(Locations);
  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        `${urllink}usercontrol/userRegistercheck`
      );
      const data = response.data;
      console.log(data);
      return data.usernames; // Return only the 'usernames' array from the response data
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };

  const fetchRoleOptions = () => {
    // Fetch role options from the backend
    axios
      .get(`${urllink}usercontrol/getRoleData`)
      .then((response) => {
        console.log(response);
        // setRoleOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching role options:", error);
      });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  // const fail = () => {
  //   toast.warn("User Registration Failed", {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { marginTop: "50px" },
  //   });
  // };
  const userwarn = () => {
    toast.warn("Username already Exists", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleinpchange = (e) => {
    const { name, value } = e.target;
    if (name === "PhoneNo") {
      if (value.length <= 10) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getDepartment_for_userregister`)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setDepartmentData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
  }, [urllink]);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>User Registration</h4>
        </div>
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="username">
                Employee ID<span>:</span>
              </label>
              <input
                type="text"
                id="employeeid"
                name="employeeid"
                value={formData.employeeid}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="username">
                Username<span>:</span>
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="password">
                Password<span>:</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleinpchange}
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="username">
                Title<span>:</span>
              </label>
              <input
                name="Title"
                value={formData.Title}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="firstName">
                First Name<span>:</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="lastName">
                Last Name<span>:</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="Dateofbirth">
                Gender<span>:</span>
              </label>
              <input
                type="text"
                value={formData.gender}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="PhoneNo">
                Phone No<span>:</span>
              </label>
              <input
                type="number"
                name="PhoneNo"
                value={formData.PhoneNo}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="Qualification">
                Qualification<span>:</span>
              </label>
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleinpchange}
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="email">
                Email<span>:</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleinpchange}
                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">
                Role<span>:</span>
              </label>
              <input
                name="role"
                value={formData.roleName}
                onChange={handleinpchange}
              />
            </div>
          </div>

          <br />
          <div className="Add_items_Purchase_Master">
            <span>Location</span>
          </div>
          <div className="displayuseraccess">
            {Locations.map((p, indx) => (
              <div className="displayuseraccess_child" key={indx}>
                <input
                  type="checkbox"
                  id={`${indx}_${p?.location_name}`}
                  checked={LocationData.includes(p?.location_name)}
                  onChange={() => handlelocationChange(p?.location_name)}
                />
                <label
                  htmlFor={`${indx}_${p?.location_name}`}
                  className="par_acc_lab"
                >
                  {p?.location_name}
                </label>
              </div>
            ))}
          </div>
          <div className="Add_items_Purchase_Master">
            <span>Deprtment</span>
          </div>
          <div className="displayuseraccess">
            {DepartmentData.map((p, indx) => (
              <div className="displayuseraccess_child" key={indx}>
                <input
                  type="checkbox"
                  id={`${indx}_${p?.sub_department_name}`}
                  checked={selectdepartmentdata.includes(
                    p?.sub_department_name
                  )}
                  onChange={() =>
                    handledepartmentchange(p?.sub_department_name)
                  }
                />
                <label
                  htmlFor={`${indx}_${p?.sub_department_name}`}
                  className="par_acc_lab"
                >
                  {p?.sub_department_name}
                </label>
              </div>
            ))}
          </div>
          <br></br>
          <div className="Add_items_Purchase_Master">Access</div>
          <div className="displayuseraccess">
            {checkedItems.map((item, indx) => (
              <div key={indx} className="displayuseraccess_child">
                <input
                  type="checkbox"
                  id={item.key}
                  checked={item.check}
                  onChange={() => handleParentChange(indx)}
                />
                <label htmlFor={item.key} className="par_acc_lab">
                  {item.label}
                </label>
                {item.children.map((child, ind1) => (
                  <div
                    key={ind1}
                    style={{ marginLeft: "20px", marginTop: "5px" }}
                  >
                    <input
                      type="checkbox"
                      id={child.key}
                      checked={child.check}
                      onChange={() => handleChildChange(indx, ind1)}
                    />
                    <label htmlFor={child.key} className="chi_acc_lab">
                      {child.label}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="Register_btn_con" style={{ marginTop: "20px" }}>
          <button
            onClick={handleSubmit}
            type="submit"
            className="RegisterForm_1_btns"
          >
            {isUpdate ? "Update" : "Submit"}
          </button>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        containerId="toast-container-over-header"
      />
    </>
  );
}

export default UserRegister;
