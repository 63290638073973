import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Select from "react-select";

function TestMaster() {
  // const [highlight, setHighlight] = useState(false);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  // const [radio, setRadio] = useState();
  const [roleData, setRoleData] = useState([]);
  // const [testDesData, setTestDesData] = useState([]);
  const [containerdata, setContainerData] = useState([]);
  const [methoddata, setMethodData] = useState([]);
  const [unitdata, setUnitData] = useState([]);
  const [specimendata, setSpecimenData] = useState([]);
  const [subDep, setSetDep] = useState([]);

  const testdata = useSelector((state) => state.userRecord?.TestMaster);
  console.log("testdata", testdata);
  const [TestName, setTestName] = useState([]);

  const [testMasterData, setTestMasterData] = useState({
    testName: "",
    testCode: "",
    department: "",
    subdepartment: "",
    header: "",
    displayText: "",
    billingName: "",
    container: "",
    specimen: "",
    method: "",
    gender: "",
    inputType: "",
    decimalPlaces: "",
    inputPatternType: "",
    testCategory: "",
    logicalCategory: "",
    capturedUnit: "",
    uom: "",
    reportType: "",
    testInstruction: "",
    loincCode: "",
    allowDiscount: "No",
    orderable: "No",
    showGraph: "No",
    active: "No",
    stat: "No",
    nonReportable: "No",
    calculatedTest: "No",
    isOutsourced: "No",
    minimumtime: "",
    Emergencytime: "",
    timeperiod: "",
    isEditMode: false,
    formula: "",
    paraone: "",
    paratwo: "",
    Culturetest: "No",
    Checkout: "No",
    isNABHL: "No",
    isCAP: "No",
    is_Machine_Interfaced: "No",
    Reagentlevel: "",
    AssayCode: "",
    IsSubTest: "No",
    SubTestCodes: "",
  });

  console.log("testMasterData:", testMasterData);
  console.log("agesetupdata:", testdata);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getalltestname`)
      .then((response) => {
        setTestName(response.data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, [urllink]);

  const handleInputChangeRemarks = (selectedOptions) => {
    // Convert selected options to an array of test codes
    const selectedTestCodes = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    // Exclude the main test code from the selected options
    const finalTestCodes = selectedTestCodes.filter(
      (code) => code !== testMasterData?.testCode
    );

    // Convert the array to a comma-separated string
    const finalTestCodesString = finalTestCodes.join(",");

    // Update the state with the new SubTestCodes
    setTestMasterData({
      ...testMasterData,
      SubTestCodes: finalTestCodesString, // Store as a single string with commas
    });
  };

  // const userwarn = (warningMessage) => {
  //   toast.warn(`${warningMessage}`, {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { marginTop: "50px" },
  //   });
  // };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTestMasterData({
      ...testMasterData,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    });
  };

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/getdepartmentdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setRoleData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
    axios
      .get(`${urllink}mainddepartment/getsubdepartment`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setSetDep(data);
      })
      .catch((error) => {
        console.error("Error fetching SubDepartment data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getcontainer`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setContainerData(data);
      })
      .catch((error) => {
        console.error("Error fetching container data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getmethod`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setMethodData(data);
      })
      .catch((error) => {
        console.error("Error fetching method data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getunit`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setUnitData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getspecimen`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setSpecimenData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);
  useEffect(() => {
    if (testdata && Object.keys(testdata).length > 0) {
      setTestMasterData({
        testName: testdata.Test_Name || "",
        testCode: testdata.Test_Code || "",
        department: testdata.Department || "",
        subdepartment: testdata.Sub_Department || "",
        header: testdata.Header || "",
        displayText: testdata.Display_Text || "",
        billingName: testdata.Billing_Name || "",
        container: testdata.Container_Name || "",
        specimen: testdata.Specimen_Name || "",
        method: testdata.Method_Name || "",
        gender: testdata.Gender || "",
        inputType: testdata.Input_Type || "",
        decimalPlaces: testdata.Decimal_Places || "",
        inputPatternType: testdata.Input_Pattern_Type || "",
        testCategory: testdata.Test_Category || "",
        logicalCategory: testdata.Logical_Category || "",
        capturedUnit: testdata.Captured_Unit || "",
        uom: testdata.UOM || "",
        reportType: testdata.Report_Type || "",
        testInstruction: testdata.Test_Instructions || "",
        loincCode: testdata.Loinc_Code || "",
        allowDiscount: testdata.Allow_Discount || "No",
        orderable: testdata.Orderable,
        showGraph: testdata.Show_Graph || "No",
        active: testdata.Active_Status || "No",
        stat: testdata.STAT || "No",
        nonReportable: testdata.Non_Reportable || "No",
        calculatedTest: testdata.Calculated_Test || "No",
        isOutsourced: testdata.Outsourced || "No",
        minimumtime: testdata.Processing_Time || "",
        Emergencytime: testdata.Emergency_Processing_Time || "",
        timeperiod: testdata.Period_Type || "",
        isEditMode: testdata.isEditMode || false,
        formula: testdata.Formula || "",
        paraone: testdata.Parameter_One || "",
        paratwo: testdata.Parameter_Two || "",
        Culturetest: testdata.Culturetest || "No",
        Checkout: testdata.Checkout || "No",
        isNABHL: testdata.Is_NABHL || "No",
        isCAP: testdata.Is_CAP || "No",
        is_Machine_Interfaced: testdata.Is_Machine_Interfaced || "No",
        Reagentlevel: testdata.Reagent_Level || "",
        AssayCode: testdata.Assay_Code || "",
        test_id: testdata.id,
        IsSubTest: testdata?.IsSubTest,
        SubTestCodes: testdata?.SubTestCodes,
      });
    } else {
      axios
        .get(`${urllink}usercontrol/getTestCode`)
        .then((res) => {
          console.log(res.data);
          setTestMasterData((prev) => ({
            ...prev,
            testCode: res.data.TestCode,
          }));
        })
        .catch((err) => {
          console.err(err);
        });
    }
  }, [testdata, urllink]);

  const handleSubmittestData = () => {
    // if (testMasterData.testName !=='' && testMasterData.subdepartment !=='' && testMasterData.method !== '' && testMasterData.specimen !== '') {
    //   alert("Please fill out all fields.");
    //   return; // Stop the submission since not all fields are filled
    // } else {
    // If all fields are filled, proceed with the submission
    axios
      .post(`${urllink}usercontrol/inserttestmaster`, testMasterData)
      .then((response) => {
        console.log(response);
        successMsg("Successfully");
        // setHide(true);
        // Reset the form fields after successful submission
        setTestMasterData({
          testName: "",
          testCode: "",
          department: "",
          subdepartment: "",
          header: "",
          displayText: "",
          billingName: "",
          container: "",
          specimen: "",
          method: "",
          gender: "",
          inputType: "",
          decimalPlaces: "",
          inputPatternType: "",
          testCategory: "",
          logicalCategory: "",
          capturedUnit: "",
          uom: "",
          reportType: "",
          testInstruction: "",
          loincCode: "",
          highlight: "No",
          allowDiscount: "No",
          orderable: "No",
          showGraph: "No",
          active: "No",
          stat: "No",
          nonReportable: "No",
          calculatedTest: "No",
          isOutsourced: "No",
          Culturetest: "No",
          Checkout: "No",
          isNABHL: "No",
          isCAP: "No",
          is_Machine_Interfaced: "No",
          Reagentlevel: "",
          AssayCode: "",
          IsSubTest: "No",
          SubTestCodes: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // React.useEffect(() => {
  //   fetchTestDescriptionData();
  // }, []);
  // const fetchTestDescriptionData = () => {
  //   axios
  //     .get(`${urllink}usercontrol/gettestdescriptiondata`)
  //     .then((response) => {
  //       const data = response.data;
  //       console.log("data", data);

  //       // setTestDesData(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching testdescription data:", error);
  //     });
  // };

  const handleTestdata = (testData) => {
    const updatedata = {
      ...testMasterData,
    };
    console.log(updatedata);
    axios
      .post(`${urllink}usercontrol/update_test_description_data`, updatedata)
      .then((response) => {
        console.log(response.data);
        setTestMasterData({
          testName: "",
          testCode: "",
          department: "",
          subdepartment: "",
          header: "",
          displayText: "",
          billingName: "",
          container: "",
          specimen: "",
          method: "",
          gender: "",
          inputType: "",
          decimalPlaces: "",
          inputPatternType: "",
          testCategory: "",
          logicalCategory: "",
          capturedUnit: "",
          uom: "",
          reportType: "",
          testInstruction: "",
          loincCode: "",
          highlight: "No",
          allowDiscount: "No",
          orderable: "No",
          showGraph: "No",
          active: "No",
          stat: "No",
          nonReportable: "No",
          Culturetest: "No",
          Checkout: "No",
          isNABHL: "No",
          isCAP: "No",
          is_Machine_Interfaced: "No",
          Reagentlevel: "",
          AssayCode: "",
          IsSubTest: "No",
          SubTestCodes: "",
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Test Master</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="testCode">
            Test Code<span>:</span>
          </label>
          <input
            type="text"
            id="testCode"
            name="testCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            value={testMasterData.testCode}
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="testName">
            Test Name<span>:</span>
          </label>
          <input
            type="text"
            id="testName"
            name="testName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            value={testMasterData.testName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="AssayCode">
            Assay Code<span>:</span>
          </label>
          <input
            type="text"
            id="AssayCode"
            name="AssayCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            value={testMasterData.AssayCode}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="department">
            Department<span>:</span>
          </label>
          <select
            id="department"
            name="department"
            value={testMasterData.department}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            {roleData?.map((role, index) => (
              <option key={index} value={role.department_name}>
                {role.department_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="department">
            Sub Department<span>:</span>
          </label>
          <select
            id="subdepartment"
            name="subdepartment"
            value={testMasterData.subdepartment}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            {subDep?.map((role, index) => (
              <option key={index} value={role.subdepartment_name}>
                {role.subdepartment_name}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="header">
            Header<span>:</span>
          </label>
          <input
            type="text"
            id="header"
            name="header"
            pattern="[A-Za-z ]+"
            onChange={handleInputChange}
            value={testMasterData.header}
            title="Only letters and spaces are allowed"
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="displayText">
            Display Text<span>:</span>
          </label>
          <input
            type="text"
            id="displayText"
            onChange={handleInputChange}
            name="displayText"
            pattern="[A-Za-z ]+"
            value={testMasterData.displayText}
            title="Only letters and spaces are allowed"
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="billingName">
            Billing Name<span>:</span>
          </label>
          <input
            type="text"
            id="billingName"
            onChange={handleInputChange}
            name="billingName"
            value={testMasterData.billingName}
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="container">
            Container<span>:</span>
          </label>
          <select
            id="container"
            onChange={handleInputChange}
            name="container"
            value={testMasterData.container}
          >
            <option value="">Select</option>
            {containerdata?.map((row, index) => (
              <option key={index} value={row.container_name}>
                {row.container_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="specimen">
            Specimen<span>:</span>
          </label>
          <select
            id="specimen"
            value={testMasterData.specimen}
            onChange={handleInputChange}
            name="specimen"
          >
            <option value="">Select</option>
            {specimendata?.map((row, index) => (
              <option key={index} value={row.specimen_name}>
                {row.specimen_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="method">
            Method<span>:</span>
          </label>
          <input
            id="method"
            onChange={handleInputChange}
            value={testMasterData.method}
            name="method"
            list="methodList"
          />
          <datalist id="methodList">
            {methoddata?.map((row, index) => (
              <option key={index} value={row.method_name} />
            ))}
          </datalist>
        </div>

              
        <div className="RegisForm_1">
          <label htmlFor="gender">
            Gender<span>:</span>
          </label>
          <select
            id="gender"
            onChange={handleInputChange}
            name="gender"
            value={testMasterData.gender}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Both">Both</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="inputType">
            Input Type<span>:</span>
          </label>
          <select
            id="inputType"
            onChange={handleInputChange}
            name="inputType"
            value={testMasterData.inputType}
          >
            <option value="">Select</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="decimalPlaces">
            Decimal Places<span>:</span>
          </label>
          <input
            type="number"
            id="decimalPlaces"
            onChange={handleInputChange}
            name="decimalPlaces"
            value={testMasterData.decimalPlaces}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="inputPatternType">
            Minimum Processing Time<span>:</span>
          </label>
          <input
            type="number"
            id="minimumtime"
            name="minimumtime"
            required
            value={testMasterData.minimumtime}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Emergencytime">
            Emergency Processing Time<span>:</span>
          </label>
          <input
            type="number"
            id="Emergencytime"
            name="Emergencytime"
            required
            value={testMasterData.Emergencytime}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="logicalCategory">
            Time Period<span>:</span>
          </label>
          <select
            id="timeperiod"
            onChange={handleInputChange}
            name="timeperiod"
            value={testMasterData.timeperiod}
          >
            <option value="">Select</option>
            <option value="Minute">Minute</option>
            <option value="Hours">Hours</option>
            <option value="Day">Day</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="inputPatternType">
            Input Pattern Type<span>:</span>
          </label>
          <select
            id="inputPatternType"
            onChange={handleInputChange}
            name="inputPatternType"
            value={testMasterData.inputPatternType}
          >
            <option value="">Select</option>
            <option value="Numeric">Numeric</option>
            <option value="AlphaNumeric">Alpha Numeric</option>
            <option value="Symbol">Symbol</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="testCategory">
            Test Category<span>:</span>
          </label>
          <select
            id="testCategory"
            onChange={handleInputChange}
            name="testCategory"
            value={testMasterData.testCategory}
          >
            <option value="">Select</option>
            <option value="Special">Special</option>
            <option value="STAT">STAT</option>
            <option value="General">General</option>
            <option value="Routine">Routine</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="logicalCategory">
            Logical Category<span>:</span>
          </label>
          <select
            id="logicalCategory"
            onChange={handleInputChange}
            name="logicalCategory"
            value={testMasterData.logicalCategory}
          >
            <option value="">Select</option>
            <option value="Lab">Lab</option>
            <option value="Imaging">Imaging</option>
            <option value="UltraSound">UltraSound</option>
            <option value="Xray">X-Ray</option>
            <option value="Bone">Bone</option>
            <option value="Blood">Blood</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="capturedUnit">
            Captured Unit<span>:</span>
          </label>
          <input
            type="text"
            id="capturedUnit"
            name="capturedUnit"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            onChange={handleInputChange}
            value={testMasterData.capturedUnit}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="uom">
            UOM<span>:</span>
          </label>
          <select
            id="uom"
            onChange={handleInputChange}
            name="uom"
            value={testMasterData.uom}
          >
            <option value="">Select</option>
            {unitdata?.map((row, index) => (
              <option key={index} value={row.unit_name}>
                {row.unit_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="reportType">
            Report<span>:</span>
          </label>
          <select
            id="reportType"
            onChange={handleInputChange}
            name="reportType"
            value={testMasterData.reportType}
          >
            <option value="">Select</option>
            <option value=" Cloud-Based"> Cloud-Based</option>
            <option value="HardCopy">Hard Copy</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="testInstruction">
            Test Instruction<span>:</span>
          </label>
          <textarea
            id="testInstruction"
            onChange={handleInputChange}
            name="testInstruction"
            value={testMasterData.testInstruction}
          ></textarea>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Reagentlevel">
            Reagent level<span>:</span>
          </label>
          <input
            type="number"
            id="Reagentlevel"
            onChange={handleInputChange}
            value={testMasterData.Reagentlevel}
            name="Reagentlevel"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="loincCode">
            Loinc Code<span>:</span>
          </label>
          <input
            type="text"
            id="loincCode"
            onChange={handleInputChange}
            value={testMasterData.loincCode}
            name="loincCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            required
          />
        </div>

        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="allowDiscount">
            Allow Discount<span>:</span>
          </label>
          <input
            type="checkbox"
            id="allowDiscount"
            onChange={handleInputChange}
            className="chk_box_23"
            name="allowDiscount"
            value={testMasterData.allowDiscount}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="orderable">
            Orderable<span>:</span>
          </label>
          <input
            type="checkbox"
            id="orderable"
            onChange={handleInputChange}
            className="chk_box_23"
            name="orderable"
            value={testMasterData.orderable}
          />
        </div>

        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="showGraph">
            Show Graph<span>:</span>
          </label>
          <input
            type="checkbox"
            id="showGraph"
            onChange={handleInputChange}
            className="chk_box_23"
            name="showGraph"
            value={testMasterData.showGraph}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="stat">
            STAT<span>:</span>
          </label>
          <input
            type="checkbox"
            id="stat"
            onChange={handleInputChange}
            className="chk_box_23"
            name="stat"
            value={testMasterData.stat}
          />
        </div>

        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="active">
            Active<span>:</span>
          </label>
          <input
            type="checkbox"
            id="active"
            onChange={handleInputChange}
            className="chk_box_23"
            name="active"
            value={testMasterData.active}
          />
        </div>

        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="nonReportable">
            Non-reportable<span>:</span>
          </label>
          <input
            type="checkbox"
            id="nonReportable"
            onChange={handleInputChange}
            className="chk_box_23"
            name="nonReportable"
            value={testMasterData.nonReportable}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="isOutsourced">
            Is Outsourced<span>:</span>
          </label>
          <input
            type="checkbox"
            id="isOutsourced"
            onChange={handleInputChange}
            className="chk_box_23"
            name="isOutsourced"
            value={testMasterData.isOutsourced}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="calculatedTest">
            is Machine Interfaced<span>:</span>
          </label>
          <input
            type="checkbox"
            id="is_Machine_Interfaced"
            onChange={handleInputChange}
            className="chk_box_23"
            name="is_Machine_Interfaced"
            value={testMasterData.is_Machine_Interfaced}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="calculatedTest">
            Calculated Test<span>:</span>
          </label>
          <input
            type="checkbox"
            id="calculatedTest"
            onChange={handleInputChange}
            className="chk_box_23"
            name="calculatedTest"
            value={testMasterData.calculatedTest}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="calculatedTest">
            isCAP <span>:</span>
          </label>
          <input
            type="checkbox"
            id="isCAP"
            onChange={handleInputChange}
            className="chk_box_23"
            name="isCAP"
            value={testMasterData.isCAP}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="calculatedTest">
            isNABHL <span>:</span>
          </label>
          <input
            type="checkbox"
            id="isNABHL"
            onChange={handleInputChange}
            className="chk_box_23"
            name="isNABHL"
            value={testMasterData.isNABHL}
          />
        </div>

        {testMasterData.calculatedTest === "Yes" ? (
          <>
            <div className="RegisForm_1">
              <label htmlFor="calculatedTest">
                Calculated Formula<span>:</span>
              </label>
              <input
                type="text"
                id="formula"
                onChange={handleInputChange}
                //  className="chk_box_23"
                name="formula"
                value={testMasterData.formula}
                //  value={testMasterData.calculatedTest}
              />
            </div>
          </>
        ) : null}
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="Culturetest">
            Culture Test<span>:</span>
          </label>
          <input
            type="checkbox"
            id="Culturetest"
            onChange={handleInputChange}
            className="chk_box_23"
            name="Culturetest"
            value={testMasterData.Culturetest}
          />
        </div>
        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="Checkout">
            Checkout Test <span>:</span>
          </label>
          <input
            type="checkbox"
            id="Checkout"
            onChange={handleInputChange}
            className="chk_box_23"
            name="Checkout"
            value={testMasterData.Checkout}
          />
        </div>
        {testMasterData.Checkout === "Yes" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="calculatedTest">
                Routine <span>:</span>
              </label>
              <input
                type="text"
                id="formula"
                onChange={handleInputChange}
                //  className="chk_box_23"
                name="paraone"
                value={testMasterData.paraone}
                //  value={testMasterData.calculatedTest}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="calculatedTest">
                Time Gap <span>:</span>
              </label>
              <input
                type="text"
                id="formula"
                onChange={handleInputChange}
                //  className="chk_box_23"
                name="paratwo"
                value={testMasterData.paratwo}
                //  value={testMasterData.calculatedTest}
              />
            </div>
          </>
        )}

        <div className="RegisForm_1 rghrhfh">
          <label htmlFor="IsSubTest">
            Is SubTest <span>:</span>
          </label>
          <input
            type="checkbox"
            id="IsSubTest"
            onChange={handleInputChange}
            className="chk_box_23"
            name="IsSubTest"
            value={testMasterData.IsSubTest}
            checked={testMasterData.IsSubTest === "Yes"}
          />
        </div>

        {testMasterData?.IsSubTest === "Yes" && (
          <div className="RegisForm_1">
            <label htmlFor="IsSubTest">
              SubTest <span>:</span>
            </label>
            <Select
              id="Remarks"
              name="Remarks"
              value={TestName.filter((test) =>
                testMasterData.SubTestCodes?.split(",").includes(test.Test_Code)
              ).map((test) => ({
                value: test.Test_Code,
                label: test.Test_Name,
              }))}
              onChange={handleInputChangeRemarks}
              options={TestName
                // Exclude the main test code from the options
                .filter((test) => test.Test_Code !== testMasterData?.testCode)
                .map((test) => ({
                  value: test.Test_Code,
                  label: test.Test_Name,
                }))}
              isMulti
              classNamePrefix="react-select"
              placeholder="Select Test"
            />
          </div>
        )}
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          onClick={
            testMasterData.isEditMode ? handleTestdata : handleSubmittestData
          }
        >
          {testMasterData.isEditMode ? "Update" : "Submit"}
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default TestMaster;
