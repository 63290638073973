import React, { useState, useEffect, useCallback } from "react";
import "./GroupMaster.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function GroupMaster() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [testname, setTestName] = useState([]);
  const [selecttest, setselecttest] = useState("");
  const [selectedtest, setselectedtest] = useState([]);
  const [selecttestcode, setselecttestcode] = useState("");
  const [Department, setDepartment] = useState([]);
  const [SelectedFile, setSelectedFile] = useState(null);

  const testdatas = useSelector((state) => state.userRecord?.GroupMasterData);
  console.log("testdatas", testdatas);
  const [formData, setFormData] = useState({
    groupCode: "",
    groupName: "",
    displayName: "",
    billingCode: "",
    billingName: "",
    gender: "",
    reportType: "",
    departmentName: "",
    testCategory: "",
    logicalCategory: "",
    authorizedUser: "",
    lonicCode: "",
    groupCost: "",
  });
  console.log(formData);
  console.log(selectedtest);

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/getsubdepartment`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        console.log("data", data);

        setDepartment(data);
      })
      .catch((error) => {
        console.error("Error fetching SubDepartment data:", error);
      });
  }, [urllink]);

  const handleTestNameChange = (e) => {
    const selectedTestName = e.target.value;
    setselecttest(selectedTestName);

    const selectedTest = testname?.find(
      (item) => item.Test_Name === selectedTestName
    );
    if (selectedTest) {
      setselecttestcode(selectedTest.Test_Code || "");
    } else {
      setselecttestcode("");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  // const [tableData, setTableData] = useState([]);

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      "groupCode",
      "groupName",
      "billingCode",
      "billingName",
      "gender",
      "reportType",
      "departmentName",
      "testCategory",
      "logicalCategory",
      "authorizedUser",
      "lonicCode",
      "groupCost",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length === 0) {
      try {
        // Determine the endpoint based on editMode
        const endpoint = isEditMode
          ? `${urllink}usercontrol/updategroupmaster`
          : `${urllink}usercontrol/insertgroupmaster`;

        // Make a POST request to the appropriate endpoint
        const response = await axios.post(endpoint, formData);
        // Handle the response as needed
        console.log(response.data);
        // setHide(true);

        // Reset the form data after submission
        setFormData({
          groupCode: "",
          groupName: "",
          displayName: "",
          billingCode: "",
          billingName: "",
          gender: "",
          reportType: "",
          departmentName: "",
          testCategory: "",
          logicalCategory: "",
          authorizedUser: "",
          lonicCode: "",
          groupCost: "",
        });

        // Optionally, you can fetch the updated data after successful submission
        // fetchGroupMasterData();
        fetchGroupMasterList();
        console.log(selectedtest);
        // if (!isEditMode) {
        // If it's not in edit mode, insert into insert_groupmaster_testlist
        const updatedSelectedTest = selectedtest.map((test) => ({
          ...test,
          group_name: formData.groupName,
        }));

        await axios.post(
          `${urllink}usercontrol/insert_groupmaster_testlist`,
          updatedSelectedTest
        );

        console.log("Successfully inserted into insert_groupmaster_testlist");
        setselectedtest([]);
        // }

        // Reset the editMode state after submission
        setIsEditMode(false);
        navigate("/Home/GroupMasterList");
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenarios
      }
    } else {
      userwarn(
        `Please fill out all required fields: ${missingFields.join(", ")}`
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urllink}usercontrol/getalltestname?gender=${formData.gender}`
        );
        console.log(response.data);
        setTestName(response.data);
      } catch (error) {
        console.error("Error fetching test names:", error);
      }
    };

    fetchData();
  }, [urllink, formData.gender]);
  // Inside your component
  //
  useEffect(() => {
    if (testdatas && Object.keys(testdatas).length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        groupCode: testdatas.group_code,
        groupName: testdatas.group_name,
        displayName: testdatas.display_name,
        billingCode: testdatas.billing_code,
        billingName: testdatas.billing_name,
        gender: testdatas.gender,
        reportType: testdatas.report,
        departmentName: testdatas.department_name,
        testCategory: testdatas.test_category,
        logicalCategory: testdatas.logical_category,
        authorizedUser: testdatas.authorized_user,
        lonicCode: testdatas.lonic_code,
        group_master_id: testdatas.id,
        groupCost: testdatas?.Group_Cost,
      }));
      setIsEditMode(testdatas.isEditMode);
    } else {
      axios
        .get(`${urllink}usercontrol/getGroupCode`)
        .then((res) => {
          console.log(res.data);
          setFormData((prev) => ({
            ...prev,
            groupCode: res.data.GroupCode,
          }));
        })
        .catch((err) => {
          console.err(err);
        });
    }
  }, [testdatas, urllink]);

  const handleInsertGroupMasterTestList = () => {
    console.log(selecttest);
    console.log(formData);
    if (
      formData.groupCode !== "" &&
      formData.groupName !== "" &&
      selecttest !== ""
    ) {
      console.log(selecttestcode);
      const isTestCodeExists = selectedtest.some(
        (test) => test.Test_Code === selecttestcode
      );

      if (isTestCodeExists) {
        alert(
          "Test code already exists with the name: " +
            selectedtest.find((test) => test.Test_Code === selecttestcode)
              .TestName
        );
      } else {
        const newvalues = {
          id: selectedtest.length + 1,
          group_code: formData.groupCode,
          group_name: formData.groupName,
          test_name: selecttest,
          Test_Code: selecttestcode,
          Group_Cost: formData.groupCost,
        };
        console.log(newvalues);
        setselectedtest((prev) => [...prev, newvalues]);
        setselecttest("");
        setselecttestcode("");
      }
    } else {
      alert("Please Enter Required Values");
    }
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Documents") {
        axios
          .post(`${urllink}usercontrol/post_groupmaster_csvfile`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error);
          });
      }
    }
  };

  const fetchGroupMasterList = useCallback(() => {
    axios
      .get(
        `${urllink}usercontrol/getgroupmastermasterlist?groupcode=${testdatas?.group_code}`
      )
      .then((response) => {
        const data = response.data;
        console.log("masterlist", data);

        setselectedtest(data);
      })
      .catch((error) => {
        console.error("Error fetching groupmaster masterlist data:", error);
      });
  }, [urllink, testdatas]);

  // };

  React.useEffect(() => {
    // fetchGroupMasterData();
    fetchGroupMasterList();
  }, [fetchGroupMasterList]);
  const handleDelete = (index) => {
    console.log(index);
    console.log(selectedtest);

    // Remove the row at the specified index
    const updatedTests = selectedtest.filter((test) => test.id !== index.id);

    // Update the state with the modified data
    setselectedtest(updatedTests);
  };

  console.log("formData", formData);

  const GroupMasterlistcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "test_name",
      name: "Test Name",
      width: 300,
      frozen: true,
    },

    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleDelete(params.row)}
          startIcon={<DeleteIcon />}
        ></Button>
      ),
    },
  ];

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Group Master</h4>
      </div>
      <br />

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label className="" htmlFor="groupCode">
            Group Code<span>:</span>
          </label>
          <input
            type="text"
            id="groupCode"
            name="groupCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Group Code"
            required
            disabled={isEditMode}
            onChange={handleInputChange}
            value={formData.groupCode}
          />
        </div>
        <div className="RegisForm_1">
          <label className="" htmlFor="GroupName">
            Group Name<span>:</span>
          </label>
          <input
            type="text"
            id="groupName"
            name="groupName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Group Name"
            required
            onChange={handleInputChange}
            value={formData.groupName}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="displayName" className="">
            Display Name<span>:</span>
          </label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Display Name"
            required
            onChange={handleInputChange}
            value={formData.displayName}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="billingCode" className="">
            Billing Code<span>:</span>
          </label>

          <input
            type="text"
            id="billingCode"
            name="billingCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Billing Code"
            required
            onChange={handleInputChange}
            value={formData.billingCode}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="billingName" className="">
            Billing Name<span>:</span>
          </label>
          <input
            type="text"
            id="billingName"
            name="billingName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Billing Name"
            required
            onChange={handleInputChange}
            value={formData.billingName}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="gender">
            Gender<span>:</span>
          </label>
          <select
            id="gender"
            name="gender"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.gender}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Both">Both</option>
          </select>
        </div>

        {/* <div><dddd/div> */}

        <div className="RegisForm_1">
          <label htmlFor="report">
            Report<span>:</span>
          </label>
          <select
            id="reportType"
            name="reportType"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.reportType}
          >
            <option value="">Select</option>
            <option value="Cloud-Based">Cloud-Based</option>
            <option value="HardCopy">Hard Copy</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="departmentName" className="">
            Department Name<span>:</span>
          </label>
          <select
            id="departmentName"
            name="departmentName"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.departmentName}
          >
            <option value="">Select</option>
            {Department.map((row, index) => (
              <option key={index} value={row.subdepartment_name}>
                {row.subdepartment_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="testcategory" className="">
            Test Category<span>:</span>
          </label>

          <select
            id="testCategory"
            name="testCategory"
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.testCategory}
          >
            <option value="">Select</option>
            <option value="Special">Special</option>
            <option value="STAT">STAT</option>
            <option value="General">General</option>
            <option value="Routine">Routine</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="logicalcategory" className="">
            Logical Category<span>:</span>
          </label>

          <select
            id="logicalCategory"
            name="logicalCategory"
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.logicalCategory}
          >
            <option value="">Select</option>
            <option value="Numeric">Numeric</option>
            <option value="AlphaNumeric">Alpha Numeric</option>
            <option value="Symbol">Symbol</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="autoauthorizeduser" className="">
            Auto Authorized User<span>:</span>
          </label>

          <input
            type="text"
            id="authorizedUser"
            name="authorizedUser"
            // className="new_clinic_form_inp111"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter your Auto Authorized User"
            required
            onChange={handleInputChange}
            value={formData.authorizedUser}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="loniccode" className="">
            Lonic Code<span>:</span>
          </label>

          <input
            type="text"
            id="loincCode"
            name="lonicCode"
            // className="new_clinic_form_inp111"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter your Lonic Code"
            required
            onChange={handleInputChange}
            value={formData.lonicCode}
          />
        </div>

        {/* testname */}
        {
          <>
            <div className="RegisForm_1">
              <label htmlFor="groupcost" className="">
                Group Cost<span>:</span>
              </label>

              <input
                type="number"
                id="groupCost"
                name="groupCost"
                // className="new_clinic_form_inp111"
                pattern="[A-Za-z ]+"
                title="Only numbers and spaces are allowed"
                // placeholder="Enter your Group Cost"
                required
                onChange={handleInputChange}
                value={formData.groupCost}
              />
            </div>

            <div className="RegisForm_1">
              <label className="" htmlFor="test names">
                Test Name <span>:</span>
              </label>

              <input
                id="testname"
                name="testname"
                list="browsers1"
                onChange={handleTestNameChange}
                value={selecttest}
                required
                autoComplete="off"
              />
              {console.log(testname)}
              <datalist id="browsers1">
                {testname && testname.length > 0 ? (
                  testname.map((item, index) => (
                    <option key={index + 1} value={item.Test_Name}>
                      {item.Test_Name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No tests available
                  </option>
                )}
              </datalist>
            </div>
            <div className="RegisForm_1">
              <label>
                Test Code <span>:</span>
              </label>
              <input
                type="text"
                id="Test_Code"
                name="Test_Code"
                onChange={(e) => setselecttestcode(e.target.value)}
                value={selecttestcode}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label>
                {" "}
                Upload CSV File <span>:</span>{" "}
              </label>
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                id="Servicechoose"
                required
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label
                htmlFor="Servicechoose"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
              <button
                className="RegisterForm_1_btns choose_file_update"
                onClick={() => handleCsvupload("Documents")}
              >
                Upload
              </button>
            </div>
          </>
        }
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          onClick={handleInsertGroupMasterTestList}
        >
          <AddIcon />
        </button>
      </div>

      <div className="Main_container_app">
        <ReactGrid columns={GroupMasterlistcolumns} RowData={selectedtest} />
      </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          {isEditMode ? "Update" : "Submit"}
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default GroupMaster;
