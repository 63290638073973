import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import { FaEdit } from "react-icons/fa";

const PatientListforLab = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const dispatchvalue = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [SelectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    axios
      .get(`${urllink}Billing/getallpatient?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response);

        // const data = response.data.map((row, index) => ({
        //   id: index + 1,
        //   ...row,
        // }));
        setsumma(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userRecord, urllink]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();

    // Sort by numeric part of Patient_Id in descending order and take the top 30
    const sortedData = summa
        .sort((a, b) => {
            const numA = parseInt(a.Patient_Id.replace("LED", ""), 10);
            const numB = parseInt(b.Patient_Id.replace("LED", ""), 10);
            return numB - numA;
        })
        .slice(0, 30);

    // Apply search filtering on the sorted data
    const filteredData = (searchQuery || searchQuery1)
        ? sortedData.filter((row) => {
            const lowerCasePatientName = row.Patient_Name
                ? row.Patient_Name.toLowerCase()
                : "";
            const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
            return (
                lowerCasePatientName.includes(lowerCaseNameQuery) &&
                lowerCasePhone.includes(lowerCasePhoneQuery)
            );
        })
        : sortedData; // If no search query, show top 30 sorted by Patient_Id
      
        const data = filteredData.map((row, index) => ({
          id: index + 1,
          ...row,
        }));

    // Set the filtered rows
    setFilteredRows(data);
}, [searchQuery, searchQuery1, summa]);




  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  const handleclick = (params) => {
    console.log(params);
    dispatchvalue({ type: "forpatientdatalab", value: params });
    navigate("/Home/PatientProfile");
  };

  const handleclick1 = (params) => {
    console.log(params);
    dispatchvalue({ type: "forpatienteditdatalab", value: params });
    navigate("/Home/PatientsEdit");
  };

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Patient_Id",
      name: "Patient ID",
      frozen: true,
      width: 120,
    },
    {
      key: "PatientPhoto",
      name: "Patient Photo",
      renderCell: (params) => (
        <img
          src={params.row.PatientPhoto}
          style={{
            height: "39px",
            width: "41px",
            borderRadius: "22px",
          }}
          alt={params.row.Patient_Name}
        />
      ),
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      width: 300,
      frozen: true,
    },
    {
      key: "Age",
      name: "Age",
    },
    {
      key: "Gender",
      name: "Gender",
    },

    {
      key: "Phone",
      name: "Phone",
    },
    {
      key: "EditActions",
      name: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleclick1(params.row)}
            startIcon={<FaEdit />}
            sx={{
              color: "var(--ProjectColor)",
              "&:hover": {
                color: "var(--ProjectColorhover)",
              },
            }}
          ></Button>
        </>
      ),
    },
    {
      key: "Actions",
      name: "View",
      width: 80,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleclick(params.row)}
            startIcon={<VisibilityIcon />}
            sx={{
              color: "var(--ProjectColor)",
              "&:hover": {
                color: "var(--ProjectColorhover)",
              },
            }}
          ></Button>
        </>
      ),
    },
  ];

  const handleCsvupload = () => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      axios
        .post(`${urllink}Billing/postPatientMaster`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response);
          // successMsg("File Processed Successfully");
          // fetch_testmasterdata();
          setSelectedFile(null);
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } else {
      // userwarn("Choose File");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(null);
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient List</h4>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="inp_1">
              <label>
                patient Master Excel File<span>:</span>
              </label>
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                id="Servicechoose"
                required
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label
                htmlFor="Servicechoose"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
              <button
                className="RegisterForm_1_btns choose_file_update"
                onClick={handleCsvupload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns1} RowData={filteredRows} />
        </div>
      </div>
    </>
  );
};

export default PatientListforLab;
