import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";

function Units() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [unitData, setUnitData] = React.useState([]);
  const [unit, setUnit] = useState("");
  const [selectedid, setSelectedid] = useState(null);
  const [unitcode, setUnitCode] = useState("");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const handleSubmitUnits = async () => {
    if (!unit.trim() || !unitcode.trim()) {
      userwarn("Both unit Code Name and unit are required.");
      return; 
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(
        `${urllink}mainddepartment/insertunit`,
        {
          unit,
          unitcode,
        }
      );

      // Handle the response as needed
      console.log(response.data);
      successMsg('Successfully Inserted')
      fetchUnitCodeData();
      setUnit("");
      // setUnitCode('')
      // Optionally, you can reset the state after a successful submission

      fetchUnitData();
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenarios
    }
  };

  const fetchUnitCodeData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getunitCode`)
      .then((response) => {
        const data = response.data;
        setUnitCode(data.unitCode);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]); // No dependencies here to ensure it's only created once

  const fetchUnitData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getunit`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setUnitData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]); // No dependencies here to ensure it's only created once

  useEffect(() => {
    fetchUnitData();
    fetchUnitCodeData();
  }, [fetchUnitCodeData, fetchUnitData]);

  const handleEdit = (row) => {
    setUnit(row.unit_name);
    setUnitCode(row.unit_code);
    setIsEditMode(true);
    setSelectedid(row.unit_id); // Assuming `method_id` is the identifier
  };

  const handleUpdateMethod = () => {
    axios
      .post(`${urllink}mainddepartment/updateunit`, {
        method_id: selectedid,
        method_name: unit,
        method_code: unitcode,
      })
      .then((res) => {
        console.log(res.data);
        successMsg(res.data.message)
        fetchUnitData();
        setUnit("");
        setUnitCode("");
        setIsEditMode(false);
        setSelectedid("");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const unitcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "unit_code",
      name: "Unit Code",
    },
    {
      key: "unit_name",
      name: "Unit Name",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <p
          
          onClick={() => handleEdit(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <EditIcon />
        </p>
      ),
    },
  ];

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };


  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <div className="appointment">
      <br />

      <div className="ShiftClosing_Container">
        <h2 style={{ textAlign: "center" }}>Unit Master</h2>
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Unit Code <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={unitcode}
                disabled
                onChange={(e) => setUnitCode(e.target.value)}
                placeholder="Enter Unit Code"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Unit <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                placeholder="Enter Unit"
              />
            </div>

            <button
              className="RegisterForm_1_btns"
              onClick={isEditMode ? handleUpdateMethod : handleSubmitUnits}
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
          </div>
        </div>

        <div className="Main_container_app">
          <ReactGrid columns={unitcolumns} RowData={unitData} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Units;
