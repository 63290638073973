import React, { useState, useEffect, useCallback } from "react";
import "./GroupMaster.css";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";

function GroupMasterList() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryCode, setSearchQueryCode] = useState("");
  const [value, setValue] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedrow, setSelectedrow] = useState([]);
  // const [isEditMode, setIsEditMode] = useState(false);
  const dispatchvalue = useDispatch();

  const handleSearchChangeCode = (event) => {
    setSearchQueryCode(event.target.value);
  };

  const fetchgroup_masterlist = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getgroupmasterdata`)
      .then((response) => {
        console.log(response.data);
        const data = response.data.map((row) => ({
          id: row.group_master_id,
          ...row,
        }));
        setValue(data);
      })
      .catch((error) => {
        console.error("Error fetching test description data:", error);
      });
  }, [urllink]);

  useEffect(() => {
    fetchgroup_masterlist();
  }, [fetchgroup_masterlist]);

  const handleEditClick = (params) => {
    console.log(params);

    setSelectedrow((updatedRow) => {
      // Set isEditMode to true and include the original row
      const updatedRowWithEditMode = { ...params, isEditMode: true };

      // Log the updatedRow
      console.log(updatedRowWithEditMode);

      // Dispatch the updatedRow
      dispatchvalue({ type: "GroupMaster", value: updatedRowWithEditMode });
      console.log("dispatch", dispatchvalue);

      // Navigate to the Test Master component
      navigate("/Home/GroupMaster");

      // Return the updatedRow
      return updatedRowWithEditMode;
    });
  };

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowersearchQueryCode = searchQueryCode.toLowerCase();

    const filteredData = value.filter((row) => {
      const lowerCaseSupplierName = row.group_name
        ? row.group_name.toLowerCase()
        : "";
      const lowersearchQueryCode1 = row.group_code
        ? row.group_code.toLowerCase()
        : "";

      return (
        lowerCaseSupplierName.includes(lowerCaseQuery) &&
        lowersearchQueryCode1.includes(lowersearchQueryCode)
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, value, searchQueryCode]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlenavigate = () => {
    dispatchvalue({ type: "GroupMaster", value: {} });
    navigate("/Home/GroupMaster");
  };

  console.log(selectedrow);

  const GroupMastercolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "group_name",
      name: "Group Name",
      width: 300,
      frozen: true,
    },
    {
      key: "gender",
      name: "Gender",
    },
    {
      key: "lonic_code",
      name: "Lonic Code",
    },

    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <p
          onClick={() => handleEditClick(params.row)}
          style={{ width: "130px", textAlign: "center", cursor: "pointer" }}
        >
          <EditIcon />
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Group Master List</h4>
        </div>
        <br />

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Group Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter Group Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Group Code <span>:</span>
            </label>
            <input
              type="text"
              value={searchQueryCode}
              onChange={handleSearchChangeCode}
              placeholder="Enter Group Code"
            />
          </div>
          <button className="btn_1" type="submit" onClick={handlenavigate}>
            <AddCircleOutlineIcon />
          </button>
        </div>

        <div className="Main_container_app">
          <ReactGrid columns={GroupMastercolumns} RowData={filteredRows} />
        </div>
      </div>
    </>
  );
}
export default GroupMasterList;
