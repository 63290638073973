import React, { useCallback, useEffect, useState } from "react";
import "./PatientProfile.css";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DvrIcon from "@mui/icons-material/Dvr";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";
import BiotechIcon from "@mui/icons-material/Biotech";
import PaymentIcon from "@mui/icons-material/Payment";
import axios from "axios";
import { useSelector } from "react-redux";
import bgImg2 from "../assets/profileimg.jpeg";
import HomeIcon from "@mui/icons-material/Home";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const PatientProfile = () => {
  const patientData = useSelector(
    (state) => state.userRecord?.forpatientdatalab
  );
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(patientData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState("");
  const [column, setColumn] = useState([]);
  const [Rowdata, setRowData] = useState([]);

  const handledownload = useCallback(
    (row) => {
      const invoiceNo = row.Billing_Invoice;

      axios
        .get(`${urllink}Billing/download_report?invoice_no=${invoiceNo}`)
        .then((response) => {
          const { Report } = response.data;

          if (Report) {
            // Convert base64 to a Blob
            const byteCharacters = atob(Report.split(",")[1]); // Remove the 'data:application/pdf;base64,' part
            const byteArrays = [];
            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 1024
            ) {
              const slice = byteCharacters.slice(offset, offset + 1024);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `report_${invoiceNo}.pdf`;
            link.click();
          } else {
            console.error("No report data found.");
          }
        })
        .catch((error) => {
          console.error("Error downloading the report:", error);
        });
    },
    [urllink]
  );

  const handledownload1 = useCallback(
    (row) => {
      const invoiceNo = row.Billing_Invoice;

      axios
        .get(`${urllink}Billing/download_trf?invoice_no=${invoiceNo}`, {
          responseType: "blob", // Important to handle binary data
        })
        .then((response) => {
          // Create a URL for the file
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${invoiceNo}_TRF.pdf`); // Set the default file name

          // Append the link to the body
          document.body.appendChild(link);

          // Trigger a click event on the link
          link.click();

          // Clean up
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url); // Release the object URL
        })
        .catch((error) => {
          console.error("Error downloading the report:", error);
        });
    },
    [urllink]
  );

  const handleOpenModle = useCallback(
    (summa) => {
      console.log(summa);
      setShow(summa);
      if (summa === "BillingHistory") {
        setColumn([
          {
            key: "id",
            name: "S.No",
            width: 70,
            frozen: true,
          },

          {
            key: "Billing_Invoice",
            name: "Invoice No",
          },

          {
            key: "Billing_Date",
            name: "Bill Date",
          },

          {
            key: "Net_Amount",
            name: "Net Amount",
          },

          {
            key: "Status",
            name: "Status",
          },
          {
            key: "Sample_Barcode_Image",
            name: "View",
            width: 200,
            renderCell: (params) => (
              <>
                <a
                  href={params.row.Sample_Barcode_Image}
                  download="barcode_image.png"
                >
                  <img
                    // height="40px"
                    style={{
                      height: "60px",
                      position: "relative",
                      left: "15px",
                      padding: "10px",
                    }}
                    src={params.row.Sample_Barcode_Image}
                    alt="Sample Barcode"
                  />
                </a>
              </>
            ),
          },
        ]);

        setOpenModal(true);
        const Patient_Id = patientData?.Patient_Id;

        axios
          .get(
            `${urllink}Billing/getbilling_details_lab?patientid=${Patient_Id}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response);
            const data = response.data;
            setRowData(
              data.map((row, index) => ({
                id: index + 1,
                ...row,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (summa === "TestRequest") {
        console.log("hai");
        setColumn([
          {
            key: "id",
            name: "S.No",
            width: 70,
            frozen: true,
          },
          {
            key: "invoicedata",
            name: "Invoice No",
            frozen: true,
          },

          {
            key: "testname",
            name: "Test Name",
            width: 500,
          },
        ]);

        setOpenModal(true);
        const patient_id = patientData?.Patient_Id;

        axios
          .get(
            `${urllink}Billing/gettest_details_lab?PatientID=${patient_id}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response);
            const data = response.data;

            setRowData(
              data.map((row, index) => ({
                id: index + 1,
                ...row,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (summa === "TRF") {
        setColumn([
          {
            key: "id",
            name: "S.No",
            width: 70,
            frozen: true,
          },
          {
            key: "Billing_Invoice",
            name: "Invoice No",
            frozen: true,
          },
          {
            key: "TRF_File",
            name: "Action",
            width: 150,
            renderCell: (params) =>
              params.row.TRF_File === "Pending" ? (
                "No File"
              ) : (
                <Button
                  onClick={() => handledownload1(params.row)}
                  startIcon={<DownloadRoundedIcon />}
                  sx={{
                    color: "var(--ProjectColor)",
                    "&:hover": {
                      color: "var(--ProjectColorhover)",
                    },
                  }}
                ></Button>
              ),
          },
        ]);
        setOpenModal(true);
        const patient_id = patientData?.Patient_Id;

        axios
          .get(
            `${urllink}Billing/get_TRF_Invoices?patientid=${patient_id}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response);
            const data = response.data;

            setRowData(
              data.map((row, index) => ({
                id: index + 1,
                ...row,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (summa === "TestReport") {
        setColumn([
          {
            key: "id",
            name: "S.No",
            width: 70,
            frozen: true,
          },
          {
            key: "Billing_Invoice",
            name: "Invoice No",
            frozen: true,
          },
          {
            key: "Net_Amount",
            name: "Net Amount",
            frozen: true,
          },

          {
            key: "Report",
            name: "Action",
            renderCell: (params) =>
              params.row.Report === "Pending" ? (
                "Pending"
              ) : (
                <Button
                  onClick={() => handledownload(params.row)}
                  startIcon={<DownloadRoundedIcon />}
                  sx={{
                    color: "var(--ProjectColor)",
                    "&:hover": {
                      color: "var(--ProjectColorhover)",
                    },
                  }}
                ></Button>
              ),
          },
        ]);

        setOpenModal(true);
        const patient_id = patientData?.Patient_Id;

        axios
          .get(
            `${urllink}Billing/get_Reports_ForProfile?patientid=${patient_id}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response.data);
            const data = response.data;

            // Update state with the fetched data
            setRowData(
              data.map((row, index) => ({
                id: index + 1,
                ...row,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [
      handledownload,
      handledownload1,
      userRecord,
      urllink,
      patientData?.Patient_Id,
    ]
  );

  useEffect(() => {
    handleOpenModle(userRecord);
  }, [handleOpenModle, userRecord]);

  const DisplayData = () => {
    return (
      <>
        <div className="appointment">
          <div className="h_head">
            <h3> {show + " List"}</h3>
          </div>
          <div className="Main_container_app">
            <ReactGrid columns={column} RowData={Rowdata} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="patient_profile">
      <div className="p_head">
        <h3 style={{ color: "white" }}>Patient Profile</h3>
      </div>
      <div className="p_data">
        <div className="p_p_detials">
          <div className="p_profile_img_head">
            {patientData && patientData.PatientPhoto && (
              <div className="p_profile_img img_096">
                <img
                  src={patientData?.PatientPhoto}
                  alt={`Patient ${patientData.Patient_Id}`}
                />
              </div>
            )}
            {!(patientData && patientData.PatientPhoto) && (
              <div className="p_profile_img img_096">
                <img src={bgImg2} alt={`Patient ${patientData?.Patient_Id}`} />
              </div>
            )}

            <div className="p_profile_name">
              <h3>{patientData.Patient_Name}</h3>

              <div className="p_profile_body_icon">
                <h5>
                  <PhoneAndroidIcon />
                  <p>
                    <h4 className="ieudh78e6e34">{patientData?.Phone}</h4>
                  </p>
                </h5>
              </div>
              <div className="p_profile_body_icon">
                <h5>
                  <EmailIcon />
                  <p className="jnj6">
                    <h4 className="ieudh78e6e34">
                      {patientData?.Email || "-"}
                    </h4>
                  </p>
                </h5>
              </div>
            </div>
          </div>
          <div className="p_profile_body">
            <h3>Address</h3>
            <div className="p_profile_body_icon">
              <HomeIcon />
              <div className="text-hmeicn">
                {`${patientData.communicationAddress}`}
              </div>
            </div>
          </div>
        </div>
        <div className="p_p_detial_1">
          <div className="p_p_data">
            <div className="p_profile_data overview">
              <h3>Overview </h3>
            </div>
            <div className="p_profile_items_11">
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}>Gender :</h6>
                  <h3>{patientData?.Gender}</h3>
                </div>
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}>Patient Barcode :</h6>
                  <h3>{patientData?.Patient_Barcode}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}>Date of Birth :</h6>
                  <h3>{patientData?.DOB}</h3>
                </div>
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}> Patient Id :</h6>
                  <h3>{patientData?.Patient_Id}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}>Age :</h6>
                  <h3>{patientData?.Age}</h3>
                </div>
                <div className="p_profile_data">
                  <h6 style={{ color: "white" }}>Marital Status :</h6>
                  <h3>{patientData?.Marital_Status || "-"}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p_data_1">
        <div className="p_p_data_detial_1">
          <div className="p_p_data_items_left">
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("BillingHistory")}
            >
              <div className="p_profile_img cente_1">
                <PaymentIcon />
              </div>
              <h3 style={{ color: "white" }}>Billing History</h3>
              <div className="p_profile_img cente_1">
                <ChevronRightIcon />
              </div>
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("TestRequest")}
            >
              <div className="p_profile_img cente_1">
                <AddShoppingCartIcon />
              </div>
              <h3 style={{ color: "white" }}>Test Request</h3>
              <div className="p_profile_img cente_1">
                <ChevronRightIcon />
              </div>
            </button>
          </div>
          <div className="p_p_data_items_left">
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("TestReport")}
            >
              <div className="p_profile_img cente_1">
                <BiotechIcon />
              </div>
              <h3 style={{ color: "white" }}>Test Report</h3>
              <div className="p_profile_img cente_1">
                <ChevronRightIcon />
              </div>
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("TRF")}
            >
              <div className="p_profile_img cente_1">
                <DvrIcon />
              </div>
              <h3 style={{ color: "white" }}>TRF</h3>
              <div className="p_profile_img cente_1">
                <ChevronRightIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles newwPopupforreason">
            <DisplayData />
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientProfile;
