import * as React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "react-modal";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { ToastContainer, toast } from "react-toastify";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";

const PatientRequestforLab = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const dispatchvalue = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [receipt, setreceipt] = useState(null);
  const [testNames, setTestNames] = useState([]);

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };

  useEffect(() => {
    axios
      .get(`${urllink}Billing/getallpatientrequest`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        const data1 = data.sort((a, b) => {
          const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ""), 10);
          const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ""), 10);
          return invoiceB - invoiceA;
        });

        const data2 = data1.map((row, index) => ({
          id: index + 1, // Assuming you want to use Patient_Id as id
          ...row,
          status: "Status",
        }));
        setsumma(data2);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [urllink]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery)
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  // const reprintRef = useRef();

  //   const handlePrint = () => {
  //     if (modalContent) {
  //         const pdfWindow = window.open("", "_blank");
  //         if (pdfWindow) {
  //             pdfWindow.document.write(
  //                 `<iframe width="100%" height="100%" src="${modalContent}" style="border:none;"></iframe>`
  //             );

  //             // Wait for a short time and then call print
  //             setTimeout(() => {
  //                 pdfWindow.print();
  //             }, 500); // Adjust the timeout as needed
  //         } else {
  //             console.error("Unable to open new window for printing.");
  //         }
  //     } else {
  //         console.error("No content available for printing.");
  //     }
  // };
  const [Selecteddata, setSelecteddata] = useState({});
  console.log(Selecteddata);
  const handleReprint = (params) => {
    console.log(params);

    axios
      .get(
        `${urllink}Billing/get_billing_receipts_rebilling_print?invoice=${params.Billing_Invoice}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response);

        let data = `data:application/pdf;base64,${response.data.receipt1}`;
        setModalContent(data);
        setModalIsOpen(true);
        setSelecteddata(params);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBarcodeReprint = (params) => {
    console.log(params);
    dispatchvalue({ type: "PrintBarcode", value: params });
    //  setsave(!save)
    navigate("/Home/BarcodePrint");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  const handleview = (params) => {
    console.log(params);

    // Split the Test_Names string by commas and trim any whitespace
    const testNamesArray = params.Test_Names.split(",").map((test) =>
      test.trim()
    );
    console.log(testNamesArray);
    const data = testNamesArray.map((testname, index) => ({
      id: index + 1,
      testname: testname,
    }));
    console.log(data);

    // Set the split test names into state (if needed) or use directly in render
    setTestNames(data);
    setOpenModal(true);
  };

  const unitcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "testname",
      name: "Test Name",
      width: 400,
    },
  ];

  const handleReceiptReprint = (params) => {
    console.log(params);
    axios
      .get(
        `${urllink}Billing/get_Bill_receipt_forreprint?Billing_Invoice=${params?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.message) {
          // alert('hai')
          userwarn(res.data.message);
        } else {
          setreceipt(res.data.receipt);
          setOpenModal1(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice",
      frozen: true,
      width: 150,
    },
    {
      key: "UpdatedAt",
      name: "Date",
    },
    {
      key: "Patient_Id",
      name: "Patient Id",
      width: 120,
    },
    // {
    //   key: "Visit_Id",
    //   name: "Visit Id",
    //   width: 90,
    // },
    {
      key: "visitTokenid",
      name: "Visit No",
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      width: 300,
    },
    {
      key: "Phone",
      name: "Phone",
    },

    {
      key: "Patient_Group",
      name: "Patient Group",
    },
    {
      key: "Refering_Doctor",
      name: "Reference Doctor",
    },
    {
      key: "EditAction3",
      name: "Test Names",
      renderCell: (params) => (
        <Button
          onClick={() => handleview(params.row)}
          startIcon={<VisibilityIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },

    {
      key: "EditAction",
      name: "Bill Reprint",
      renderCell: (params) => (
        <Button
          onClick={() => handleReprint(params.row)}
          // startIcon={<FaPrint />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        >
          <FaPrint />
        </Button>
      ),
    },
    // {
    //   key: "EditAction1",
    //   name: "Receipt Reprint",
    //   renderCell: (params) => (
    //     <Button
    //       onClick={() => handleReceiptReprint(params.row)}
    //       // startIcon={<ReceiptLongRoundedIcon />}
    //       sx={{
    //         color: "var(--ProjectColor)",
    //         "&:hover": {
    //           color: "var(--ProjectColorhover)",
    //         },
    //       }}
    //     >
    //       <ReceiptLongRoundedIcon />
    //     </Button>
    //   ),
    // },
    {
      key: "EditAction2",
      name: "Barcode Reprint",
      renderCell: (params) => (
        <Button
          onClick={() => handleBarcodeReprint(params.row)}
          // startIcon={<FaPrint />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        >
          <FaPrint />
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient Request List</h4>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone No"
              />
            </div>
          </div>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns1} RowData={filteredRows} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{ content: { ...yourStyles } }}
        >
          {/* <div className="background_text_for_reprint"> */}
          <div className="h_head">
            <h3
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              Patient Name: {`${(Selecteddata?.Patient_Name || '').toUpperCase()} -`} BILL REPRINT

            </h3>
          </div>
          <div className="pdf_img_show">
            {modalContent
              .toLowerCase()
              .startsWith("data:application/pdf;base64,") ? (
              <>
                <iframe
                  title="PDF Viewer"
                  src={modalContent}
                  width="100%"
                  height="100%"
                  border="none"
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </>
            ) : (
              <img
                src={modalContent}
                alt="Concern Form"
                width="100%"
                height="100%"
              />
            )}
            <div className="jhuhhjh">
              <p
                onClick={closeModal}
                style={{
                  width: "130px",
                  textAlign: "center",
                  cursor: "pointer",
                  position: "relative",
                  left: "30px",
                }}
              >
                <HighlightOffIcon />
              </p>
            </div>
          </div>
        </Modal>
        <ToastContainer />
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason uwagduaguleaveautotest"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Main_container_app">
              <ReactGrid columns={unitcolumns} RowData={testNames} />
            </div>
            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {openModal1 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal1(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason uwagduaguleaveautotest"
            onClick={(e) => e.stopPropagation()}
          >
            <iframe title="PDF Viewer" src={receipt} />

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal1(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientRequestforLab;
